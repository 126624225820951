import { ClientConfiguration } from '../models/ClientConfiguration';
import { Api } from './Api';
import { DecodedClientToken } from './ClientTokenHandler';

export default class ClientConfigurationHandler {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  public async fetchClientConfiguration(
    decodedClientToken: DecodedClientToken,
  ) {
    const { data, error } = await this.api.get<ClientConfiguration>(
      `${decodedClientToken.configurationUrl}/?withDisplayMetadata=true`,
      { apiVersion: '2.1' },
    );

    if (error) {
      throw new Error(
        `Failed to initialize client: ${JSON.stringify(error, undefined, 2)}`,
      );
    }

    if (!data) {
      throw new Error('Failed to initialize client');
    }

    return data;
  }
}
