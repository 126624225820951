import window from '../../utils/window';

enum BatteryStatus {
  CHARGING = 'CHARGING',
  NOT_CHARGING = 'NOT_CHARGING',
}

export interface IDeviceInfo {
  batteryLevel: Nullable<number>;
  batteryStatus: Nullable<BatteryStatus>;
  memoryFootprint: Nullable<number>;
  modelIdentifier?: string;
  modelName?: string;
  screen: {
    height: number;
    width: number;
  };
  locale: string;
  platformVersion: string;
  userAgent: string;
}

export async function createDeviceInfo(): Promise<IDeviceInfo> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line compat/compat
  const battery = await (navigator?.getBattery?.() || Promise.resolve());

  let batteryLevel: Nullable<number> = null;
  let batteryStatus: Nullable<BatteryStatus> = null;

  if (battery) {
    batteryLevel = battery.level;
    batteryStatus = battery.charging
      ? BatteryStatus.CHARGING
      : BatteryStatus.NOT_CHARGING;
  }

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line compat/compat
    memoryFootprint: window?.performance?.memory?.usedJSHeapSize,
    screen: {
      height: window.screen.height,
      width: window.screen.width,
    },
    platformVersion: navigator.platform,
    userAgent: navigator.userAgent,
    locale: navigator.language,
    batteryLevel,
    batteryStatus,
  };
}
