import currencies from '../../assets/currencies.min.json';

interface CurrencyInfo {
  decimalDigits: number;
}

export class Currencies {
  static getCurrencyInfoByCurrencyCode(
    currencyCode?: string,
  ): CurrencyInfo | null {
    if (!currencyCode) {
      return null;
    }

    const minifiedCurrencyInfo = currencies[currencyCode];
    if (!minifiedCurrencyInfo) {
      return null;
    }

    return { decimalDigits: minifiedCurrencyInfo?.b } as CurrencyInfo;
  }
}
