import {
  ProgressNotifier,
  ProgressNotifierCallbacks,
} from './ProgressNotifier';
import { ClientContext } from './ClientContext';
import { CardNetwork, PaymentMethodType, SupportedLocale } from '../types';
import { Analytics } from '../analytics/Analytics';
import { IOptionStoreListener } from '../store/OptionStore';
import CheckoutStore from '../store/CheckoutStore';
import { Translation } from '../utils/i18n/Translation';
import { ClientSessionActionService } from '../checkout/client-session/ClientSessionActionService';
import {
  PaymentMethodTokenizationHandler,
  PaymentMethodTokenizer,
} from './PaymentMethodTokenizationHandler';

interface AuxillaryOptions {
  allowedCardNetworks?: CardNetwork[];
  optionStore: IOptionStoreListener;
  store: CheckoutStore;
  clientSessionActionService?: ClientSessionActionService;
}

export interface PaymentMethodContextOptions
  extends ProgressNotifierCallbacks,
    AuxillaryOptions {
  locale: SupportedLocale;
}

export interface IPaymentMethodContext extends ClientContext, AuxillaryOptions {
  progress: ProgressNotifier;
  translations: Translation;
  analytics: Analytics;
  tokenizePaymentMethod: PaymentMethodTokenizer;
}

export const PaymentMethodContext = {
  create(
    context: ClientContext,
    options: PaymentMethodContextOptions,
    optionStore: IOptionStoreListener,
    store: CheckoutStore,
    paymentMethodType: PaymentMethodType,
  ): IPaymentMethodContext {
    const { tokenizePaymentMethod } = PaymentMethodTokenizationHandler.create(
      context,
    );

    return {
      ...context,
      get session() {
        return context.session;
      },

      //
      allowedCardNetworks: options.allowedCardNetworks,
      progress: new ProgressNotifier(
        {
          onTokenizeShouldStart: options.onTokenizeShouldStart,
          onTokenizeDidNotStart: options.onTokenizeDidNotStart,
          onTokenizeStart: options.onTokenizeStart,
          onTokenizeEnd: options.onTokenizeEnd,
          onTokenizeProgress: options.onTokenizeProgress,
          onTokenizeError: options.onTokenizeError,
          onTokenizeSuccess: options.onTokenizeSuccess,
        },
        paymentMethodType,
      ),
      translations: store.getTranslations(),
      optionStore,
      store,
      clientSessionActionService: options.clientSessionActionService,
      tokenizePaymentMethod,
    };
  },
};

export default PaymentMethodContext;
