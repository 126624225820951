import { AnalyticsEventTypes } from './AnalyticsEventTypes';
import { createDeviceInfo, IDeviceInfo } from './DeviceInfo';
import { Environment } from '../../utils/Environment';

const DEFAULT_SDK_TYPE = 'WEB';

export interface IBaseAnalyticsEvent<T> {
  eventType: AnalyticsEventTypes;
  createdAt: number;
  device: IDeviceInfo;
  properties: T;
  sdkType: string;
  sdkVersion: string;
  appIdentifier?: string;
  checkoutSessionId?: string;
  clientSessionId?: string;
  customerId?: string;
  primerAccountId?: string;
}

export interface IAnalyticsOptions {
  checkoutSessionId?: string;
  clientSessionId?: string;
  primerAccountId?: string;
  appIdentifier?: string;
  customerId?: string;
}

export async function createBaseAnalyticsEvent<T>(
  eventType: AnalyticsEventTypes,
  properties: T,
  options: IAnalyticsOptions,
): Promise<IBaseAnalyticsEvent<T>> {
  const deviceInfo = await createDeviceInfo();

  return {
    sdkVersion: Environment.get<string>('PRIMER_SDK_VERSION'),
    sdkType: DEFAULT_SDK_TYPE,
    createdAt: Date.now(),
    device: deviceInfo,
    properties,
    eventType,
    ...options,
  };
}
