import urlJoin from 'url-join';
import { ModuleLoader } from './ModuleLoader';
import Module from './Module';
import {
  IModuleFactory,
  ModuleKey,
  ModuleKeyDescriptor,
  PackageKey,
} from './types';
import { ScriptLoader } from '../ScriptLoader';
import Package from './Package';

const moduleKeyDescriptorToModuleKey = ({
  remotePath,
  entryFile,
  scope,
  path,
}: ModuleKeyDescriptor): ModuleKey => {
  return {
    entryPoint: urlJoin(remotePath, entryFile ?? 'entry.js'),
    scope: scope ?? 'PrimerSDK',
    path,
  };
};

export default class ModuleFactory implements IModuleFactory {
  private moduleLoader: ModuleLoader;

  private modules: Array<Module>;

  private packages: Array<Package>;

  constructor(scriptLoader: ScriptLoader) {
    this.modules = [];
    this.packages = [];
    this.moduleLoader = new ModuleLoader({ scriptLoader });
  }

  getPackages() {
    return this.packages;
  }

  getModule(keyDescriptor: ModuleKeyDescriptor): Module {
    const key = moduleKeyDescriptorToModuleKey(keyDescriptor);

    let module = this.findModule(key);
    if (module) {
      return module;
    }

    module = new Module({ key, moduleLoader: this.moduleLoader });
    this.modules.push(module);

    return module;
  }

  getPackage(key: PackageKey) {
    let p = this.findPackage(key);
    if (p) {
      return p;
    }

    p = new Package({ moduleFactory: this, key });
    this.packages.push(p);

    return p;
  }

  private findModule({
    entryPoint,
    scope,
    path,
  }: ModuleKey): Module | undefined {
    return this.modules.find(
      (module) =>
        module.key.entryPoint === entryPoint &&
        module.key.scope === scope &&
        module.key.path === path,
    );
  }

  private findPackage({ remotePath, entryFile, scope }: PackageKey) {
    return this.packages.find(
      (p) =>
        p.key.remotePath === remotePath &&
        p.key.entryFile === entryFile &&
        p.key.scope === scope,
    );
  }
}
