import { Api } from '@primer-sdk-web/core/Api';
import { LongPoll } from '@primer-sdk-web/core/LongPoll';

type LongPollResults = { id: string; status: string };

type ResumeCallbackOptions = {
  url: string;
  timeout?: number;
  pollInterval?: number;
};

export interface ResumeCallbackHandlerContext {
  api: Api;
}

const checkResumeToken = ({ id, status }: LongPollResults) =>
  typeof id === 'string' && id.length > 0 && status === 'COMPLETE';

export type ResumeCallbackHandler = {
  promise: Promise<LongPollResults | null>;
  stop: () => void;
};

const createResumeCallbackHandler = (
  context: ResumeCallbackHandlerContext,
  {
    url,
    timeout = 1000 * 60 * 60 * 24,
    pollInterval = 1000,
  }: ResumeCallbackOptions,
): ResumeCallbackHandler => {
  const longPoll = new LongPoll({
    api: context.api,
  });

  const promise = longPoll.start<LongPollResults>({
    url,
    timeout,
    pollInterval, // interval before making another call
    predicate: checkResumeToken,
  });

  return {
    promise,
    stop: () => longPoll.stop(),
  };
};

export default createResumeCallbackHandler;
