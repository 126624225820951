// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h, render } from 'preact';
import { StoreProvider } from '@primer-io/shared-library/contexts';
import { internalClientSessionToClientSession } from '../../models/ClientSession';
import { createPaymentMethodContextFactory } from '../../core/PaymentMethodContextFactory';
import { PaymentMethods } from '../../payment-methods/PaymentMethods';
import { CheckoutPaymentMethodConfig } from '../CheckoutPaymentMethodConfig';
import { CheckoutTokenizationHandlers } from '../CheckoutTokenizationHandlers';
import { BasePaymentMethod } from '../../payment-methods/BasePaymentMethod';
import {
  HeadlessManagerType,
  HeadlessUniversalCheckoutOptions,
  PaymentMethodInfo,
  PaymentMethodType,
  PrimerHeadlessCheckout,
  TokenizationHandlers,
} from '../../types';
import { ClientContext } from '../../core/ClientContext';
import normalizeOptions from '../../payment-methods/normalizeOptions';
import { createCheckoutViewUtils } from '../ui/ViewUtils';
import { CheckoutModules } from '../../checkout-modules/CheckoutModules';
import ClientSessionHandler from '../../core/ClientSessionHandler';
import { ClientSessionActionHandler } from '../client-session/ClientSessionActionHandler';
import { logWarning, WarningType } from '../../utils/consoleWarn';
import { ClientSessionActionService } from '../client-session/ClientSessionActionService';
import {
  ErrorCode,
  PrimerClientError,
  throwPrimerClientError,
} from '../../errors';
import { CheckoutPaymentHandlers } from '../CheckoutPaymentHandlers';
import { PaymentMethodManager } from '../managers/PaymentMethodManager';
import initializeClientContext from '../ClientContextInitializer';
import PaymentMethodPopupOverlay from '../../core/PopupService/PaymentMethodPopupOverlay';
import { InternalFlowOptions } from '../../internalTypes';
import { ImplementationType } from '../../payment-methods/payment-method-implementations/types';
import { startCheckoutFlow } from '../CheckoutFlowStarter';
import IFrameOverlay from '../../core/IFrameService/IFrameOverlay';

const PrimerPaymentMethodPrefix = 'PRIMER_TEST_';

const renderRoot = ({ store, root }: { store; root }) =>
  render(
    <StoreProvider value={store}>
      <PaymentMethodPopupOverlay />
      <IFrameOverlay />
    </StoreProvider>,
    root,
  );

export class HeadlessUniversalCheckout {
  private options: HeadlessUniversalCheckoutOptions;

  private paymentMethodManager: PaymentMethodManager;

  constructor(private readonly context: ClientContext) {
    this.configure = this.configure.bind(this);
    this.start = this.start.bind(this);
  }

  static async create(context: ClientContext): Promise<PrimerHeadlessCheckout> {
    const checkout = new HeadlessUniversalCheckout(context);
    checkout.paymentMethodManager = PaymentMethodManager.create();

    return {
      ...checkout,
      createPaymentMethodManager: checkout.paymentMethodManager.createPaymentMethodManager.bind(
        checkout.paymentMethodManager,
      ),
      getAssetsManager: checkout.paymentMethodManager.getAssetsManager.bind(
        checkout.paymentMethodManager,
      ),
    } as PrimerHeadlessCheckout;
  }

  configure(options: HeadlessUniversalCheckoutOptions) {
    this.options = options;
    this.context.clientOptions = options as InternalFlowOptions;
  }

  async start() {
    const root = document.createElement('div');
    document.body.appendChild(root);

    ///////////////////////////////////////////
    // Store
    const { store } = this.context;

    // Client Session
    const clientSessionHandler = new ClientSessionHandler(
      this.context.clientTokenHandler,
      this.context.clientConfigurationHandler,
      store,
    );

    // Client Session Actions
    const clientSessionActionHandler = ClientSessionActionHandler.create(
      this.context,
      clientSessionHandler,
      store,
      this.options,
    );

    const clientSessionActionService = new ClientSessionActionService(
      store,
      clientSessionActionHandler,
    );

    // View Utils
    const viewUtils = createCheckoutViewUtils(store, this.options);

    ///////////////////////////////////////////
    // Fetch Client Configuration
    ///////////////////////////////////////////
    await initializeClientContext(this.context);

    if (
      !this.context.session?.clientSession.order.totalOrderAmount &&
      !this.context.session?.clientSession.order.merchantAmount
    ) {
      logWarning(WarningType.MISSING_AMOUNT_IN_CLIENT_SESSION);
    }
    if (!this.context.session?.clientSession.order.currencyCode) {
      logWarning(WarningType.MISSING_CURRENCY_IN_CLIENT_SESSION);
    }

    store.setClientSession(this.context.session.clientSession);

    this.options?.onClientSessionUpdate?.(
      internalClientSessionToClientSession(this.context.session.clientSession),
    );

    renderRoot({ store, root });

    ///////////////////////////////////////////
    // Vault & Payment Methods
    ///////////////////////////////////////////

    const processCheckoutModules = async () => {
      const checkoutModules = this.context.session.checkoutModules
        .map((checkoutModule) =>
          CheckoutModules.create(checkoutModule, {
            context: this.context,
            options: this.options,
            store,
            clientSessionActionService,
          }),
        )
        .filter(Boolean);

      store.setCheckoutModules(checkoutModules);
    };

    ///////////////////////////////////////////
    // Prepare Payment Handlers
    ///////////////////////////////////////////

    // Payment Handlers to Tokenization Handlers
    let checkoutPaymentHandlers: TokenizationHandlers;

    if (
      !this.options.paymentHandling ||
      this.options.paymentHandling === 'AUTO'
    ) {
      checkoutPaymentHandlers = CheckoutPaymentHandlers.create(
        this.context,
        this.options,
      );
    } else if (this.options.paymentHandling === 'MANUAL') {
      checkoutPaymentHandlers = {
        onTokenizeSuccess: this.options.onTokenizeSuccess,
        onTokenizeDidNotStart: this.options.onTokenizeDidNotStart,
        onTokenizeError: this.options.onTokenizeError,
        onResumeSuccess: this.options.onResumeSuccess,
        onResumeError: this.options.onResumeError,
        onTokenizeShouldStart: this.options.onTokenizeShouldStart,
        onTokenizeStart: this.options.onTokenizeStart,
        onResumePending: this.options.onResumePending,
      };
    } else {
      throw PrimerClientError.fromErrorCode(ErrorCode.INITIALIZATION_ERROR, {
        message: `Universal Checkout does not support "${this.options.paymentHandling}". Defaulting to the default value of "AUTO".`,
      });
    }

    // Augment tokenization handlers for Universal Checkout
    const checkoutTokenizationHandlers = CheckoutTokenizationHandlers.create(
      this.context,
      viewUtils,
      this.options,
      clientSessionActionService,
      checkoutPaymentHandlers,
    );

    // Payment method config
    const checkoutPaymentMethodConfig = CheckoutPaymentMethodConfig.create(
      store,
      viewUtils,
      this.options,
      clientSessionActionService,
    );

    const paymentMethodContextConfig = normalizeOptions({
      ...checkoutPaymentMethodConfig,
      ...checkoutTokenizationHandlers,
    });

    const paymentMethodContextFactory = createPaymentMethodContextFactory(
      this.context,
      paymentMethodContextConfig,
      store,
      store,
    );

    this.paymentMethodManager.init({
      styleManager: viewUtils.styleManager,
      options: this.options,
      context: this.context,
      paymentMethodContextFactory,
      paymentMethodContextConfig,
      store,
    });

    ///////////////////////////////////////////
    // Start flow
    ///////////////////////////////////////////
    startCheckoutFlow({
      tokenizationHandlers: checkoutTokenizationHandlers,
      context: this.context,
      options: this.options,
      store,
    });

    let paymentMethods: Record<string, BasePaymentMethod> = {};
    const processPaymentMethods = async () => {
      paymentMethods = await PaymentMethods.create(
        this.context,
        paymentMethodContextFactory,
        paymentMethodContextConfig,
        viewUtils.styleManager,
      );

      store.setPaymentMethods(paymentMethods);

      const paymentMethodsInfo = Object.entries(paymentMethods)
        .filter(
          ([key]) => !key.toUpperCase().startsWith(PrimerPaymentMethodPrefix),
        )
        .map(([, value]) =>
          this.mapBasePaymentMethodToPaymentMethodInfo(value),
        );

      this.options?.onAvailablePaymentMethodsLoad(paymentMethodsInfo);
    };

    await Promise.all([processPaymentMethods(), processCheckoutModules()]);

    if (Object.keys(store.getPaymentMethods()).length === 0) {
      throwPrimerClientError(
        PrimerClientError.fromErrorCode(ErrorCode.NO_PAYMENT_METHODS, {
          message: `No payment methods loaded. Cannot initialize Universal Checkout. \nMake sure that all payment methods are properly configured.`,
        }),
      );
    }

    clientSessionHandler.setOnClientConfigurationUpdated(
      (clientConfiguration) => {
        // Forward new client session

        this.options?.onClientSessionUpdate?.(
          internalClientSessionToClientSession(store.getClientSession()),
        );

        // Remove Checkout Modules
        const initialCheckoutModules = store.getCheckoutModules();
        const filteredCheckoutModules = initialCheckoutModules.filter(
          (checkoutModule) =>
            clientConfiguration.checkoutModules.find(
              (checkoutModuleConfig) =>
                checkoutModuleConfig.type === checkoutModule.type,
            ),
        );
        store.setCheckoutModules(filteredCheckoutModules);

        // Add Checkout Modules
        const newCheckoutModules = clientConfiguration.checkoutModules
          .map((checkoutModuleConfig) => {
            const hasCheckoutModule = !!store.getCheckoutModuleWithType(
              checkoutModuleConfig.type,
            );
            if (hasCheckoutModule) {
              return undefined;
            }

            return CheckoutModules.create(checkoutModuleConfig, {
              context: this.context,
              options: this.options,
              store,
              clientSessionActionService,
            });
          })
          .filter(Boolean);

        store.setCheckoutModules([
          ...store.getCheckoutModules(),
          ...newCheckoutModules,
        ]);

        // Update Checkout Modules
        clientConfiguration.checkoutModules.forEach((checkoutModuleConfig) => {
          const checkoutModule = store.getCheckoutModuleWithType(
            checkoutModuleConfig.type,
          );
          if (!checkoutModule) {
            return;
          }

          CheckoutModules.update(checkoutModule, checkoutModuleConfig);
        });
      },
    );
  }

  private mapBasePaymentMethodToPaymentMethodInfo(
    pm: BasePaymentMethod,
  ): PaymentMethodInfo {
    if (pm.type === PaymentMethodType.PAYMENT_CARD) {
      return {
        type: pm.type,
        managerType: HeadlessManagerType.CARD,
      };
    }

    return {
      type: pm.type,
      managerType:
        pm.remoteConfig?.implementationType === ImplementationType.NATIVE_SDK
          ? HeadlessManagerType.NATIVE
          : HeadlessManagerType.REDIRECT,
    };
  }
}
