import { animationCurve } from '@primer-io/shared-library/components';
import { FunctionalComponent } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import styled from 'styled-components';
import { usePresence } from '../utils/AnimatePresence/use-presence';

const StyledPopupOverlay = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  transition: all 500ms ${animationCurve};
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
`;

const StyledPopupOverlayContent = styled.div<{ isVisible: boolean }>`
  position: relative;
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 300ms ${animationCurve}, opacity 500ms;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  top: ${(p) => (p.isVisible ? 0 : '50%')};
`;

export const OverlayContentContainer: FunctionalComponent<any> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    setIsVisible(isPresent);

    if (!isPresent) {
      setTimeout(() => safeToRemove?.(), 500);
    }
  }, [isPresent]);

  return (
    <StyledPopupOverlay
      data-testId='Overlay'
      id='primer-overlay'
      isVisible={isVisible}
    >
      <StyledPopupOverlayContent isVisible={isVisible}>
        {children}
      </StyledPopupOverlayContent>
    </StyledPopupOverlay>
  );
};
