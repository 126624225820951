export const getCenteredPopupWindowOptions = (options: {
  popup: { width: number; height: number };
  parent: { left: number; top: number; width: number; height: number };
}) => ({
  left: options.parent.left + (options.parent.width - options.popup.width) / 2,
  top: options.parent.top + (options.parent.height - options.popup.height) / 2,
  width: options.popup.width,
  height: options.popup.height,
});

// https://developer.mozilla.org/en-US/docs/Web/API/Window/open#window_features
export const getWindowFeatures = (
  features: Record<string, string | number>,
): string =>
  Object.keys(features)
    .map((key) => `${key}=${features[key]}`)
    .join(',');
