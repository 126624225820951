import { getTimezoneOffset } from './getTimezoneOffset';
import window from './window';

export interface BrowserInfo {
  javaEnabled: boolean;
  language: string;
  colorDepth: number;
  screenHeight: number;
  screenWidth: number;
  timezoneOffset: number;
  userAgent: string;
}

export function getBrowserInfo(): BrowserInfo {
  return {
    javaEnabled: window.navigator.javaEnabled(),
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timezoneOffset: getTimezoneOffset(),
    userAgent: window.navigator.userAgent,
  };
}
