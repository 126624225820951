import {
  createCallbackHandler,
  printHandlerTimeoutWarning,
} from '../utils/CallbackHandler';
import CheckoutStore from '../store/CheckoutStore';
import { IFrameOverlayService } from '../core/IFrameService/IFrameOverlayService';
import {
  HeadlessUniversalCheckoutOptions,
  OnResumeSuccess,
  OnTokenizeSuccess,
  PaymentMethodData,
  PaymentMethodToken,
  ResumeToken,
  SinglePaymentMethodCheckoutOptions,
  ThreeDSVerification,
  TokenizationHandlers,
  UniversalCheckoutOptions,
} from '../types';
import ThreeDSecure from '../three-d-secure/ThreeDSecure';
import { PaymentMethodType } from '../enums/Tokens';
import { ProgressEvent, ThreeDSecureStatus } from '../enums/Tokenization';
import { ClientContext } from '../core/ClientContext';
import {
  createResumeSuccessCallback,
  createTokenizeSuccessCallback,
  IntentHandlers,
  SuccessCallbackErrorHandler,
} from './createSuccessCallback';

import { IViewUtils } from './ui/types';
import { ApiEvent } from '../analytics/constants/enums';
import { SuccessCallbackOptions } from '../core/ProgressNotifier';

import {
  ClientTokenIntent,
  CommonClientToken,
} from '../core/ClientTokenHandler';
import { ErrorCode, PrimerClientError } from '../errors';
import { Analytics } from '../analytics/Analytics';
import ThreeDSUtils from './ui/ThreeDSUtils';
import { ClientSessionActionService } from './client-session/ClientSessionActionService';

type PaymentMethodDecodedClientToken = CommonClientToken & {
  intent: string;
  redirectUrl: string;
  statusUrl: string;
};

export type InternalTokenizationHandlers = Pick<
  UniversalCheckoutOptions,
  | 'onTokenizeStart'
  | 'onTokenizeError'
  | 'onResumeError'
  | 'onTokenizeShouldStart'
  | 'onTokenizeDidNotStart'
> & {
  onTokenizeSuccess: (data: PaymentMethodToken) => Promise<void>;
  onResumeSuccess: (data: ResumeToken, onError: () => void) => Promise<void>;
  onTokenizeProgress: (evt: { type: string }) => void;
};

const handleThreeDSAnalytics = (
  analytics: Analytics,
  verification: ThreeDSVerification,
) => {
  if (verification.error) {
    analytics.callV1({ event: ApiEvent.threeDSecureError });
  }

  if (verification.status === ThreeDSecureStatus.FAILED) {
    analytics.callV1({ event: ApiEvent.threeDSecureFailed });
  }

  if (verification.status === ThreeDSecureStatus.SKIPPED) {
    analytics.callV1({ event: ApiEvent.threeDSecureSkipped });
  }

  if (verification.status === ThreeDSecureStatus.SUCCESS) {
    analytics.callV1({ event: ApiEvent.threeDSecureSuccess });
  }
};

const showError = (error: PrimerClientError, store: CheckoutStore) => {
  store.setIsLoading(false);
  store.setIsProcessing(false);

  let errorMessage;
  if (!error.isFromDeveloper) {
    errorMessage = store.getTranslations()?.tokenizationError;
  } else {
    errorMessage = error.message
      ? error.message
      : store.getTranslations()?.tokenizationError;
  }
  store.setErrorMessage(errorMessage);
};

const createOnSuccessWithHandler = (
  onSuccess: OnTokenizeSuccess | OnResumeSuccess | undefined,
  type: 'TOKENIZE' | 'RESUME',
) => {
  return async (data) => {
    if (!onSuccess) {
      return true;
    }

    const callbackHandler = createCallbackHandler({
      functionNames: [
        'handleFailure',
        'handleSuccess',
        'continueWithNewClientToken',
      ],
      warning: {
        timeout: 10000,
        onTimeout: ({ timeout }) =>
          printHandlerTimeoutWarning({
            timeout,
            methodDefinition:
              type === 'TOKENIZE'
                ? 'onTokenizeSuccess(paymentMethodTokenData, handler)'
                : 'onResumeSuccess(resumeTokenData, handler)',
            functionDescriptions: [
              {
                definition: 'handler.handleSuccess()',
                description: 'Show a success screen.',
              },

              {
                definition: 'handler.handleFailure(errorMessage)',
                description:
                  'Cancel the flow and show an error message. Not providing an `errorMessage` will display a default localized error message.',
              },

              {
                definition: 'handler.continueWithNewClientToken(clientToken)',
                description:
                  'Continue the flow with a new client token to trigger a new step.',
              },
            ],
          }),
      },
    });

    onSuccess(data, callbackHandler.handler);
    const { functionName, args } = await callbackHandler.promise;

    if (functionName === 'handleFailure') {
      throw new Error(args[0]);
    } else if (functionName === 'handleSuccess') {
      return true;
    } else if (functionName === 'continueWithNewClientToken') {
      return { clientToken: args[0] };
    }

    return true;
  };
};

const createOnTokenizeSuccessWithHandler = (
  onTokenizeSuccess: OnTokenizeSuccess | undefined,
) => createOnSuccessWithHandler(onTokenizeSuccess, 'TOKENIZE');
const createOnResumeSuccessWithHandler = (
  onResumeSuccess: OnResumeSuccess | undefined,
) => createOnSuccessWithHandler(onResumeSuccess, 'RESUME');

export const CheckoutTokenizationHandlers = {
  create(
    context: ClientContext,
    viewUtils: IViewUtils,
    options:
      | UniversalCheckoutOptions
      | SinglePaymentMethodCheckoutOptions
      | HeadlessUniversalCheckoutOptions,
    clientSessionActionService: ClientSessionActionService,
    tokenizationHandlers: TokenizationHandlers,
  ): InternalTokenizationHandlers {
    let threeDS: ThreeDSecure | undefined;

    const hasCard = !!context.session.paymentMethods.find(
      (paymentMethod) => paymentMethod.type === PaymentMethodType.PAYMENT_CARD,
    );

    if (hasCard) {
      threeDS = ThreeDSUtils.setup(context, viewUtils);
    }

    const handle3DS = async (decodedClientToken) => {
      if (!threeDS) {
        return '';
      }

      viewUtils.store.setIsLoading(true);
      viewUtils.store.setIsProcessing(true);

      const verification: ThreeDSVerification = await threeDS.verify({
        token: decodedClientToken.tokenId,
      });

      handleThreeDSAnalytics(context.analytics, verification);

      return verification.resumeToken;
    };

    const handleProcessor3DS = async (
      statusUrl: string,
      redirectUrl: string,
    ) => {
      viewUtils.store.setIsLoading(true);
      viewUtils.store.setIsProcessing(true);

      const iFrameService = new IFrameOverlayService(viewUtils.store, context);

      return iFrameService.presentIFrameOverlay(statusUrl, redirectUrl);
    };

    const onResumeError = async (error: PrimerClientError) => {
      showError(error, viewUtils.store);

      tokenizationHandlers.onResumeError?.(error);
    };

    const onResumePending = (paymentMethodData: PaymentMethodData) => {
      tokenizationHandlers.onResumePending?.(paymentMethodData);
    };

    const onResumeSuccess = async (data: ResumeToken, onError?: () => void) => {
      const onResumeSuccessFail = (error) => {
        showError(error, viewUtils.store);
        onError?.();
      };

      await createResumeSuccessCallback(
        createOnResumeSuccessWithHandler(tokenizationHandlers.onResumeSuccess),
        context,
        viewUtils,
        options,
        clientSessionActionService,
        { handlers: {} },
        onResumeSuccessFail,
      )(data);
    };

    return {
      onTokenizeProgress(ev) {
        switch (ev.type) {
          case ProgressEvent.TOKENIZE_STARTED:
            viewUtils.store.setIsLoading(true);
            viewUtils.store.setIsProcessing(true);
            break;

          case ProgressEvent.TOKENIZE_ERROR:
          case ProgressEvent.TOKENIZE_DID_NOT_START:
            viewUtils.store.setIsLoading(false);
            viewUtils.store.setIsProcessing(false);
            break;

          default:
            break;
        }
      },
      onTokenizeShouldStart: tokenizationHandlers.onTokenizeShouldStart,
      onTokenizeDidNotStart: tokenizationHandlers.onTokenizeDidNotStart,
      onTokenizeStart: () => {
        viewUtils.store.setErrorMessage(null);
        tokenizationHandlers.onTokenizeStart?.();
      },
      onTokenizeError: (error) => {
        const errorMessage = viewUtils.store.getTranslations()
          .tokenizationError;
        if (errorMessage) {
          viewUtils.store.setErrorMessage(errorMessage);
        }
        clientSessionActionService.unselectPaymentMethod();
        context.analytics.callV1({ event: ApiEvent.tokenizationError });
        tokenizationHandlers.onTokenizeError?.(error);
      },
      async onTokenizeSuccess(
        data: PaymentMethodToken,
        callOptions?: SuccessCallbackOptions,
      ) {
        // Default client token handler

        let clientTokenHandlers: IntentHandlers;
        let resumeError: SuccessCallbackErrorHandler | undefined;

        // Client token handler from callOptions
        if (callOptions?.clientTokenHandler?.clientTokenIntent) {
          const {
            clientTokenIntent,
            handler,
            onError,
          } = callOptions.clientTokenHandler;

          clientTokenHandlers = {
            newClientTokenRequired: true,
            handlers: {
              [clientTokenIntent]: async (decodedClientToken) => {
                try {
                  const returnValue = await handler({
                    decodedClientToken,
                  });

                  if (
                    !returnValue?.resumeToken &&
                    returnValue?.paymentMethodData
                  ) {
                    onResumePending(returnValue.paymentMethodData);
                    return;
                  }

                  const {
                    resumeToken,
                    onError: onResumeErrorCallback,
                  } = returnValue;
                  onResumeSuccess({ resumeToken }, onResumeErrorCallback);
                } catch (e) {
                  onResumeError(e);
                }
              },
            },
          };

          resumeError = (error: PrimerClientError) => {
            try {
              onError?.();
            } finally {
              showError(error, viewUtils.store);

              if (!error.isFromDeveloper) {
                tokenizationHandlers.onResumeError?.(error);
              }
            }
          };
        } else {
          // 3DS
          clientTokenHandlers = {
            newClientTokenRequired: false,
            handlers: {
              [ClientTokenIntent.PROCESSOR_3DS]: async (decodedClientToken) => {
                if (
                  decodedClientToken.intent !== ClientTokenIntent.PROCESSOR_3DS
                )
                  return;

                const {
                  statusUrl,
                  redirectUrl,
                } = decodedClientToken as PaymentMethodDecodedClientToken;

                try {
                  const resumeToken = await handleProcessor3DS(
                    statusUrl,
                    redirectUrl,
                  );

                  if (resumeToken) {
                    onResumeSuccess({ resumeToken });
                  }
                } catch (e) {
                  onResumeError(e);
                }
              },
              [ClientTokenIntent.THREEDS_AUTHENTICATION]: async (
                decodedClientToken,
              ) => {
                if (
                  decodedClientToken.intent !==
                  ClientTokenIntent.THREEDS_AUTHENTICATION
                )
                  return;

                try {
                  const resumeToken = await handle3DS(decodedClientToken);

                  if (resumeToken) {
                    onResumeSuccess({ resumeToken });
                    return;
                  }
                } catch (e) {
                  throw PrimerClientError.fromErrorCode(
                    ErrorCode.THREE_DS_AUTH_FAILED,
                    { message: 'Cannot perform threeDS' },
                  );
                }
              },
            },
          };
          resumeError = (error) => {
            showError(error, viewUtils.store);

            if (!error.isFromDeveloper) {
              tokenizationHandlers.onResumeError?.(error);
            }
          };
        }

        const onSuccess = createTokenizeSuccessCallback(
          createOnTokenizeSuccessWithHandler(
            tokenizationHandlers.onTokenizeSuccess,
          ),
          context,
          viewUtils,
          options,
          clientSessionActionService,
          clientTokenHandlers,
          resumeError,
        );

        onSuccess(data, callOptions);

        context.analytics.callV1({
          event: ApiEvent.completedCheckout,
          data: { paymentMethod: data.paymentInstrumentType },
        });
      },
      onResumeError,
      onResumeSuccess,
    };
  },
};
