import { Alpha3CurrencyCode } from '../types';

type CurrencyDisplayFormat = 'narrowSymbol' | 'symbol';

export default function createAmountLocalizer(
  amount: number,
  currencyCode: Alpha3CurrencyCode | string,
  decimalDigits: number,
  formattedLocale: any,
) {
  return {
    localizeAmount(currencyDisplay: CurrencyDisplayFormat) {
      return new Intl.NumberFormat(formattedLocale, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay,
      }).format(amount / decimalDigits);
    },
  };
}
