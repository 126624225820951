import { FunctionalComponent } from 'preact';
import { useEffect, useLayoutEffect } from 'preact/hooks';
import {
  useSelector,
  useCheckoutContext,
  useCheckoutStore,
  useStoreSelector,
  usePaymentMethodStore,
  useCheckoutModule,
} from '@primer-io/shared-library/contexts';
import {
  HostedInput,
  Input,
  Label,
  ErrorLabel,
} from '@primer-io/shared-library/components';
import { UseFormResult } from '@primer-io/shared-library/form';

import { CardPaymentMethodType } from '../../enums/Tokens';
import {
  PaymentMethodOption,
  CardNetworkOption,
} from '../../models/ClientSession';
import { SceneEnum, ElementID } from '../../enums/Checkout';
import { useDisableComponent } from '../../utils/hooks';
import { useWaitForComponent } from '../../utils/SceneLoader/WaitForComponents';
import { nextTick } from '../../utils/nextTick';
import { CreditCard } from './CreditCard';
import { getAttribute } from '../../checkout/DomUtilities';
import { getFirstFocusableChild } from '../../checkout/focus';
import SurchargeCard from '../../components/SurchargeCard';
import { CreditCardStore, CreditCardStoreSelector } from './CreditCardStore';
import {
  CardInformationStore,
  CardInformationStoreSelector,
} from '../../checkout-modules/card-information';
import CreditCardNumberInputIcon from './CreditCardNumberInputIcon';

const useCreditCardFormState = (
  cardPaymentMethodType: CardPaymentMethodType,
) => {
  const creditCardStore = usePaymentMethodStore<CreditCardStore>(
    cardPaymentMethodType,
  );

  const expiryDateFieldError = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getExpiryDateFieldError,
  );

  const isExpiryDateFieldFocused = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getExpiryDateFieldFocused,
  );

  const expiryDateInputId = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getExpiryDateInputId,
  );

  const cvvFieldError = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getCvvFieldError,
  );

  const isCvvFieldFocused = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getCvvFieldFocused,
  );

  const cvvInputId = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getCvvInputId,
  );

  const isCvvFieldEnabled = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getIsCvvFieldEnabled,
  );

  const numberFieldError = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getNumberFieldError,
  );

  const isNumberFieldFocused = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getNumberFieldFocused,
  );

  const numberInputId = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getNumberInputId,
  );

  const cardholderNameFieldError = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getCardholderNameFieldError,
  );

  const cardholderNameInputId = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getCardholderNameInputId,
  );

  const cardInfo = useStoreSelector(
    creditCardStore,
    CreditCardStoreSelector.getMetadata,
  );
  return {
    expiryDateFieldError,
    isExpiryDateFieldFocused,
    expiryDateInputId,
    cvvFieldError,
    isCvvFieldFocused,
    isCvvFieldEnabled,
    numberFieldError,
    numberInputId,
    cvvInputId,
    isNumberFieldFocused,
    cardholderNameFieldError,
    cardholderNameInputId,
    cardType: cardInfo?.type,
    cardNumberLength: cardInfo?.cardNumberLength,
  };
};

type Props = {
  cardBillingAddressForm?: UseFormResult;
  cardDescriptionForm?: UseFormResult;
  cardPaymentMethodType?: CardPaymentMethodType;
};

const CreditCardForm: FunctionalComponent<Props> = ({
  cardBillingAddressForm,
  cardDescriptionForm,
  cardPaymentMethodType = CardPaymentMethodType.PAYMENT_CARD,
}) => {
  const { options } = useCheckoutContext();
  const store = useCheckoutStore();
  const locale = store.getLocale();
  const labels = useSelector((s) => s.translation);

  const card = store.getPaymentMethodWithType<CreditCard>(
    cardPaymentMethodType,
  );
  const creditCardStore = usePaymentMethodStore<CreditCardStore>(
    cardPaymentMethodType,
  );

  const cardInformation = useCheckoutModule<CardInformationStore>(
    'CARD_INFORMATION',
  );

  const moduleCardholderNameVisible = useStoreSelector(
    cardInformation,
    CardInformationStoreSelector.getIsShowingCardholderName,
    undefined,
  );
  const optionsCardholderNameVisible = options.card?.cardholderName?.visible;

  let isShowingCardholderName;
  if (moduleCardholderNameVisible !== undefined) {
    isShowingCardholderName = moduleCardholderNameVisible;
  } else {
    isShowingCardholderName = optionsCardholderNameVisible ?? true;
  }

  const cardholderNamePlaceholder = options.card?.cardholderName?.placeholder;

  const currencyCode = useSelector((s) => s.clientSession?.order.currencyCode);

  const paymentMethodOptions = useSelector(
    (s) => s.clientSession?.paymentMethod?.options as PaymentMethodOption[],
  );

  let cardNetworkOptions: CardNetworkOption[] | undefined;
  if (store.hasCardSurcharge) {
    cardNetworkOptions = paymentMethodOptions?.find((option) => {
      return option.type === 'PAYMENT_CARD';
    })?.networks;
  }

  const showSavePaymentMethod = useSelector(
    (s) => s.options.showSavePaymentMethod,
  );

  const { disableControls } = useDisableComponent({
    scene: SceneEnum.CHOOSE_PAYMENT_METHOD,
  });

  ///////////////////////////////////////////
  // Form
  ///////////////////////////////////////////

  const {
    expiryDateFieldError,
    isExpiryDateFieldFocused,
    expiryDateInputId,
    cvvFieldError,
    isCvvFieldFocused,
    isCvvFieldEnabled,
    cvvInputId,
    numberFieldError,
    isNumberFieldFocused,
    cardholderNameInputId,
    numberInputId,
    cardType: type,
    cardNumberLength,
    cardholderNameFieldError,
  } = useCreditCardFormState(cardPaymentMethodType);

  ///////////////////////////////////////////
  // side effects
  ///////////////////////////////////////////

  const { wait, ready } = useWaitForComponent();

  const disableIFrames = (disabled) => {
    card?.setDisabled(disabled);
  };

  useLayoutEffect(() => {
    wait(cardPaymentMethodType);
  }, []);

  useEffect(() => {
    if (!card) return;
    (async () => {
      await card.mount();
      ready(cardPaymentMethodType);
      disableIFrames(disableControls);

      const paymentMethodCount = Object.keys(store.getPaymentMethods()).length;
      if (!options.style?.focusCheckoutOnInit || paymentMethodCount !== 1) {
        return;
      }

      await nextTick(async () => {
        getFirstFocusableChild(ElementID.CARD_FORM)?.focus();
      });
    })();
  }, [card]);

  useEffect(() => {
    disableIFrames(disableControls);
  }, [disableControls]);

  ///////////////////////////////////////////
  // Callbacks
  ///////////////////////////////////////////
  const handleCardholderNameChange = () => {
    const inputValue = getAttribute(cardholderNameInputId, 'value');
    if (inputValue?.trim()) {
      creditCardStore.setFieldError('cardholderName', null);
    }
  };

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////
  return (
    <form
      id='primer-checkout-card-form'
      className='PrimerCheckout__sceneElement PrimerCheckout__cardForm'
      onSubmit={(e) => {
        e.preventDefault();
        store.triggerSubmitButtonClick();
      }}
    >
      <div
        id='primer-checkout-card-number-field'
        className='PrimerCheckout__formField'
      >
        <Label testId={'CreditCardForm.inputLabel'} text={labels?.cardNumber} />
        <HostedInput
          inputId={numberInputId}
          focused={isNumberFieldFocused}
          hasErrors={!!numberFieldError}
          beforeInput={<CreditCardNumberInputIcon type={type} />}
          afterInput={
            <SurchargeCard
              networkType={type}
              cardNumberLength={cardNumberLength}
              cardNetworkOptions={cardNetworkOptions}
              currencyCode={currencyCode}
              locale={locale}
            />
          }
        />

        <ErrorLabel
          id='primer-checkout-card-number'
          message={numberFieldError}
        />
      </div>
      <div className='PrimerCheckout__formField PrimerCheckout__formField--dual'>
        <div
          id='primer-checkout-card-expirydate-field'
          className='PrimerCheckout__formField'
        >
          <Label
            testId={'CreditCardForm.inputLabel'}
            text={labels?.cardExpiry}
          />
          <HostedInput
            inputId={expiryDateInputId}
            focused={isExpiryDateFieldFocused}
            hasErrors={!!expiryDateFieldError}
          />
          <ErrorLabel
            message={expiryDateFieldError}
            id='primer-checkout-card-expirydate-error'
          />
        </div>
        {isCvvFieldEnabled && (
          <div
            id='primer-checkout-card-cvv-field'
            className='PrimerCheckout__formField'
          >
            <Label
              testId={'CreditCardForm.inputLabel'}
              text={labels?.cardCVV}
            />
            <HostedInput
              inputId={cvvInputId}
              focused={isCvvFieldFocused}
              hasErrors={!!cvvFieldError}
            />
            <ErrorLabel
              message={cvvFieldError}
              id='primer-checkout-card-cvv-error'
            />
          </div>
        )}
      </div>
      {isShowingCardholderName && (
        <div
          id='primer-checkout-cardholder-name-field'
          className='PrimerCheckout__formField'
        >
          <Label
            testId='CreditCardForm.inputLabel'
            text={labels?.cardholderName}
          />
          <Input
            inputId={cardholderNameInputId}
            placeholder={
              (cardholderNamePlaceholder as string) ??
              labels?.cardholderNamePlaceholder
            }
            autoComplete={'cc-name'}
            disabled={disableControls}
            hasErrors={!!cardholderNameFieldError}
            onChange={handleCardholderNameChange}
          />
          <ErrorLabel
            message={cardholderNameFieldError}
            id='primer-checkout-cardholder-name-error'
          />
        </div>
      )}
      {cardDescriptionForm && (
        <div className='PrimerCheckout__formField'>
          {cardDescriptionForm?.renderForm()}
        </div>
      )}
      {cardBillingAddressForm && (
        <div className='PrimerCheckout__formField'>
          {cardBillingAddressForm.renderForm()}
        </div>
      )}
      {showSavePaymentMethod && (
        <div
          id='primer-checkout-save-payment-method-field'
          className='PrimerCheckout__formField PrimerCheckout__formField--orientation-horizontal'
        >
          <input
            id='primer-checkout-save-payment-method-input'
            type='checkbox'
            className='PrimerCheckout__checkbox'
          />
          <label
            htmlFor='primer-checkout-save-payment-method-input'
            className='PrimerCheckout__label PrimerCheckout__label--size-sm'
          >
            {labels?.savePaymentMethod}
          </label>
        </div>
      )}
      <button type='submit' hidden></button>
    </form>
  );
};

export default CreditCardForm;
