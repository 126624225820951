import urljoin from 'url-join';

import {
  ErrorCode,
  PrimerClientError,
  throwPrimerClientError,
} from '../errors';
import { InternalFlowOptions } from '../internalTypes';

import {
  CheckoutUXFlow,
  PaymentFlow,
  PaymentMethodType,
  SinglePaymentMethodCheckoutOptions,
  UniversalCheckoutOptions,
} from '../types';
import { Environment } from '../utils/Environment';
import { PaymentMethodConfig } from '../models/ClientConfiguration';
import { ClientContext } from '../core/ClientContext';
import { IFrameEventType } from '../core/IFrameEventType';

const DEACTIVATED_PAYMENT_METHODS = ['ADYEN_BANK_TRANSFER'];

const EXPRESS_PAYMENT_METHODS = ['APPLE_PAY', 'PAYPAL'];

export const filterPaymentMethodConfigs = (
  paymentMethods: PaymentMethodConfig[],
  renderOptions: InternalFlowOptions,
): PaymentMethodConfig[] => {
  paymentMethods = paymentMethods.filter(
    (paymentMethod) =>
      !DEACTIVATED_PAYMENT_METHODS.includes(paymentMethod.type),
  );

  if (
    !renderOptions.uxFlow ||
    renderOptions.uxFlow === CheckoutUXFlow.CHECKOUT
  ) {
    paymentMethods = paymentMethods.filter(
      (paymentMethod) =>
        (renderOptions as UniversalCheckoutOptions).allowedPaymentMethods?.includes(
          paymentMethod.type,
        ) ?? true,
    );
  } else if (renderOptions.uxFlow === CheckoutUXFlow.EXPRESS_CHECKOUT) {
    paymentMethods = paymentMethods.filter((paymentMethod) => {
      return EXPRESS_PAYMENT_METHODS.includes(paymentMethod.type);
    });
  } else if (
    renderOptions.uxFlow === CheckoutUXFlow.SINGLE_PAYMENT_METHOD_CHECKOUT
  ) {
    paymentMethods = paymentMethods.filter(
      (paymentMethod) =>
        (renderOptions as SinglePaymentMethodCheckoutOptions).paymentMethod ===
        paymentMethod.type,
    );

    if (!(renderOptions as SinglePaymentMethodCheckoutOptions).paymentMethod) {
      throwPrimerClientError(
        PrimerClientError.fromErrorCode(ErrorCode.PAYMENT_METHOD_NOT_PROVIDED, {
          message:
            'Checkout could not be initialized. Please provide the payment method which should be rendered in the single payment method flow.',
        }),
      );
    }

    if (paymentMethods.length === 0) {
      throwPrimerClientError(
        PrimerClientError.fromErrorCode(ErrorCode.PAYMENT_METHOD_NOT_SETUP, {
          message: `Checkout could not be initialized. ${
            (renderOptions as SinglePaymentMethodCheckoutOptions).paymentMethod
          } has not been configured for this account. Please configure the connection in your Primer dashboard: https://sandbox-dashboard.primer.io`,
        }),
      );
    }
  }

  return paymentMethods;
};

const getModulesUrl = () => {
  const version = Environment.get('PRIMER_SDK_VERSION', '0.0.0-local');
  return urljoin(Environment.get('PRIMER_MODULES_URL', ''), version);
};

const initializeClientContext = async (context: ClientContext) => {
  const clientToken = context.clientTokenHandler.getCurrentClientToken();
  if (!clientToken) {
    return;
  }

  const decodedClientToken = context.clientTokenHandler.getCurrentDecodedClientToken();
  if (!decodedClientToken) {
    return;
  }

  const clientConfiguration = await context.clientConfigurationHandler.fetchClientConfiguration(
    decodedClientToken,
  );

  const { clientSession, checkoutModules } = clientConfiguration;

  if (clientConfiguration.paymentMethods.length === 0) {
    throw PrimerClientError.fromErrorCode(ErrorCode.NO_PAYMENT_METHODS, {
      message: `No payment methods found for this session. Cannot initialize the SDK. \nMake sure that the payment methods are properly configured on your Dashboard, and that at least one payment method can be displayed with the data provided in the Client Session.`,
    });
  }

  context.messageBus.publish('api-controller', {
    type: IFrameEventType.SESSION,
    payload: {
      coreUrl: clientConfiguration.coreUrl,
      pciUrl: clientConfiguration.pciUrl,
    },
  });

  const paymentMethods = filterPaymentMethodConfigs(
    clientConfiguration.paymentMethods,
    context.clientOptions,
  );
  if (paymentMethods.length === 0) {
    throw PrimerClientError.fromErrorCode(ErrorCode.NO_PAYMENT_METHODS, {
      message: `\`allowedPaymentMethods\` filtered out all the payment methods. Cannot initialize the SDK.\n Make sure that \`allowedPaymentMethods\` does not disallow all your supported payment methods.`,
    });
  }

  const cardPaymentMethod = clientConfiguration?.paymentMethods.find(
    (elm) => elm.type === PaymentMethodType.PAYMENT_CARD,
  );

  context.store.setClientSessionInfo({
    isTeardown: false,
    modulesUrl: getModulesUrl(),
    paymentMethods,
    checkoutModules,
    paymentFlow: decodedClientToken.paymentFlow || PaymentFlow.DEFAULT,
    clientSession,
    env: clientConfiguration.env,
    production: clientConfiguration.env === 'PRODUCTION',

    // TODO: refactor the usage of 3DS tokens
    get threeDSecureToken(): Nullable<string> {
      return cardPaymentMethod?.options?.threeDSecureToken ?? null;
    },
    get threeDSecureInitUrl(): Nullable<string> {
      return cardPaymentMethod?.options?.threeDSecureInitUrl ?? null;
    },
    get threeDSecureProvider(): string {
      return cardPaymentMethod?.options?.threeDSecureProvider ?? 'CARDINAL';
    },
    get threeDSecureEnabled(): boolean {
      return (
        cardPaymentMethod?.options?.threeDSecureEnabled ??
        Boolean(cardPaymentMethod?.options?.threeDSecureInitUrl ?? null)
      );
    },
  });
};

export default initializeClientContext;
