import { FunctionalComponent } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import styled, { css } from 'styled-components';
import {
  animationCurve,
  BaseButton,
  createWithStyle,
  getBlockStyle,
  getTextStyle,
  xsBreakpoint,
} from '@primer-io/shared-library/components';

import { useCheckoutStore } from '@primer-io/shared-library/contexts';
import { PaymentInstrumentType } from '../enums/Tokens';
import { VaultListItem, CardDetails, PayPalDetails } from '../types';
import { toggleClass } from '../utils/toggleClass';
import { ClassName } from '../enums/Checkout';
import { delay } from '../utils/delay';
import { usePresence } from '../utils/AnimatePresence/use-presence';

const withSavedPaymentMethodButtonStyle = createWithStyle(
  (style) => style?.savedPaymentMethodButton,
);

const getSavedPaymentButtonStyle = (style) => css`
  ${getBlockStyle(style)}
  & .PrimerCheckout__label {
    ${getTextStyle(style)}
  }

  &:hover {
    ${getBlockStyle(style?.hover)}
    & .PrimerCheckout__label {
      ${getTextStyle(style?.hover)}
    }
  }

  &:focus {
    ${getBlockStyle(style?.focus)}
    & .PrimerCheckout__label {
      ${getTextStyle(style?.focus)}
    }
  }
`;

const SavedPaymentMethodButton = styled(BaseButton)`
  display: flex;
  flex-direction: row;

  outline: none;
  padding: 8px 16px;
  align-items: center;
  position: relative;
  text-align: left;

  border-width: 1px;
  border-style: solid;

  transition: box-shadow 100ms ease, border-color 100ms ease, background-color: 100ms ease;
  cursor: pointer;

  ${withSavedPaymentMethodButtonStyle((style) =>
    getSavedPaymentButtonStyle(style?.base),
  )}

  &.PrimerCheckout--selected {
    ${withSavedPaymentMethodButtonStyle((style) =>
      getSavedPaymentButtonStyle(style?.selected),
    )}
  }
`;

const ButtonLabel = styled.span`
  font-size: 17px;
  margin-left: 0.5em;
  white-space: pre;
  direction: ltr;
`;

const ButtonDetail = styled.span`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;

  ${xsBreakpoint} {
    flex-direction: column;
    align-items: flex-start;
    display: inline-grid;

    & ${ButtonLabel} {
      margin-left: 0px;
      width: 100%;
      text-align: left;
    }
  }
`;

const ButtonIcon = styled.img<{ isRtlLocale: boolean }>`
  display: flex;
  flex-direction: column;

  flex: 0 0 auto;
  width: 30px;
  max-width: 30px;
  ${(p) => (p.isRtlLocale ? 'margin-left: 24px;' : 'margin-right: 24px;')}
  transition: width 100ms ease;
`;

type VaultedProps = {
  vaultedItem: VaultListItem;
};

const CardContent: FunctionalComponent<VaultedProps> = ({ vaultedItem }) => {
  const store = useCheckoutStore();
  const details = vaultedItem.details as CardDetails;
  const isRtlLocale = store.getIsRtlLocale();

  return (
    <>
      <ButtonIcon
        className='PrimerCheckout__savedPaymentMethodIcon'
        src={details.icon}
        alt={details.network}
        isRtlLocale={isRtlLocale}
      />
      <ButtonContent className='PrimerCheckout__savedPaymentMethodContent'>
        <ButtonDetail className='PrimerCheckout__label PrimerCheckout__savedPaymentMethodDetail'>
          {details.userDescription ?? details.name}
        </ButtonDetail>
        <ButtonLabel className='PrimerCheckout__label PrimerCheckout__savedPaymentMethodLabel'>
          {details.cardNumber}
        </ButtonLabel>
      </ButtonContent>
    </>
  );
};

const Card = {
  Content: CardContent,

  getAriaLabel: ({ vaultedItem }) =>
    `${vaultedItem.details.network} ${vaultedItem.details.name}, ending with ${vaultedItem.details.last4Digits}`,
};

const PayPalContent: FunctionalComponent<VaultedProps> = ({ vaultedItem }) => {
  const store = useCheckoutStore();
  const details = vaultedItem.details as PayPalDetails;
  const isRtlLocale = store.getIsRtlLocale();

  return (
    <>
      <ButtonIcon
        className='PrimerCheckout__savedPaymentMethodIcon'
        src={details.icon}
        alt=''
        isRtlLocale={isRtlLocale}
      />
      <ButtonContent className='PrimerCheckout__savedPaymentMethodContent'>
        <ButtonDetail className='PrimerCheckout__label PrimerCheckout__savedPaymentMethodDetail'>
          {details.email}
        </ButtonDetail>
      </ButtonContent>
    </>
  );
};

const PayPal = {
  Content: PayPalContent,

  getAriaLabel: ({ vaultedItem }) => `PayPal ${vaultedItem.details.email}`,
};

const Details = {
  [PaymentInstrumentType.CARD]: Card,
  [PaymentInstrumentType.PAYPAL_VAULTED]: PayPal,
};

///////////////////////////////////////////
// Item
///////////////////////////////////////////

const Root = styled.div`
  transition: height ${animationCurve} 300ms, transform ${animationCurve} 300ms,
    opacity ${animationCurve} 200ms;
  transform-origin: top center;

  &--exiting {
    opacity: 0;
    transform: translateY(-30px) scaleY(0.9);
    height: 0px;
  }

  &:not(:last-child) {
    & .PrimerCheckout__savedPaymentMethod {
      margin-bottom: 16px;
    }
  }
`;

type Props = {
  vaultedItem: VaultListItem;
  disabled?: boolean;
  selected?: boolean;
  onSelect?: () => void;
};

const PaymentMethodTokenItem: FunctionalComponent<Props> = ({
  vaultedItem,
  disabled = false,
  selected = false,
  onSelect,
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isPresent) {
      return;
    }

    (async () => {
      const paymentMethodContainer = containerRef.current;
      paymentMethodContainer.style.height = `${paymentMethodContainer.scrollHeight}px`;
      toggleClass(
        paymentMethodContainer,
        `${ClassName.SAVED_PAYMENT_METHOD_CONTAINER}--exiting`,
        true,
      );

      // Refresh layout
      paymentMethodContainer.getBoundingClientRect();
      paymentMethodContainer.style.height = '0px';

      await delay(300);
      safeToRemove?.();
    })();
  }, [isPresent]);

  const { Content, getAriaLabel } = Details[vaultedItem.type];
  const args = { vaultedItem };

  return (
    <Root
      ref={containerRef}
      className='PrimerCheckout__savedPaymentMethodContainer'
    >
      <SavedPaymentMethodButton
        type='button'
        data-token={vaultedItem.id}
        className={`PrimerCheckout__savedPaymentMethod PrimerCheckout__token ${
          selected ? 'PrimerCheckout--selected' : ''
        }`}
        aria-label={getAriaLabel?.(args)}
        aria-checked={selected}
        role='checkbox'
        onClick={onSelect}
        disabled={disabled}
      >
        {Content && <Content {...args} />}
      </SavedPaymentMethodButton>
    </Root>
  );
};

export default PaymentMethodTokenItem;
