export enum ApiEvent {
  startedCheckout = 'STARTED_CHECKOUT',
  loadedCheckoutUi = 'LOADED_CHECKOUT_UI',
  loadedVaultManagerUi = 'LOADED_VAULT_MANAGER_UI',
  completedCheckout = 'COMPLETED_CHECKOUT',
  triggered3ds = 'TRIGGERED_3DS',
  validationError = 'VALIDATION_ERROR',
  tokenizationError = 'TOKENIZATION_ERROR',
  threeDSecureError = '3DS_ERROR',
  threeDSecureFailed = '3DS_FAIL',
  threeDSecureSuccess = '3DS_SUCCESS',
  threeDSecureSkipped = '3DS_SKIPPED',
  tokenizeCalled = 'TOKENIZE_CALLED',
  creditCardValidationSuccess = 'CREDIT_CARD_VALIDATION_SUCCESS',
  creditCardValidationError = 'CREDIT_CARD_VALIDATION_ERROR',
}

export enum ClickEvent {
  clickedSubmitButton = 'CLICKED_SUBMIT_BUTTON',
  clickedAPMButton = 'CLICKED_APM_BUTTON',
  clickedPayPalButton = 'CLICKED_PAYPAL_BUTTON',
  clickedDirectDebitButton = 'CLICKED_DIRECT_DEBIT_BUTTON',
  clickedApplePayButton = 'CLICKED_APPLE_PAY_BUTTON',
  clickedGooglePayButton = 'CLICKED_GOOGLE_PAY_BUTTON',
  clickedKlarnaButton = 'CLICKED_KLARNA_BUTTON',
}

export enum PaymentIntent {
  adyenKlarna = 'ADYEN_KLARNA',
  applePay = 'APPLE_PAY',
  googlePay = 'GOOGLE_PAY',
  paypal = 'PAYPAL',
  directDebit = 'DIRECT_DEBIT',
  paymentCard = 'PAYMENT_CARD',
  klarna = 'KLARNA',
  mollieGiftCard = 'MOLLIE_GIFT_CARD',
  netsPayByCard = 'NETS_PAY_BY_CARD',
  payNlKaartdirect = 'PAY_NL_KAARTDIRECT',
}

export enum MockEvent {
  mock = 'MOCK',
}
