import CheckoutStore from '../store/CheckoutStore';
import {
  UniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
  VaultManagerOptions,
} from '../types';
import {
  SubmitButtonCallbackHandler,
  SubmitButtonCallbacks,
} from './SubmitButtonCallbackHandler';
import { ClientContext } from '../core/ClientContext';

export const CallbackHandlers = {
  create(
    store: CheckoutStore,
    context: ClientContext,
    options:
      | UniversalCheckoutOptions
      | VaultManagerOptions
      | SinglePaymentMethodCheckoutOptions,
  ) {
    const createSubmitButtonCallbackHandler = async () => {
      const callbacks: SubmitButtonCallbacks = {
        onDisable: options.submitButton?.onDisable,
        onLoading: options.submitButton?.onLoading,
        onVisible: options.submitButton?.onVisible,
        onContentChange: options.submitButton?.onContentChange,
      };
      SubmitButtonCallbackHandler.create(store, context, callbacks);
    };

    // initialize all callback handlers relating to UI
    const createUiCallbackHandlers = () => {
      createSubmitButtonCallbackHandler();
    };

    return createUiCallbackHandlers();
  },
};
