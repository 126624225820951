import { getBrowserInfo } from '../../utils/browserInfo';
import { throwAsyncIfTeardown, throwIfTeardown } from '../../checkout/Teardown';
import { ClientContext } from '../../core/ClientContext';
import { PaymentMethodToken } from '../../types';
import { uuid } from '../../utils/uuid';
import {
  ThreeDSProvider,
  ThreeDSSetupOptions,
  ThreeDSAuthPayload,
  ThreeDSChallengeAuthentication,
  ThreeDSMethodAuthentication,
} from '../types';
import { ThreeDSChallenge } from './ThreeDSChallenge';
import { ThreeDSMethod } from './ThreeDSMethod';
import { AuthenticationInput } from './types';
import { formatAuthenticationData } from './utils';

/**
 * This will cause all 3DS requests to do an exponential backoff
 * - trying 15 times
 * - starting at ~1 sec interval
 * - ending at ~4.1 sec interval
 */
const threeDSRequestOptions = {
  maxAttempts: 15,
};

export default class ThreeDSecureIOProvider implements ThreeDSProvider {
  private context: ClientContext;

  private options: ThreeDSSetupOptions;

  private token: PaymentMethodToken | null;

  constructor(context: ClientContext) {
    this.context = context;
    this.token = null;
  }

  throwIfTeardown() {
    return throwIfTeardown(this.context);
  }

  async throwAsyncIfTeardown<T>(fn: () => Promise<T>): Promise<T> {
    return throwAsyncIfTeardown(fn, this.context);
  }

  setup(options: ThreeDSSetupOptions): void {
    this.options = options;
  }

  async verify(options: AuthenticationInput): Promise<ThreeDSAuthPayload> {
    this.throwIfTeardown();
    this.token = null;

    // for checkout components - the ThreeDSSetupOptions are merged into the AuthenticationInput
    if (!this.options) {
      // @ts-expect-error For checkout components - setup is not called
      this.options = options;
    }

    const auth = await this.throwAsyncIfTeardown(() => this.auth(options));

    const { authentication, token, resumeToken } = auth;

    switch (authentication.responseCode) {
      case 'METHOD':
        return this.handle3DSMethod(options.token, authentication);

      case 'CHALLENGE':
        return this.handle3DSChallenge(options.token, authentication);

      default:
        return { authentication, token, resumeToken };
    }
  }

  private async auth(
    options: AuthenticationInput,
  ): Promise<ThreeDSAuthPayload> {
    const fingerprint = getBrowserInfo();
    const request = formatAuthenticationData(options, fingerprint);

    const response = await this.throwAsyncIfTeardown(() =>
      this.context.api.post<
        unknown,
        ThreeDSAuthPayload<ThreeDSMethodAuthentication>
      >(`/3ds/${options.token}/auth`, request, threeDSRequestOptions),
    );

    if (response.error || !response.data) {
      return this.handleAPIError();
    }

    this.extractToken(response.data);

    return response.data;
  }

  private async handle3DSMethod(
    tokenId: string,
    auth: ThreeDSMethodAuthentication,
  ): Promise<ThreeDSAuthPayload> {
    let result = await this.throwAsyncIfTeardown(() =>
      ThreeDSMethod.exec(this.context.longPoll, {
        acsMethodUrl: auth.acsMethodUrl,
        statusUrl: auth.statusUrl,
        notificationUrl: auth.notificationUrl,
        transactionId: auth.transactionId,
      }),
    );

    if (!result) {
      /**
       * If the method times out, continue authentication
       */
      result = await this.throwAsyncIfTeardown(() =>
        this.continueAuth(tokenId),
      );
    }

    this.extractToken(result);

    const { authentication, token, resumeToken } = result;

    if (authentication.responseCode === 'CHALLENGE') {
      return this.handle3DSChallenge(tokenId, authentication);
    }

    return { authentication, token, resumeToken };
  }

  private async handle3DSChallenge(
    tokenId: string,
    auth: ThreeDSChallengeAuthentication,
  ): Promise<ThreeDSAuthPayload> {
    let result = await this.throwAsyncIfTeardown(() =>
      ThreeDSChallenge.exec(this.context.longPoll, {
        acsChallengeUrl: auth.acsChallengeUrl,
        acsChallengeData: auth.acsChallengeData,
        acsTransactionId: auth.acsTransactionId,
        container: this.options.container,
        onChallengeEnd: this.options.onChallengeEnd,
        onChallengeStart: this.options.onChallengeStart,
        protocolVersion: auth.protocolVersion,
        statusUrl: auth.statusUrl,
        notificationUrl: auth.notificationUrl,
        transactionId: auth.transactionId,
        windowSize: auth.challengeWindowSize,
      }),
    );

    if (!result) {
      /**
       * If the challenge timed out, fetch the result from the server using the continue endpoint
       */
      result = await this.continueAuth(tokenId);
    }

    this.extractToken(result);

    return result;
  }

  private async continueAuth(tokenId: string): Promise<ThreeDSAuthPayload> {
    const continued = await this.throwAsyncIfTeardown(() =>
      this.context.api.post<unknown, ThreeDSAuthPayload>(
        `/3ds/${tokenId}/continue`,
        {},
        threeDSRequestOptions,
      ),
    );

    if (continued.error || !continued.data) {
      return this.handleAPIError();
    }

    this.extractToken(continued.data);

    return continued.data;
  }

  private handleAPIError(): ThreeDSAuthPayload {
    return {
      token: this.token ?? undefined,
      resumeToken: uuid(),
      authentication: {
        responseCode: 'SKIPPED',
        skippedReasonCode: 'NEGOTIATION_ERROR',
        skippedReasonText: 'Failed to negotiate with 3DS server',
      },
    };
  }

  private extractToken(auth: ThreeDSAuthPayload): void {
    if (auth.token) {
      this.token = auth.token;
    }
  }
}
