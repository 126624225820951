import { ClientConfiguration } from '../models/ClientConfiguration';
import CheckoutStore from '../store/CheckoutStore';
import ClientConfigurationHandler from './ClientConfigurationHandler';
import { IClientTokenHandler } from './ClientTokenHandler';

export default class ClientSessionHandler {
  private clientTokenHandler: IClientTokenHandler;

  private clientConfigurationHandler: ClientConfigurationHandler;

  private store: CheckoutStore;

  private onClientConfigurationUpdated?: (
    clientConfiguration: ClientConfiguration,
  ) => void;

  constructor(
    clientTokenHandler: IClientTokenHandler,
    clientConfigurationHandler: ClientConfigurationHandler,
    store: CheckoutStore,
  ) {
    this.clientTokenHandler = clientTokenHandler;
    this.clientConfigurationHandler = clientConfigurationHandler;
    this.store = store;
  }

  setOnClientConfigurationUpdated(
    onClientConfigurationUpdated: (
      clientConfiguration: ClientConfiguration,
    ) => void,
  ) {
    this.onClientConfigurationUpdated = onClientConfigurationUpdated;
  }

  public async updateClientConfigurationWithClientToken(
    clientToken: string,
  ): Promise<boolean> {
    try {
      const decodedClientToken = await this.clientTokenHandler.processClientToken(
        clientToken,
      );

      const clientConfiguration = await this.clientConfigurationHandler.fetchClientConfiguration(
        decodedClientToken,
      );

      if (!clientConfiguration.clientSession) {
        console.warn(
          'The provided client-token contains no information relating to the order. Please ensure that the correct information is provided when creating a client session. For more information regarding the creation of a client session, take a look at our API reference: https://primer.io/docs/api/#tag/Client-Session',
        );
        return false;
      }

      // Update client session
      this.setClientConfiguration(clientConfiguration);

      return true;
    } catch (e) {
      return false;
    }
  }

  public setClientConfiguration(clientConfiguration: ClientConfiguration) {
    this.store.setClientSession(clientConfiguration.clientSession);
    this.onClientConfigurationUpdated?.(clientConfiguration);
  }
}
