import { Currencies } from './currency/currencies';

const DEFAULT_DECIMAL_DIGITS = 2;

export function getDecimalDigitsByCurrencyCode(currencyCode?: string): number {
  const currencyInfo = Currencies.getCurrencyInfoByCurrencyCode(currencyCode);
  if (!currencyInfo) {
    console.warn(`Can't find currency by currency code '${currencyCode}'`);
  }

  return 10 ** (currencyInfo?.decimalDigits ?? DEFAULT_DECIMAL_DIGITS);
}
