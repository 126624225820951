import { Analytics } from '../Analytics';
import { AnalyticEventProperties, AnalyticsEventTypes } from '../models';

export abstract class BaseAnalyticsModule {
  analytics: Analytics;

  abstract type: AnalyticsEventTypes;

  constructor({ analytics }: { analytics: Analytics }) {
    this.analytics = analytics;
    this.init();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init(): void {}

  trackEvent(props: AnalyticEventProperties): Promise<boolean | void> {
    return this.analytics.trackEvent(this.type, props);
  }
}
