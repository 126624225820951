// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h, render } from 'preact';
import bem from 'easy-bem';
import {
  CheckoutProvider,
  StoreProvider,
  StyleProvider,
} from '@primer-io/shared-library/contexts';

import InternalError from '../../core/InternalError';
import { internalClientSessionToClientSession } from '../../models/ClientSession';
import VaultPaymentTokenizationService from '../../checkout-modules/vault/VaultPaymentTokenizationService';
import { createPaymentMethodContextFactory } from '../../core/PaymentMethodContextFactory';
import { locateElement } from '../../utils/dom';
import { PaymentMethods } from '../../payment-methods/PaymentMethods';
import { CheckoutPaymentMethodConfig } from '../CheckoutPaymentMethodConfig';
import { CheckoutTokenizationHandlers } from '../CheckoutTokenizationHandlers';
import { BasePaymentMethod } from '../../payment-methods/BasePaymentMethod';
import {
  CardDetails,
  UniversalCheckoutOptions,
  PrimerCheckout,
  SinglePaymentMethodCheckoutOptions,
  SupportedLocale,
  TokenizationHandlers,
  CheckoutUXFlow,
} from '../../types';
import { Vault } from '../../checkout-modules/vault/Vault';
import { setValue } from '../DomUtilities';
import { ElementID } from '../../enums/Checkout';
import { Translations } from '../../utils/i18n/TranslationFactory';
import { IFrameEventType } from '../../core/IFrameEventType';
import { IFrameMessagePayload } from '../../core/IFrameMessage';
import { ClientContext } from '../../core/ClientContext';
import normalizeOptions from '../../payment-methods/normalizeOptions';

import { createCheckoutViewUtils } from '../ui/ViewUtils';

import CheckoutRoot from '../../scenes/CheckoutRoot';

import { SceneTransition } from '../SceneTransition';
import { getSystemLocale } from '../../utils/getSystemLocale';
import { createSetTokenizationEnabled } from '../OptionUpdater';
import { CheckoutModules } from '../../checkout-modules/CheckoutModules';
import { teardownUI } from '../Teardown';
import { CallbackHandlers } from '../CallbackHandlers';
import ClientSessionHandler from '../../core/ClientSessionHandler';
import { ClientSessionActionHandler } from '../client-session/ClientSessionActionHandler';
import { logWarning, WarningType } from '../../utils/consoleWarn';
import { ClientSessionActionService } from '../client-session/ClientSessionActionService';

import {
  ErrorCode,
  PrimerClientError,
  throwPrimerClientError,
} from '../../errors';
import GlobalStyle from '../../components/GlobalStyle';
import { startCheckoutFlow } from '../CheckoutFlowStarter';
import { CheckoutPaymentHandlers } from '../CheckoutPaymentHandlers';
import initializeClientContext from '../ClientContextInitializer';

const onConfigError = (message: string): PrimerCheckout => {
  console.error(message);
  throw PrimerClientError.fromErrorCode(ErrorCode.INITIALIZATION_ERROR, {
    message,
  });
};

const renderRoot = ({
  options,
  viewUtils,
  context,
  transitions,
  clientSessionActionService,
  vaultPaymentTokenizationService,
  store,
  root,
}: {
  options;
  viewUtils;
  context?;
  transitions;
  clientSessionActionService?;
  vaultPaymentTokenizationService?;
  onDataChangeHandler?;
  store;
  root;
}) =>
  render(
    <CheckoutProvider
      value={{
        options,
        viewUtils,
        context,
        transitions,
        clientSessionActionService,
        vaultPaymentTokenizationService,
        className: bem('PrimerCheckout'),
      }}
    >
      <StoreProvider value={store}>
        <StyleProvider value={{ style: viewUtils.styleManager.getStyle() }}>
          <GlobalStyle />
          <CheckoutRoot />
        </StyleProvider>
      </StoreProvider>
    </CheckoutProvider>,
    root,
  );

export class UniversalCheckout {
  static async create(
    context: ClientContext,
    options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
  ): Promise<PrimerCheckout> {
    ///////////////////////////////////////////
    // Prepare root
    ///////////////////////////////////////////
    const parent = locateElement(options.container);

    if (parent == null) {
      return onConfigError(
        `Attempted to mount checkout UI at "${options.container}" but the element could not be found`,
      );
    }

    const root = document.createElement('div');
    parent.insertBefore(root, null);

    ///////////////////////////////////////////
    // Render
    ///////////////////////////////////////////
    // Store
    const { store } = context;

    // Client Session
    const clientSessionHandler = new ClientSessionHandler(
      context.clientTokenHandler,
      context.clientConfigurationHandler,
      store,
    );

    // Client Session Actions
    const clientSessionActionHandler = ClientSessionActionHandler.create(
      context,
      clientSessionHandler,
      store,
      options,
    );

    const clientSessionActionService = new ClientSessionActionService(
      store,
      clientSessionActionHandler,
      {
        onPaymentMethodAction: options?.onPaymentMethodAction,
      },
    );

    // View Utils
    const viewUtils = createCheckoutViewUtils(store, options);
    const transitions = new SceneTransition();

    ///////////////////////////////////////////
    // Prepare store
    ///////////////////////////////////////////
    store.setLocale(options.locale ?? (getSystemLocale() as SupportedLocale));
    store.setSceneTransition(options.scene?.transition);
    store.setInputLabelsVisible(options.form?.inputLabelsVisible ?? true);
    store.setShowSavedPaymentMethods(false);
    store.setCardFlow(options.card?.preferredFlow ?? 'EMBEDDED_IN_HOME');

    ///////////////////////////////////////////
    // Translation
    ///////////////////////////////////////////
    const translation = await Translations.get(options.locale);

    // Localisation
    store.setTranslation(translation);

    ///////////////////////////////////////////
    // First Render
    ///////////////////////////////////////////

    renderRoot({ options, viewUtils, transitions, store, root });

    ///////////////////////////////////////////
    // Fetch Client Configuration
    ///////////////////////////////////////////
    await initializeClientContext(context);

    if (
      !context.session?.clientSession.order.totalOrderAmount &&
      !context.session?.clientSession.order.merchantAmount
    ) {
      logWarning(WarningType.MISSING_AMOUNT_IN_CLIENT_SESSION);
    }
    if (!context.session?.clientSession.order.currencyCode) {
      logWarning(WarningType.MISSING_CURRENCY_IN_CLIENT_SESSION);
    }

    store.setClientSession(context.session.clientSession);

    options?.onClientSessionUpdate?.(
      internalClientSessionToClientSession(context.session.clientSession),
    );

    ///////////////////////////////////////////
    // Callback Handlers
    ///////////////////////////////////////////

    CallbackHandlers.create(store, context, options);

    ///////////////////////////////////////////
    // Vault & Payment Methods
    ///////////////////////////////////////////

    const processCheckoutModules = async () => {
      const checkoutModules = context.session.checkoutModules
        .map((checkoutModule) =>
          CheckoutModules.create(checkoutModule, {
            context,
            store,
            options,
            clientSessionActionService,
          }),
        )
        .filter(Boolean);

      store.setCheckoutModules(checkoutModules);
    };

    const processVault = async () => {
      // No vault in SINGLE_PAYMENT_METHOD_CHECKOUTs
      if (options.uxFlow === CheckoutUXFlow.SINGLE_PAYMENT_METHOD_CHECKOUT) {
        return;
      }

      const vault = new Vault(context);
      store.setVault(vault);

      // Vault is visible by default
      const vaultVisible = options?.vault?.visible ?? true;

      // Hiding the vault = not fetching the vault data
      if (vaultVisible === undefined || vaultVisible === true) {
        const vaulted = await vault.fetch();

        vaulted.forEach((pm) => {
          store.addVault(pm);
        });
        store.selectFirstVault();

        const selectedVaultItem = store.getSelectedVaultItem();

        if (selectedVaultItem) {
          if (selectedVaultItem.type === 'PAYMENT_CARD') {
            clientSessionActionService.selectVaultedCardNetwork(
              (selectedVaultItem.details as CardDetails).network,
            );
          } else {
            clientSessionActionService.selectPaymentMethod(
              selectedVaultItem.type,
            );
          }
        }
      }
    };

    ///////////////////////////////////////////
    // Prepare Payment Handlers
    ///////////////////////////////////////////

    // Payment Handlers to Tokenization Handlers
    let checkoutPaymentHandlers: TokenizationHandlers;

    if (!options.paymentHandling || options.paymentHandling === 'AUTO') {
      checkoutPaymentHandlers = CheckoutPaymentHandlers.create(
        context,
        options,
      );
    } else if (options.paymentHandling === 'MANUAL') {
      checkoutPaymentHandlers = {
        onTokenizeSuccess: options.onTokenizeSuccess,
        onTokenizeDidNotStart: options.onTokenizeDidNotStart,
        onTokenizeError: options.onTokenizeError,
        onResumeSuccess: options.onResumeSuccess,
        onResumeError: options.onResumeError,
        onTokenizeShouldStart: options.onTokenizeShouldStart,
        onTokenizeStart: options.onTokenizeStart,
        onResumePending: options.onResumePending,
      };
    } else {
      throw PrimerClientError.fromErrorCode(ErrorCode.INITIALIZATION_ERROR, {
        message: `Universal Checkout does not support "${options.paymentHandling}". Defaulting to the default value of "AUTO".`,
      });
    }

    // Augment tokenization handlers for Universal Checkout
    const checkoutTokenizationHandlers = CheckoutTokenizationHandlers.create(
      context,
      viewUtils,
      options,
      clientSessionActionService,
      checkoutPaymentHandlers,
    );

    // Payment method config
    const checkoutPaymentMethodConfig = CheckoutPaymentMethodConfig.create(
      store,
      viewUtils,
      options,
      clientSessionActionService,
    );

    const paymentMethodContextConfig = normalizeOptions({
      ...checkoutPaymentMethodConfig,
      ...checkoutTokenizationHandlers,
    });

    const paymentMethodContextFactory = createPaymentMethodContextFactory(
      context,
      paymentMethodContextConfig,
      store,
      store,
    );

    const vaultPaymentTokenizationService = new VaultPaymentTokenizationService(
      paymentMethodContextFactory,
    );

    let paymentMethods: Record<string, BasePaymentMethod> = {};
    const processPaymentMethods = async () => {
      paymentMethods = await PaymentMethods.create(
        context,
        paymentMethodContextFactory,
        paymentMethodContextConfig,
        viewUtils.styleManager,
      );

      store.setPaymentMethods(paymentMethods);
    };

    await Promise.all([
      processVault(),
      processPaymentMethods(),
      processCheckoutModules(),
    ]);

    if (Object.keys(store.getPaymentMethods()).length === 0) {
      throwPrimerClientError(
        PrimerClientError.fromErrorCode(ErrorCode.NO_PAYMENT_METHODS, {
          message: `No payment methods loaded. Cannot initialize Universal Checkout. \nMake sure that all payment methods are properly configured.`,
        }),
      );
    }

    context.messageBus.on(
      IFrameEventType.CARDHOLDER_NAME,
      (e: IFrameMessagePayload<string>) => {
        setValue(ElementID.CARDHOLDER_NAME_INPUT, e.payload);
      },
    );

    clientSessionHandler.setOnClientConfigurationUpdated(
      (clientConfiguration) => {
        // Forward new client session
        options?.onClientSessionUpdate?.(
          internalClientSessionToClientSession(store.getClientSession()),
        );

        // Remove Checkout Modules
        const initialCheckoutModules = store.getCheckoutModules();
        const filteredCheckoutModules = initialCheckoutModules.filter(
          (checkoutModule) =>
            clientConfiguration.checkoutModules.find(
              (checkoutModuleConfig) =>
                checkoutModuleConfig.type === checkoutModule.type,
            ),
        );
        store.setCheckoutModules(filteredCheckoutModules);

        // Add Checkout Modules
        const newCheckoutModules = clientConfiguration.checkoutModules
          .map((checkoutModuleConfig) => {
            const hasCheckoutModule = !!store.getCheckoutModuleWithType(
              checkoutModuleConfig.type,
            );
            if (hasCheckoutModule) {
              return undefined;
            }

            return CheckoutModules.create(checkoutModuleConfig, {
              context,
              store,
              options,
              clientSessionActionService,
            });
          })
          .filter(Boolean);

        store.setCheckoutModules([
          ...store.getCheckoutModules(),
          ...newCheckoutModules,
        ]);

        // Update Checkout Modules
        clientConfiguration.checkoutModules.forEach((checkoutModuleConfig) => {
          const checkoutModule = store.getCheckoutModuleWithType(
            checkoutModuleConfig.type,
          );
          if (!checkoutModule) {
            return;
          }

          CheckoutModules.update(checkoutModule, checkoutModuleConfig);
        });
      },
    );

    ///////////////////////////////////////////
    // Start flow
    ///////////////////////////////////////////
    startCheckoutFlow({
      context,
      store,
      tokenizationHandlers: checkoutTokenizationHandlers,
      options,
    });

    ///////////////////////////////////////////
    // Rerender with all the data
    ///////////////////////////////////////////
    renderRoot({
      options,
      viewUtils,
      transitions,
      store,
      root,
      context,
      clientSessionActionService,
      vaultPaymentTokenizationService,
    });

    ///////////////////////////////////////////
    // Prepare things to return
    ///////////////////////////////////////////

    const setTokenizationEnabled = createSetTokenizationEnabled(store);

    async function setClientToken(clientToken?: string): Promise<boolean> {
      if (!clientToken) {
        logWarning(WarningType.SET_CLIENT_TOKEN_WITHOUT_TOKEN);
        return false;
      }

      let isClientSessionUpdated = false;
      try {
        isClientSessionUpdated = await clientSessionHandler.updateClientConfigurationWithClientToken(
          clientToken,
        );
      } catch (e) {
        if (e instanceof InternalError) {
          throw PrimerClientError.fromErrorCode(
            ErrorCode.INITIALIZATION_ERROR,
            {
              message:
                {
                  CLIENT_TOKEN_MALFORMED:
                    'The provided `clientToken` is malformed.',
                  CLIENT_TOKEN_EXPIRED:
                    'The provided `clientToken` has expired.',
                }[e.code] ?? `Unknown client token error: ${e.code}`,
            },
          );
        } else {
          throw PrimerClientError.fromErrorCode(
            ErrorCode.INITIALIZATION_ERROR,
            {
              message: `Unknown client token error: ${e.message}`,
            },
          );
        }
      }

      if (!isClientSessionUpdated) {
        logWarning(
          'The checkout was unable to update the client session with the provided client-token. Please ensure the token is not malformed and contains the necessary information.',
        );
        return false;
      }

      return true;
    }

    return {
      submit: () => store.triggerSubmitButtonClick(),
      refreshClientSession: async (): Promise<boolean> => {
        const clientToken = context.clientTokenHandler.getOriginalClientToken();

        return setClientToken(clientToken);
      },
      setPaymentCreationEnabled: setTokenizationEnabled,
      setTokenizationEnabled,
      /**
       * @deprecated The method should not be used
       */
      setClientToken,
      teardown: () => {
        if (context.store.getIsTeardown()) {
          throw new Error('Checkout has already been torn down');
        }

        context.store.setIsTeardown(true);

        teardownUI(root);
      },
    };
  }
}
