const PtIcon = () => (
  <svg
    width='28'
    height='20'
    viewBox='0 0 28 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='28' height='20' fill='#E5E5E5' />
    <rect width='28' height='20' rx='2' fill='white' />
    <mask
      id='mask0_34_4'
      style='mask-type:alpha'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='28'
      height='20'
    >
      <rect width='28' height='20' rx='2' fill='white' />
    </mask>
    <g mask='url(#mask0_34_4)'>
      <rect width='28' height='20' fill='#FF2936' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0 20H10.6667V0H0V20Z'
        fill='#128415'
      />
      <path
        d='M10.6667 13.3333C12.5076 13.3333 14 11.8409 14 9.99998C14 8.15903 12.5076 6.66665 10.6667 6.66665C8.82572 6.66665 7.33333 8.15903 7.33333 9.99998C7.33333 11.8409 8.82572 13.3333 10.6667 13.3333Z'
        stroke='#FAF94F'
        stroke-width='1.33333'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.33334 8.33333C9.33334 8.14924 9.48258 8 9.66668 8H11.6667C11.8508 8 12 8.14924 12 8.33333V10.6667C12 11.403 11.4031 12 10.6667 12C9.9303 12 9.33334 11.403 9.33334 10.6667V8.33333Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.6667 10.6667C11.0349 10.6667 11.3333 9.70154 11.3333 9.33335C11.3333 8.96516 11.0349 8.66669 10.6667 8.66669C10.2985 8.66669 10 8.96516 10 9.33335C10 9.70154 10.2985 10.6667 10.6667 10.6667Z'
        fill='#1D50B5'
      />
    </g>
  </svg>
);

export default PtIcon;
