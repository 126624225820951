import { ClientContext } from '../core/ClientContext';
import { CheckoutModuleConfig } from '../models/ClientConfiguration';
import CheckoutStore from '../store/CheckoutStore';
import {
  HeadlessUniversalCheckoutOptions,
  SinglePaymentMethodCheckoutOptions,
  UniversalCheckoutOptions,
} from '../types';
import { ICheckoutModuleServices } from './types';

import createBillingAddressStore, {
  BillingAddressStore,
  Fields as BillingAddressFields,
} from './billing-address';
import createCardInformationStore, {
  CardInformationStore,
} from './card-information';
import createSavePaymentMethodStore, {
  SavePaymentMethodStore,
} from './save-payment-method';
import createShippingStore, { ShippingStore } from './shipping';
import createShippingAddressStore, {
  Fields as ShippingAddressFields,
  ShippingAddressStore,
} from './shipping-address';

interface ICreateCheckoutModuleServices extends ICheckoutModuleServices {
  context: ClientContext;
  store: CheckoutStore;
  options:
    | UniversalCheckoutOptions
    | SinglePaymentMethodCheckoutOptions
    | HeadlessUniversalCheckoutOptions;
}

const CHECKOUT_MODULE_CREATORS = {
  [CardInformationStore.type]: () => createCardInformationStore(),
  [BillingAddressStore.type]: (_config, services) =>
    createBillingAddressStore(services),
  [SavePaymentMethodStore.type]: () => createSavePaymentMethodStore(),
  [ShippingAddressStore.type]: () => createShippingAddressStore(),
  [ShippingStore.type]: () => createShippingStore(),
};

const CHECKOUT_MODULE_UPDATERS = {
  [CardInformationStore.type]: (
    checkoutModule: CardInformationStore,
    config: CheckoutModuleConfig,
  ) => {
    checkoutModule.showCardholderName(config.options?.cardHolderName);
  },

  [BillingAddressStore.type]: (
    checkoutModule: BillingAddressStore,
    config: CheckoutModuleConfig,
  ) => {
    checkoutModule.setFields((config.options ?? {}) as BillingAddressFields);
  },

  [SavePaymentMethodStore.type]: (
    checkoutModule: SavePaymentMethodStore,
    config: CheckoutModuleConfig,
  ) => {
    checkoutModule.showUserDescription(config.options?.userDescription);
  },

  [ShippingAddressStore.type]: (
    checkoutModule: ShippingAddressStore,
    config: CheckoutModuleConfig,
  ) => {
    checkoutModule.setFields((config.options ?? {}) as ShippingAddressFields);
  },

  [ShippingStore.type]: (
    checkoutModule: ShippingStore,
    config: CheckoutModuleConfig,
  ) => {
    checkoutModule.setShippingMethods(config.options?.shippingMethods ?? []);
    checkoutModule.setSelectedShippingMethod(
      config.options?.selectedShippingMethod,
    );
  },
};

const updateCheckoutModule = (
  checkoutModule: any,
  config: CheckoutModuleConfig,
) => CHECKOUT_MODULE_UPDATERS[config?.type]?.(checkoutModule, config);

const createCheckoutModule = (
  config: CheckoutModuleConfig,
  services: ICreateCheckoutModuleServices,
) => {
  const checkoutModule = CHECKOUT_MODULE_CREATORS[config?.type]?.(
    config,
    services,
  );
  checkoutModule && updateCheckoutModule(checkoutModule, config);

  return checkoutModule;
};

export const CheckoutModules = {
  create: createCheckoutModule,
  update: updateCheckoutModule,
};
