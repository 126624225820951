import window from '../utils/window';
import { IFrameMessageBus } from './IFrameMessageBus';
import { IFrameFactory } from './IFrameFactory';
import { IFrameEventType } from './IFrameEventType';
import { Nullable } from '../utilities';
import { Analytics } from '../analytics/Analytics';

enum HttpMethod {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
}

interface APIServices {
  messageBus: IFrameMessageBus;
  iframes: IFrameFactory;
  analytics: Analytics;
  accessToken: string;
}

interface RequestOptions {
  maxAttempts?: number;
  apiVersion?: string;
  isAnalytics?: boolean;
}

export interface APIRequestOptions {
  method: HttpMethod;
  url: string;
  body?: unknown;
  options: RequestOptions;
}

interface ValidationError {
  description: string;
  path: string;
}

interface ValidationErrorDetail {
  model: string;
  errors: ValidationError[];
}

export interface APIErrorShape {
  message: string;
  validationErrors: ValidationErrorDetail[];
  diagnosticsId?: string;
  errorId?: string;
}

export interface APIResponse<T = unknown> {
  error: Nullable<APIErrorShape>;
  data: Nullable<T>;
  status: Nullable<number>;
}

export interface APIResponsePayload<T> {
  payload: APIResponse<T>;
}

export class Api {
  private messageBus: IFrameMessageBus;

  private isReady: boolean;

  private onReadyCallback: Nullable<() => void>;

  constructor(services: APIServices) {
    this.messageBus = services.messageBus;
    this.isReady = false;
    this.onReadyCallback = null;

    services.iframes.create({
      filename: 'api-controller.html',
      container: window.document.body,
      meta: {
        name: 'api-controller',
      },
      payload: {
        accessToken: services.accessToken,
      },
      style: {
        position: 'absolute',
        width: '0px',
        height: '0px',
        display: 'none',
      },
      onReady: () => {
        this.isReady = true;
        if (this.onReadyCallback) {
          this.onReadyCallback();
          this.onReadyCallback = null;
        }
      },
    });
  }

  setAccessToken(accessToken: string) {
    this.messageBus.rpc('api-controller', {
      type: IFrameEventType.SET_ACCESS_TOKEN,
      payload: { accessToken },
    });
  }

  ready(): Promise<void> {
    if (this.isReady) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      this.onReadyCallback = resolve;
    });
  }

  async request<T>(payload: APIRequestOptions): Promise<APIResponse<T>> {
    await this.ready();

    const response = (await this.messageBus.rpc('api-controller', {
      type: IFrameEventType.REQUEST,
      payload,
    })) as APIResponsePayload<T>;

    return response.payload;
  }

  post<T = unknown, U = unknown>(
    url: string,
    body: T,
    options: RequestOptions = {},
  ): Promise<APIResponse<U>> {
    return this.request({
      method: HttpMethod.POST,
      url,
      body,
      options,
    });
  }

  get<T>(url: string, options: RequestOptions = {}): Promise<APIResponse<T>> {
    return this.request({
      method: HttpMethod.GET,
      url,
      options,
    });
  }

  delete<T>(
    url: string,
    options: RequestOptions = {},
  ): Promise<APIResponse<T>> {
    return this.request({
      method: HttpMethod.DELETE,
      url,
      options,
    });
  }
}
