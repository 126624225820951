import CheckoutStore, { getCurrentScene } from '../store/CheckoutStore';
import { ClientContext } from '../core/ClientContext';

export interface SubmitButtonCallbacks {
  onVisible?: (
    isVisible: boolean,
    context: { currentSceneId: string; previousSceneId?: string },
  ) => void;
  onContentChange?: (
    content: string,
    context: { currentSceneId: string },
  ) => void;
  onDisable?: (
    isDisabled: boolean,
    context: { currentSceneId: string },
  ) => void;
  onLoading?: (isLoading: boolean, context: { currentSceneId: string }) => void;
}

export const SubmitButtonCallbackHandler = {
  create(
    store: CheckoutStore,
    context: ClientContext,
    callbacks: SubmitButtonCallbacks,
  ) {
    //TODO: put that in a navigation manager or sth
    const sceneHistory: string[] = [];
    const getPreviousSceneId = () =>
      sceneHistory.length < 2
        ? undefined
        : sceneHistory[sceneHistory.length - 2];

    store.on('currentSceneId', () => {
      sceneHistory.push(getCurrentScene(store));
    });

    //

    store.on('submitButton.isDisabled', () => {
      if (callbacks.onDisable) {
        const isDisabled = store.getState().submitButton.isDisabled
          ? store.getState().submitButton.isDisabled
          : store.getState().isLoading;
        callbacks.onDisable(isDisabled, {
          currentSceneId: getCurrentScene(store),
        });
      }
    });

    store.on('isLoading', () => {
      if (callbacks.onLoading) {
        callbacks.onLoading(store.getState().isLoading, {
          currentSceneId: getCurrentScene(store),
        });
      }
    });

    store.on('submitButton.isVisible', () => {
      if (callbacks.onVisible) {
        callbacks.onVisible(store.getState().submitButton.isVisible, {
          currentSceneId: getCurrentScene(store),
          previousSceneId: getPreviousSceneId(),
        });
      }
    });

    store.on('submitButton.message', () => {
      if (callbacks.onContentChange) {
        const { message, canDisplayAmount } = store.getState().submitButton;
        const { UIOrderAmount } = store;
        const shouldDisplayAmount =
          context.clientOptions.submitButton?.amountVisible;
        let content = message;
        if (shouldDisplayAmount && canDisplayAmount && UIOrderAmount) {
          content = `${content} ${UIOrderAmount}`;
        }

        callbacks.onContentChange(content, {
          currentSceneId: getCurrentScene(store),
        });
      }
    });
  },
};
