import { useStore, useStoreSelector } from '@primer-io/shared-library/contexts';
import { Overlay } from '../../components/Overlay';
import { IFrameOverlayStore } from './IFrameOverlayStore';
import { IFrame } from './IFrame';

const IFrameOverlay = () => {
  const store = useStore<IFrameOverlayStore>('iFrameOverlay');

  const { isVisible, onCloseClick, src } = useStoreSelector(
    store,
    (state) => state,
  );

  return (
    <Overlay isVisible={isVisible} onCloseClick={onCloseClick}>
      <IFrame src={src} onCloseClick={onCloseClick} />
    </Overlay>
  );
};

export default IFrameOverlay;
