import { ClientContext } from '../../core/ClientContext';
import { CheckoutUXFlow } from '../../enums/Checkout';
import { InternalFlowOptions } from '../../internalTypes';
import {
  PrimerCheckout,
  PrimerHeadlessCheckout,
  PrimerVaultManager,
  UniversalCheckoutOptions,
  VaultManagerOptions,
} from '../../types';
import { HeadlessUniversalCheckout } from './HeadlessUniversalCheckout';
import { UniversalCheckout } from './UniversalCheckout';
import { VaultManager } from './VaultManager';

const FlowFactory = {
  create: async (
    context: ClientContext,
    options: InternalFlowOptions,
  ): Promise<PrimerVaultManager | PrimerCheckout | PrimerHeadlessCheckout> => {
    if (options.uxFlow === CheckoutUXFlow.HEADLESS_CHECKOUT) {
      return HeadlessUniversalCheckout.create(context);
    }

    if (options.uxFlow === CheckoutUXFlow.MANAGE_PAYMENT_METHODS) {
      return VaultManager.create(context, options as VaultManagerOptions);
    }

    return UniversalCheckout.create(
      context,
      options as UniversalCheckoutOptions,
    );
  },
};

export default FlowFactory;
