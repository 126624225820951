import { BasePaymentMethodManager } from './BasePaymentMethodManager';
import {
  EventTypes,
  IRedirectPaymentMethodManager,
  PaymentMethodType,
} from '../../types';
import { BasePaymentMethod } from '../../payment-methods/BasePaymentMethod';

export class RedirectPaymentMethodManager
  extends BasePaymentMethodManager
  implements IRedirectPaymentMethodManager {
  private eventListeners: Map<string, EventListener> = new Map();

  constructor(
    private readonly type: PaymentMethodType,
    private readonly paymentMethod: BasePaymentMethod,
  ) {
    super();
    this.subscribeToDefaultEvents();
  }

  static async create(
    type: PaymentMethodType,
    paymentMethod: BasePaymentMethod,
  ): Promise<RedirectPaymentMethodManager | null> {
    return new RedirectPaymentMethodManager(type, paymentMethod);
  }

  start(): Promise<void> {
    return this.paymentMethod.tokenize();
  }

  addEventListener(event: EventTypes, handler: (...args: any[]) => void) {
    this.eventListeners[event] = handler;
  }

  private subscribeToDefaultEvents() {
    this.paymentMethod.setOption(
      'onPopupClose',
      this.handleEvent(EventTypes.CLOSE).bind(this),
    );
  }

  private handleEvent(eventType: EventTypes) {
    return () => {
      this.eventListeners[eventType]?.();
    };
  }
}
