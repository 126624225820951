import deepmerge from 'deepmerge';
import { removeUndefinedFromObject } from '../../../utils/removeUndefined';
import { camelToKebab } from '../../../utils/camelToKebab';
import { isObject } from '../../../utils/isObject';

import {
  CheckoutStyle,
  InternalStyle,
  TextStyle,
  PaymentMethodButtonStyle,
  FontFace,
} from '../../../styles';
import { IStyleManager, IStyleManagerOptions } from '../StyleManager';

export const valueToPx = (value: string | number | undefined) => {
  if (value === undefined) {
    return undefined;
  }

  if (typeof value === 'number') {
    return `${value}px`;
  }

  if (typeof value === 'string') {
    return value;
  }

  return undefined;
};

export const getCss = (obj) =>
  Object.entries(removeUndefinedFromObject(obj) ?? {})
    .map(([key, value]) => `${key}: ${value};`)
    .join('\n');

const getCssFromStylesObject = (styles: any) => {
  return getCss(
    Object.fromEntries(
      Object.entries(styles ?? {}).map(([key, value]) => {
        if (isObject(value)) {
          return [];
        }

        return [camelToKebab(key), value];
      }),
    ),
  );
};

export const getTextStyle = (style?: TextStyle) =>
  getCss({
    'color': style?.color,
    'font-family': style?.fontFamily,
    'font-weight': style?.fontWeight,
    'font-size': valueToPx(style?.fontSize),
    'font-smoothing': style?.fontSmoothing,
    'line-height': valueToPx(style?.lineHeight),
    'text-transform': style?.textTransform,
    'letter-spacing': style?.letterSpacing,
  });

export const getFontFaceCss = (fontFace: FontFace) => `
  @font-face {
    ${getCss({
      'font-family': fontFace.fontFamily,
      'src': fontFace.src,
      'unicode-range': fontFace.unicodeRange,
      'font-variant': fontFace.fontVariant,
      'font-feature-settings': fontFace.fontFeatureSettings,
      'font-variation-settings': fontFace.fontVariationSettings,
      'font-stretch': fontFace.fontStretch,
      'font-weight': fontFace.fontWeight,
      'font-style': fontFace.fontStyle,
    })}
  }
`;

export const getRtlLanguageCss = (isRtlLocale) =>
  isRtlLocale
    ? `${getCss({
        'text-align': 'right',
      })}`
    : '';

export const getHeadlessHostedFieldStyle = (style?: CheckoutStyle) => {
  return `
    ${style?.fontFaces?.map(getFontFaceCss).join('\n\n') ?? ''}

    input {
      ${getCssFromStylesObject(style?.input?.base)}
    }

    input:hover {
      ${getCssFromStylesObject(style?.input?.base?.hover)}
    }

    input:focus {
      ${getCssFromStylesObject(style?.input?.base?.focus)}
    }

    input::placeholder {
      ${getCssFromStylesObject(style?.input?.base?.placeholder)}
    }

    input::selection {
      ${getCssFromStylesObject(style?.input?.base?.selection)}
    }

    input:-webkit-autofill, input:-webkit-autofill:input:focus, input:-webkit-autofill:hover {
      ${getCssFromStylesObject(style?.input?.base?.webkitAutofill)}
    }

    #primer-hosted-input {
      ${getCss({
        'padding-left': valueToPx(style?.input?.base?.paddingHorizontal),
        'padding-right': valueToPx(style?.input?.base?.paddingHorizontal),
      })}
    }

    #primer-hosted-form.submitted input.error {
      ${getCssFromStylesObject(style?.input?.error)}
    }

    #primer-hosted-form.submitted input.error:hover {
      ${getCssFromStylesObject(style?.input?.error?.hover)}
    }

    #primer-hosted-form.submitted input.error:focus {
      ${getCssFromStylesObject(style?.input?.error?.focus)}
    }

    #primer-hosted-form.submitted input.error::placeholder {
      ${getCssFromStylesObject(style?.input?.error?.placeholder)}
    }

    #primer-hosted-form.submitted input.error::selection {
      ${getCssFromStylesObject(style?.input?.error?.selection)}
    }
  `;
};

export const getHostedFieldStyle = (
  style?: CheckoutStyle,
  isRtlLocale?: boolean,
) => {
  return `
    ${style?.fontFaces?.map(getFontFaceCss).join('\n\n') ?? ''}

    input {
      ${getTextStyle(style?.input?.base)}
      ${getRtlLanguageCss(isRtlLocale)}
    }

    input:hover {
      ${getTextStyle(style?.input?.base?.hover)}
    }

    input:focus {
      ${getTextStyle(style?.input?.base?.focus)}
    }

    input::placeholder {
      ${getTextStyle(style?.input?.base?.placeholder)}
    }

    input::selection {
      ${getTextStyle(style?.input?.base?.selection)}
    }

    input:-webkit-autofill, input:-webkit-autofill:input:focus, input:-webkit-autofill:hover {
      ${getTextStyle(style?.input?.base?.webkitAutofill)}
    }

    #primer-hosted-input {
      ${getCss({
        'padding-left': valueToPx(style?.input?.base?.paddingHorizontal),
        'padding-right': valueToPx(style?.input?.base?.paddingHorizontal),
      })}
    }

    #primer-hosted-form.submitted input.error {
      ${getTextStyle(style?.input?.error)}
    }

    #primer-hosted-form.submitted input.error:hover {
      ${getTextStyle(style?.input?.error?.hover)}
    }

    #primer-hosted-form.submitted input.error:focus {
      ${getTextStyle(style?.input?.error?.focus)}
    }

    #primer-hosted-form.submitted input.error::placeholder {
      ${getTextStyle(style?.input?.error?.placeholder)}
    }

    #primer-hosted-form.submitted input.error::selection {
      ${getTextStyle(style?.input?.error?.selection)}
    }
  `;
};

///////////////////////////////////////////
// Manager
///////////////////////////////////////////

export class CssStyleManager implements IStyleManager {
  private hostedFieldStyle: string | null;

  private style: (CheckoutStyle & InternalStyle) | null;

  constructor() {
    this.hostedFieldStyle = null;
  }

  setStyle(
    style?: CheckoutStyle,
    internalStyle?: InternalStyle,
    options?: IStyleManagerOptions,
  ): void {
    this.style = deepmerge(style ?? {}, internalStyle ?? {});

    this.hostedFieldStyle = getHostedFieldStyle(style, options?.isRtlLocale);
  }

  getStyle() {
    return this.style || undefined;
  }

  getHostedFieldStyle(): string | null {
    return this.hostedFieldStyle;
  }

  getApmButtonStyle(): PaymentMethodButtonStyle | null {
    return this.style?.paymentMethodButton ?? null;
  }
}
