export enum ProductType {
  DIGITAL = 'DIGITAL',
  PHYSICAL = 'PHYSICAL',
}

export interface ClientSessionLineItem {
  itemId: string;
  description: string;
  amount: number;
  quantity: number;
  discountAmount?: number;
  taxCode?: string;
  taxAmount?: number;
  productType?: ProductType;
}

export interface ClientSessionOrder {
  orderId?: string;
  currencyCode?: string;
  countryCode?: string;
  totalOrderAmount?: number;
  merchantAmount?: number;
  lineItems?: ClientSessionLineItem[];
  shipping?: ClientSessionShipping;
  fees?: ClientSessionFeeItem[];
}

export interface ClientSessionShipping {
  amount: number;
  methodId?: string;
  methodName?: string;
  methodDescription?: string;
}

export interface ClientSessionShippingMethod {
  id: string;
  name: string;
  description: string;
  amount: number;
  type?: 'shipping' | 'pickup';
}

export interface ClientSessionFeeItem {
  type?: string;
  description?: string;
  amount: number;
}

export interface ClientSessionCustomer {
  customerId?: string;
  emailAddress?: string;
  mobileNumber?: string;
  firstName?: string;
  lastName?: string;
  billingAddress?: ClientSessionAddress;
  shippingAddress?: ClientSessionAddress;
  taxId?: string;
}

export interface ClientSessionAddress {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  postalCode?: string;
}

export interface ClientSessionPaymentMethodOptions {
  vaultOnSuccess: boolean;
  options: PaymentMethodOption[];
}

export interface PaymentMethodOption {
  type: string;
  surcharge?: number;
  networks?: CardNetworkOption[];
}

export interface CardNetworkOption {
  type: string;
  surcharge?: number;
}

export interface ClientSession {
  orderId?: string;
  currencyCode?: string;
  lineItems?: ClientSessionLineItem[];
  totalAmount?: number;
  customerId?: string;

  orderDetails?: {
    countryCode?: string;
    shipping?: ClientSessionShipping;
    fees?: ClientSessionFeeItem[];
  };

  customer?: {
    emailAddress?: string;
    mobileNumber?: string;
    firstName?: string;
    lastName?: string;
    billingAddress?: ClientSessionAddress;
    shippingAddress?: ClientSessionAddress;
    taxId?: string;
    nationalDocumentId?: string;
  };

  paymentMethod?: {
    vaultOnSuccess?: boolean;
    options?: Record<string, any>;
  };
}

export interface InternalClientSession {
  clientSessionId: string;
  order: ClientSessionOrder;
  customer: ClientSessionCustomer;
  paymentMethod: ClientSessionPaymentMethodOptions;
}

export const internalClientSessionToClientSession = (
  clientSession: InternalClientSession | null,
): ClientSession => ({
  orderId: clientSession?.order?.orderId,
  customerId: clientSession?.customer?.customerId,
  currencyCode: clientSession?.order?.currencyCode,
  lineItems: clientSession?.order?.lineItems?.map(
    ({
      amount,
      taxAmount,
      taxCode,
      quantity,
      description,
      itemId,
      discountAmount,
      productType,
    }) => ({
      amount,
      taxAmount,
      taxCode,
      quantity,
      description,
      itemId,
      discountAmount,
      productType,
    }),
  ),
  totalAmount: clientSession?.order?.merchantAmount,
  customer: {
    firstName: clientSession?.customer?.firstName,
    lastName: clientSession?.customer?.lastName,
    billingAddress: clientSession?.customer?.billingAddress,
    emailAddress: clientSession?.customer?.emailAddress,
    mobileNumber: clientSession?.customer?.mobileNumber,
    shippingAddress: clientSession?.customer?.shippingAddress,
    taxId: clientSession?.customer?.taxId,
  },

  orderDetails: {
    countryCode: clientSession?.order?.countryCode,
    fees: clientSession?.order?.fees,
    shipping: clientSession?.order?.shipping,
  },

  paymentMethod: {
    vaultOnSuccess: clientSession?.paymentMethod?.vaultOnSuccess,
    options: clientSession?.paymentMethod?.options?.reduce(
      (acc, { type, ...otherOptions }) => {
        acc[type] = {
          ...otherOptions,
        };
        return acc;
      },
      {},
    ),
  },
});
