import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { useCheckoutStore } from '@primer-io/shared-library/contexts';
import {
  Input,
  InputGroup,
  ErrorLabel,
  SelectManager,
} from '@primer-io/shared-library/components';
import { IInputGroup } from '../types';
import getErrorMessages from '../utils/errors/getErrorMessages';
import { useFormRendererContext } from '../contexts/FormRendererContext';
import { PhoneNumberInput } from '@primer-io/shared-library/components';
import { useCallback } from 'preact/hooks';

interface Props {
  inputGroups: IInputGroup[];
  onInputChange: (name: string, value: any) => void;
  onInputFocus: (name?: string) => void;
  onInputBlur: (name?: string) => void;
}

const Root = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }

  & > *:nth-last-child(2) {
    margin-bottom: 0px;
  }
`;

const FormInputGroups: FunctionalComponent<Props> = ({
  inputGroups,
  onInputChange,
  onInputFocus,
  onInputBlur,
}) => {
  const store = useCheckoutStore();
  const labels = store.getTranslations();

  const { disabled } = useFormRendererContext();

  const errorMessages = getErrorMessages(inputGroups);

  const error = errorMessages?.find(
    (message: { errorMessage: string; visible: boolean }) => message?.visible,
  );

  return (
    <Root data-testId='FormInputGroups'>
      {inputGroups?.map((inputGroup) => (
        <InputGroup
          key={inputGroup.id}
          data-testId={`FormInputGroups/InputGroup/${inputGroup.id}`}
          horizontal={inputGroup.orientation === 'horizontal'}
          hasHalfWidthInput={
            !!inputGroup.inputs.find((input) => input.halfWidth)
          }
        >
          {inputGroup.inputs?.map(
            ({
              placeholderKey,
              name,
              id,
              type,
              errorMessages: inputErrorMessages,
              errorVisible,
              maxLength,
              minLength,
              props,
              ...other
            }) => {

              const onChange = useCallback((inputValue) => onInputChange(name, inputValue), [name]);
              const onFocus = useCallback(() => onInputFocus(name), [name]);
              const onBlur = useCallback(() => onInputBlur(name), [name]);

              if (type.includes('Select')) {
                return (
                  <SelectManager
                    type={type}
                    onChange={onChange}
                    hasErrors={inputErrorMessages?.length > 0 && errorVisible}
                    inputId={id}
                    disabled={disabled}
                  />
                );
              }
              if (type.includes('PhoneNumberInput')) {
                return (
                  <PhoneNumberInput
                    prefix={props?.prefix}
                    key={id}
                    data-testId={`FormInputGroups/InputGroup/${
                      inputGroup.id ?? id
                    }/Input/${id}`}
                    inputId={id}
                    maxLength={maxLength}
                    minLength={minLength}
                    disabled={disabled}
                    hasErrors={inputErrorMessages?.length > 0 && errorVisible}
                    placeholder={labels?.[placeholderKey]}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                );
              }
              return (
                <Input
                  key={id}
                  data-testId={`FormInputGroups/InputGroup/${
                    inputGroup.id ?? id
                  }/Input/${id}`}
                  {...other}
                  inputId={id}
                  type={type}
                  maxLength={maxLength}
                  minLength={minLength}
                  disabled={disabled}
                  hasErrors={inputErrorMessages?.length > 0 && errorVisible}
                  placeholder={labels?.[placeholderKey]}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
              );
            },
          )}
        </InputGroup>
      ))}
      <ErrorLabel message={error?.errorMessage} />
    </Root>
  );
};

export default FormInputGroups;
