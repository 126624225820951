import { BaseAnalyticsProvider } from './BaseAnalyticsProvider';
import {
  AnalyticsEventTypes,
  AnalyticEventProperties,
  createBaseAnalyticsEvent,
} from '../models';

export class MockAnalyticsProvider extends BaseAnalyticsProvider {
  constructor() {
    super('');
  }

  async call(
    eventType: AnalyticsEventTypes,
    props: AnalyticEventProperties,
  ): Promise<boolean> {
    const event = await createBaseAnalyticsEvent<AnalyticEventProperties>(
      eventType,
      props,
      this.options,
    );

    console.debug(`🦄.call \`${eventType}\``, event, event.clientSessionId);

    return true;
  }
}
