import { BaseInputStyle } from '@primer-sdk-web/styles';
import styled, { css } from 'styled-components';
import { getBlockStyle } from '../utils';

const getInputStyle = (style?: BaseInputStyle) => ({
  height: style?.height,
  ...getBlockStyle(style),
});

const getInputCss = (selector) => css`
  ${(p) => getInputStyle(selector(p))}

  ${(p) =>
    p.focused &&
    `&:hover {
    ${getInputStyle(selector(p)?.hover)}
  }`}

  &:focus,
  &:focus-within {
    ${(p) => getInputStyle(selector(p)?.focus)}
  }

  ${(p) => p.focused && getInputStyle(selector(p)?.focus)}}
`;

const BaseInputCss = getInputCss((p) => p.theme.style?.input.base);
const ErrorInputCss = getInputCss((p) => p.theme.style?.input.error);

const InputContainer = styled.div`
  outline: none;
  display: block;
  position: relative;
  overflow: hidden;
  flex: ${(p) => p.flex ?? 1};

  ${BaseInputCss}
  ${(p) => p.hasErrors && ErrorInputCss}
`;

export default InputContainer;
