import { FunctionalComponent } from 'preact';
import { useLayoutEffect, useRef } from 'preact/hooks';
import {
  useCheckoutContext,
  useCheckoutStore,
  useSelector,
} from '@primer-io/shared-library/contexts';
import styled from 'styled-components';
import {
  createWithStyle,
  getBlockStyle,
  getTextStyle,
} from '@primer-io/shared-library/components';
import { ClassName } from '../../../../enums/Checkout';
import { PaymentInstrumentType } from '../../../../enums/Tokens';
import { VaultListItem } from '../../../../types';
import { useFirstRender, usePrevious } from '../../../../utils/hooks';

const withVaultMenuItemStyle = createWithStyle(
  (style) => style?.vaultMenu?.item,
);

const DeleteButton = styled.button`
  ${withVaultMenuItemStyle((style) => getTextStyle(style?.actionButton))}
`;

const ConfirmButton = styled.button`
  ${withVaultMenuItemStyle((style) => getTextStyle(style?.confirmButton))}
  ${withVaultMenuItemStyle((style) => getBlockStyle(style?.confirmButton))}
`;

const ItemLabel = styled.div`
  ${withVaultMenuItemStyle((style) => getTextStyle(style?.label))}
`;

const StyledIcon = styled.img<{ isRtlLocale: boolean }>`
  width: 30px;
  ${(p) => (p.isRtlLocale ? 'margin-left: 8px;' : 'margin-right: 8px;')}
`;

const StyledButtonsContainer = styled.div<{ isRtlLocale: boolean }>`
  ${(p) => (p.isRtlLocale ? 'margin-right: auto;' : 'margin-left: auto;')}
`;

const Card = {
  getIcon: ({ vaultedItem }) => ({
    src: vaultedItem.details.icon,
    alt: vaultedItem.details.network,
  }),

  getDeleteAriaLabel: ({ vaultedItem, labels }) =>
    `${labels?.delete} ${labels?.cardEndingWith} ${vaultedItem.details.last4Digits}`,

  getDescription: ({ vaultedItem, labels }) =>
    `${labels?.cardEndingWith} ${vaultedItem.details.last4Digits}`,

  getConfirmContent: ({ labels }) => labels?.confirmCardDeletion,
  getConfirmAriaLabel: ({ vaultedItem, labels }) =>
    `${labels.confirmCardDeletion}; ${labels.cardEndingWith} ${vaultedItem.details.last4Digits}`,
};

const PayPal = {
  getIcon: ({ vaultedItem }) => ({
    src: vaultedItem.details.icon,
    alt: 'PayPal',
  }),

  getDeleteAriaLabel: ({ vaultedItem, labels }) =>
    `${labels.delete} PayPal ${vaultedItem.details.email}`,

  getDescription: ({ vaultedItem }) => `${vaultedItem.details.email}`,

  getConfirmContent: ({ labels }) => labels?.confirmPayPalDeletion,
  getConfirmAriaLabel: ({ vaultedItem, labels }) =>
    `${labels?.confirmPayPalDeletion}; ${vaultedItem.details.email}`,
};

const Details = {
  [PaymentInstrumentType.CARD]: Card,
  [PaymentInstrumentType.PAYPAL_VAULTED]: PayPal,
};

type Props = {
  vaultedItem: VaultListItem;
  isConfirming: boolean;
  isDisabled: boolean;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  onConfirmClick: () => void;
};

const VaultMenuItem: FunctionalComponent<Props> = ({
  vaultedItem,
  isConfirming,
  isDisabled,
  onDeleteClick,
  onCancelClick,
  onConfirmClick,
}) => {
  const { id } = vaultedItem;
  const previousIsConfirming = usePrevious(isConfirming);

  const store = useCheckoutStore();
  const labels = useSelector((s) => s.translation);
  const { viewUtils, className } = useCheckoutContext();

  const isRtlLocale = store.getIsRtlLocale();

  const confirmButtonContainerRef = useRef<HTMLDivElement>();
  const confirmButtonRef = useRef<HTMLButtonElement>();
  const deleteButtonRef = useRef<HTMLButtonElement>();

  useFirstRender(() => {
    viewUtils.toggleVisibilityAnimated(
      confirmButtonContainerRef.current,
      false,
      {
        id: `confirmationContainer-${id}`,
        classPrefix: `${ClassName.PAYMENT_METHOD_MENU_ITEM}__confirmationContainer`,
        duration: 0,
        animateHeight: true,
      },
    );
  });

  useLayoutEffect(() => {
    if (isConfirming === previousIsConfirming) {
      return;
    }
    if (isConfirming) {
      viewUtils.toggleVisibilityAnimated(
        confirmButtonContainerRef.current,
        true,
        {
          id: `confirmationContainer-${id}`,
          classPrefix: `${ClassName.PAYMENT_METHOD_MENU_ITEM}__confirmationContainer`,
          duration: 300,
          animateHeight: true,
        },
      );

      // Accessibility
      confirmButtonRef.current.focus();
    } else {
      viewUtils.toggleVisibilityAnimated(
        confirmButtonContainerRef.current,
        false,
        {
          classPrefix: `${ClassName.PAYMENT_METHOD_MENU_ITEM}__confirmationContainer`,
          duration: 300,
          id: `confirmationContainer-${id}`,
          animateHeight: true,
        },
      );

      // Accessibility
      deleteButtonRef.current.focus();
    }
  }, [isConfirming]);

  ///////////////////////////////////////////
  // Cb
  ///////////////////////////////////////////
  const handleDeleteClick = () => {
    onDeleteClick();
  };

  const handleCancelClick = () => {
    onCancelClick();
  };
  const handleConfirmClick = async () => {
    await store.removeVault(id);
    onConfirmClick();
    if (store.hasCreditCardScene) {
      store.setSubmitButtonVisible(false);
    }
  };

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////
  const {
    getIcon,
    getDescription,
    getConfirmContent,
    getConfirmAriaLabel,
    getDeleteAriaLabel,
  } = Details[vaultedItem.type];

  const args = { vaultedItem, labels };

  return (
    <div
      data-token={id}
      className={className('paymentMethodDropDownMenuItem', {
        disabled: isDisabled,
      })}
      aria-disabled={isDisabled}
    >
      <div className='PrimerCheckout__paymentMethodDropDownMenuItem__content'>
        <StyledIcon isRtlLocale={isRtlLocale} {...getIcon?.(args)} />

        <ItemLabel className='PrimerCheckout__paymentMethodDropDownMenuItem__label PrimerCheckout__label'>
          {getDescription?.(args)}
        </ItemLabel>

        <StyledButtonsContainer isRtlLocale={isRtlLocale}>
          {!isConfirming && (
            <DeleteButton
              ref={deleteButtonRef}
              type='button'
              className='PrimerCheckout__deleteButton PrimerCheckout__paymentMethodDropDownMenuItem__deleteButton'
              aria-label={getDeleteAriaLabel?.(args)}
              onClick={handleDeleteClick}
              disabled={isDisabled}
            >
              {labels?.delete}
            </DeleteButton>
          )}
          {isConfirming && (
            <button
              type='button'
              className='PrimerCheckout__deleteButton PrimerCheckout__paymentMethodDropDownMenuItem__cancelButton'
              aria-label={labels?.cancel}
              onClick={handleCancelClick}
              disabled={isDisabled}
            >
              {labels?.cancel}
            </button>
          )}
        </StyledButtonsContainer>
      </div>

      <div
        ref={confirmButtonContainerRef}
        className='PrimerCheckout__paymentMethodDropDownMenuItem__confirmationContainer'
      >
        <ConfirmButton
          ref={confirmButtonRef}
          type='button'
          className='PrimerCheckout__confirmButton'
          aria-label={getConfirmAriaLabel?.(args)}
          onClick={handleConfirmClick}
          disabled={isDisabled}
        >
          {getConfirmContent?.(args)}
        </ConfirmButton>
      </div>
    </div>
  );
};

export default VaultMenuItem;
