import { parseAmountValue } from '../utils/parseAmountValue';
import {
  BusinessDetails,
  CustomerAddress,
  CustomerDetails,
  OrderDetails,
  PurchaseInformation,
  VaultInformation,
} from '../types';
import {
  ClientSessionCustomer,
  ClientSessionOrder,
  ClientSessionAddress,
} from '../models/ClientSession';
import { totalAmountFromItems } from '../utils/totalAmountCalculator';

type OptionStoreListenerEventName =
  | 'options.purchaseInfo'
  | 'options.customerDetails'
  | 'options.businessDetails'
  | 'options.orderDetails';

export type OrderDetailsWithInitiator = {
  orderDetails: ClientSessionOrder | null;
  orderDetailsInitiator: string | null;
};

export interface IOptionStoreSetter {
  setPurchaseInfo(purchaseInfo: PurchaseInformation | VaultInformation | null);
  setCustomerDetails(
    customerDetails: ClientSessionCustomer | null,
    initiator: string | null,
  );
  setBusinessDetails(
    businessDetails: BusinessDetails | null,
    initiator: string | null,
  );
  setOrderDetails(
    orderDetails: ClientSessionOrder | null,
    initiator: string | null,
  );
}

export interface IOptionStoreListener {
  getPurchaseInfo(): PurchaseInformation | VaultInformation | null;
  getCustomerDetails(): ClientSessionCustomer | null;
  getBusinessDetails(): BusinessDetails | null;
  getOrderDetails(): OrderDetailsWithInitiator;

  on(eventName: OptionStoreListenerEventName, listener: () => void);
  off(eventName: OptionStoreListenerEventName, listener: () => void);
}

export const orderDetailsToClientSessionOrder = (
  orderDetails?: OrderDetails | null,
): ClientSessionOrder | null => {
  if (!orderDetails) {
    return null;
  }

  return {
    orderId: undefined,
    currencyCode: orderDetails?.currencyCode,
    totalOrderAmount: getOrderDetailsAmount(orderDetails),
    merchantAmount: getOrderDetailsAmount(orderDetails),
    lineItems: orderDetails.items?.map((item) => ({
      itemId: item.reference ?? item.name,
      description: item.name,
      amount: parseAmountValue(
        item.unitAmount,
        orderDetails.currencyCode,
      ).asNumber(),
      quantity: item.quantity ?? 0,
      discountAmount: parseAmountValue(
        item.discountAmount,
        orderDetails.currencyCode,
      ).asNumber(),
      taxAmount: undefined,
      taxCode: item.taxCode,
      productType: item.productType,
    })),
    shipping: {
      amount: parseAmountValue(
        orderDetails.shippingAmount,
        orderDetails.currencyCode,
      ).asNumber(),
    },
    fees: undefined,
  };
};

export const clientSessionOrderToOrderDetails = (
  order?: ClientSessionOrder,
): OrderDetails | null => {
  if (!order) {
    return null;
  }

  return {
    totalAmount: order.merchantAmount ?? order.totalOrderAmount,
    totalTaxAmount: order.lineItems?.reduce(
      (value, item) => value + (item.taxAmount ?? 0),
      0,
    ),
    items: order.lineItems?.map((item) => ({
      name: item.description,
      unitAmount: item.amount,
      reference: item.itemId,
      quantity: item.quantity,
      discountAmount: item.discountAmount,
      taxCode: item.taxCode,
      productType: item.productType,
    })),

    currencyCode: order.currencyCode,
    shippingAmount: order.shipping?.amount,
  };
};

export const getClientSessionOrderAmount = (order: ClientSessionOrder) =>
  order.merchantAmount ?? order.totalOrderAmount;

const getOrderDetailsAmount = (orderDetails: OrderDetails) => {
  const parsedAmount = parseAmountValue(
    orderDetails?.totalAmount,
    orderDetails.currencyCode,
  ).asNumber();

  if (!parsedAmount) {
    return calculateAmountFromLineItems(orderDetails);
  }

  return parsedAmount;
};

const calculateAmountFromLineItems = (order: OrderDetails) => {
  if (order.items && order.items.length > 0) {
    return totalAmountFromItems({
      lineItems: order.items,
      shippingAmount: order.shippingAmount,
      totalTaxAmount: order.totalTaxAmount,
      currencyCode: order.currencyCode,
    });
  }
  return 0;
};

const addressToClientSessionAddress = (
  address?: CustomerAddress,
): ClientSessionAddress => ({
  firstName: address?.firstName,
  lastName: address?.lastName,
  addressLine1: address?.addressLine1,
  addressLine2: address?.addressLine2,
  city: address?.city,
  countryCode: address?.countryCode,
  postalCode: address?.postalCode,
  state: address?.state,
});

const clientSessionAddressToAddress = (
  address?: ClientSessionAddress,
): CustomerAddress => ({
  firstName: address?.firstName,
  lastName: address?.lastName,
  addressLine1: address?.addressLine1,
  addressLine2: address?.addressLine2,
  city: address?.city,
  countryCode: address?.countryCode,
  postalCode: address?.postalCode,
  state: address?.state,
});

export const customerDetailsToClientSessionCustomer = (
  customerDetails?: CustomerDetails | null,
): ClientSessionCustomer | null => {
  if (!customerDetails) {
    return null;
  }

  return {
    taxId: customerDetails?.customerTaxId,
    billingAddress: addressToClientSessionAddress(
      customerDetails?.billingAddress,
    ),
    shippingAddress: addressToClientSessionAddress(
      customerDetails?.shippingAddress,
    ),
  };
};

export const clientSessionCustomerToCustomerDetails = (
  customer?: ClientSessionCustomer,
): CustomerDetails => ({
  customerTaxId: customer?.taxId,
  billingAddress: clientSessionAddressToAddress(customer?.billingAddress),
  shippingAddress: clientSessionAddressToAddress(customer?.shippingAddress),
});
