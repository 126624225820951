export enum IFrameEventType {
  MOUNT = 'LIFECYCLE.MOUNT',
  REQUEST = 'ACTION.REQUEST',
  SET_ACCESS_TOKEN = 'ACTION.SET_ACCESS_TOKEN',
  RESET_FIELD = 'ACTION.RESET_FIELD',
  SET_VALUE = 'ACTION.SET_VALUE',
  SYNC_VALUE = 'ACTION.SYNC_VALUE',
  VALIDATE = 'ACTION.VALIDATE',
  SET_SUBMITTED = 'ACTION.SET_SUBMITTED',
  UPDATE_METADATA = 'ACTION.UPDATE_METADATA',
  SET_DISABLED = 'ACTION.SET_DISABLED',
  SET_FOCUSED = 'ACTION.SET_FOCUSED',
  SET_BLURRED = 'ACTION.SET_BLURRED',
  INPUT_BLUR = 'ACTION.INPUT_BLUR',
  INPUT_FOCUS = 'ACTION.INPUT_FOCUS',
  CARD_METADATA = 'INFO.CARD_METADATA',
  INPUT_METADATA = 'INFO.INPUT_METADATA',
  CARDHOLDER_NAME = 'INFO.CARDHOLDER_NAME',
  SESSION = 'INFO.SESSION',
  IOS_BLUR_FIX = 'COMPATIBILITY.IOS_BLUR_FIX',
}
