import { ResumeCallbackHandlerContext } from '@primer-io/shared-library/payment-method-utils/ResumeCallbackHandler';
import { ErrorCode, PrimerClientError } from '../../errors';
import CheckoutStore from '../../store/CheckoutStore';
import { PaymentMethodResumePollService } from '../PaymentMethodLongPollService';
import { IFrameOverlayStore } from './IFrameOverlayStore';

export class IFrameOverlayService {
  private store: CheckoutStore;

  private context: ResumeCallbackHandlerContext;

  private resumePollingService: PaymentMethodResumePollService | undefined;

  private iFrameOverlayStore: IFrameOverlayStore | undefined;

  constructor(store: CheckoutStore, context: ResumeCallbackHandlerContext) {
    this.store = store;

    this.context = context;

    this.resumePollingService = undefined;
  }

  async presentIFrameOverlay(statusUrl: string, iFrameSrc: string) {
    try {
      this.resumePollingService = new PaymentMethodResumePollService(
        this.context,
        statusUrl,
      );

      this.iFrameOverlayStore = this.store.getStore<IFrameOverlayStore>(
        'iFrameOverlay',
      );

      this.showIFrameOverlay(iFrameSrc);

      const resumeToken = await this.resumePollingService.startPolling(
        100, // poll interval of 100ms
        1000 * 60 * 60 * 2, // timeout of 2 hours
      );

      this.hideIFrameOverlay();

      if (!resumeToken) {
        throw PrimerClientError.fromErrorCode(ErrorCode.RESUME_ERROR, {
          message: "Can't fetch resume key",
        });
      }

      return resumeToken;
    } catch (e) {
      this.hideIFrameOverlay();
      throw PrimerClientError.fromErrorCode(ErrorCode.RESUME_ERROR, {
        message: "Can't fetch resume key",
      });
    }
  }

  showIFrameOverlay(iFrameSrc: string) {
    if (!this.iFrameOverlayStore) {
      return;
    }
    this.iFrameOverlayStore.showIFrameOverlay(iFrameSrc, () =>
      this.resumePollingService?.stopPolling(),
    );
  }

  hideIFrameOverlay() {
    if (!this.iFrameOverlayStore) {
      return;
    }
    this.iFrameOverlayStore?.hideIFrameOverlay();
  }
}
