import { Api } from '../../core/Api';
import CallQueue from '../../utils/CallQueue';
import {
  AnalyticEventProperties,
  AnalyticsEventTypes,
  IBaseAnalyticsEvent,
  IAnalyticsOptions,
  createBaseAnalyticsEvent,
} from '../models';

export interface IAnalyticsProvider {
  trackEvent(
    eventType: AnalyticsEventTypes,
    properties: AnalyticEventProperties,
  ): Promise<void>;
  initProvider(api: Api, options: IAnalyticsOptions): void;
}

export abstract class BaseAnalyticsProvider implements IAnalyticsProvider {
  private readonly url: string;

  private api: Nullable<Api>;

  protected options: IAnalyticsOptions;

  private callQueue: CallQueue<
    Array<IBaseAnalyticsEvent<AnalyticEventProperties>>
  >;

  public constructor(url: string) {
    this.url = url;

    this.initCallQueue();
  }

  public initProvider(api: Api, options: IAnalyticsOptions): void {
    this.options = options;
    this.api = api;
  }

  public async trackEvent(
    eventType: AnalyticsEventTypes,
    properties: AnalyticEventProperties,
  ): Promise<void> {
    const event = await createBaseAnalyticsEvent<AnalyticEventProperties>(
      eventType,
      properties,
      this.options,
    );

    return this.callQueue.call([event]);
  }

  private initCallQueue() {
    this.callQueue = new CallQueue();
    this.callQueue.handleCall = this.trackEvents.bind(this);
    this.callQueue.handleMerge = (previousData, data) => [
      ...previousData,
      ...data,
    ];
  }

  private async trackEvents(
    events: IBaseAnalyticsEvent<AnalyticEventProperties>[],
  ): Promise<any> {
    return this.api?.post(this.url, events, {
      // prevent sending network events to the server from analytics requests
      isAnalytics: true,
    });
  }
}
