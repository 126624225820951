import CheckoutStore from '../store/CheckoutStore';
import { IOptionStoreListener } from '../store/OptionStore';
import { PaymentMethodType } from '../types';
import { ClientContext } from './ClientContext';
import PaymentMethodContext, {
  IPaymentMethodContext,
  PaymentMethodContextOptions,
} from './PaymentMethodContext';

export interface IPaymentMethodContextFactory {
  createPaymentMethodContext(options: {
    paymentMethodType: PaymentMethodType;
  }): IPaymentMethodContext;
}

export const createPaymentMethodContextFactory = (
  context: ClientContext,
  options: PaymentMethodContextOptions,
  optionStore: IOptionStoreListener,
  store: CheckoutStore,
): IPaymentMethodContextFactory => {
  return {
    createPaymentMethodContext({ paymentMethodType }) {
      return PaymentMethodContext.create(
        context,
        options,
        optionStore,
        store,
        paymentMethodType,
      );
    },
  };
};
