import { IPaymentMethodContextFactory } from '../core/PaymentMethodContextFactory';
import normalizeOptions from './normalizeOptions';
import { createStyleManager, IStyleManager } from '../checkout/ui/StyleManager';
import { BasePaymentMethod } from './BasePaymentMethod';
import { ClientContext } from '../core/ClientContext';
import { PaymentMethodConfig } from '../models/ClientConfiguration';
import getPaymentMethod from './payment-method-implementations';

const preparePaymentMethod = async ({
  context,
  paymentMethodConfig,
  options,
  styleManager,
  paymentMethodContextFactory,
}: {
  context: ClientContext;
  paymentMethodConfig: PaymentMethodConfig;
  options;
  styleManager: IStyleManager;
  paymentMethodContextFactory: IPaymentMethodContextFactory;
}) => {
  const { type } = paymentMethodConfig;

  const PaymentMethod = await getPaymentMethod({
    context,
    paymentMethodConfig,
  });
  if (!PaymentMethod) {
    console.warn(`Can't init ${type}`);
    return null;
  }

  if (!PaymentMethod.specs) {
    console.warn(`Can't init ${type}`);
    return null;
  }

  const { key, canVault } = PaymentMethod.specs;
  if (!key) {
    console.warn(`Can't init ${type}`);
    return null;
  }

  const inputOptions = options[key] || {};

  if (options.vaultOnly && !canVault) {
    // TODO: return this info server-side. This will do for now
    return null;
  }

  const paymentMethodContext = paymentMethodContextFactory.createPaymentMethodContext(
    { paymentMethodType: type },
  );

  const paymentMethod = PaymentMethod.create(
    paymentMethodContext,
    inputOptions,
    paymentMethodConfig,
    styleManager,
  ) as BasePaymentMethod;

  let loaded: boolean;
  try {
    loaded = await paymentMethod.setupAndValidate();
  } catch (e) {
    console.warn(`Can't init ${type}`, e);
    loaded = false;
  }

  if (!loaded) {
    return null;
  }

  if (options.mountImmediately) {
    await paymentMethod.mount();
  }

  return {
    [type]: paymentMethod,
  };
};

export const PaymentMethods = {
  async create(
    context: ClientContext,
    paymentMethodContextFactory: IPaymentMethodContextFactory,
    opts: Record<string, unknown>,
    styleManager: IStyleManager = createStyleManager(),
  ): Promise<Record<string, BasePaymentMethod>> {
    const options = normalizeOptions(opts);

    const promises = context.session.paymentMethods.map(
      async (paymentMethodConfig) =>
        preparePaymentMethod({
          context,
          paymentMethodConfig,
          options,
          styleManager,
          paymentMethodContextFactory,
        }),
    );

    const results = await Promise.all(promises);

    return Object.assign({}, ...results);
  },
};
