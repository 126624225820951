import { FunctionalComponent } from 'preact';
import {
  useCheckoutContext,
  useCheckoutStore,
  useSelector,
} from '@primer-io/shared-library/contexts';
import { CardDetails, VaultListItem } from '../../../../types';

import PaymentMethodTokenItem from '../../../../components/PaymentMethodTokenItem';

type Props = {
  vaultedItem: VaultListItem;
};

const VaultedPaymentMethod: FunctionalComponent<Props> = ({ vaultedItem }) => {
  const store = useCheckoutStore();
  const { clientSessionActionService } = useCheckoutContext();
  const selectedVault = useSelector((s) => s.vault.selected);
  const selected = vaultedItem.id === selectedVault;

  const handleSelect = () => {
    if (vaultedItem.type === 'PAYMENT_CARD') {
      clientSessionActionService?.selectVaultedCardNetwork(
        (vaultedItem.details as CardDetails).network,
      );
    } else {
      clientSessionActionService?.selectPaymentMethod(vaultedItem.type);
    }
    store.selectVault(vaultedItem.id);
    store.setSubmitButtonVisible(true);
    store.setSubmitButtonDisabled(false);
  };

  return (
    <PaymentMethodTokenItem
      vaultedItem={vaultedItem}
      selected={selected}
      onSelect={handleSelect}
    />
  );
};

export default VaultedPaymentMethod;
