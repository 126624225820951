import { FunctionalComponent } from 'preact';
import { useEffect, useLayoutEffect, useRef } from 'preact/hooks';
import styled from 'styled-components';
import { useCheckoutContext } from '@primer-io/shared-library/contexts';
import { getTextStyle } from '../utils';

const Root = styled.div`
  ${(p) => ({
    ...getTextStyle(p.theme.style?.inputErrorText),
    textAlign: p.theme.style?.inputErrorText?.textAlign,
  })}
`;

type Props = {
  id?: string;
  message?: string;
};

const Error: FunctionalComponent<Props> = ({ id, message }) => {
  const { viewUtils } = useCheckoutContext();

  const rootRef = useRef<typeof Root>();
  const elementRef = useRef<HTMLDivElement>();
  const animationId = useRef(`${Math.random()}`);

  useLayoutEffect(() => {
    const element = document.createElement('div');
    element.className =
      'PrimerCheckout__helperText PrimerCheckout__helperText--error';

    rootRef.current.appendChild(element);
    elementRef.current = element;
  }, []);

  useEffect(() => {
    if (message) {
      elementRef.current.textContent = message;
    }

    viewUtils.toggleVisibilityAnimated(elementRef.current, !!message, {
      id: animationId.current,
      duration: 300,
      animateHeight: true,
      autoHeight: true,
      onFinish: () => {
        if (!message) {
          elementRef.current.textContent = '';
        }
      },
    });
  }, [message, viewUtils]);

  return <Root ref={rootRef} id={id}></Root>;
};

export default Error;
