// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h, FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';
import { PresenceContext } from './PresenceContext';
import { VariantLabels } from './types';
import { useConstant } from './use-constant';

interface PresenceChildProps {
  isPresent: boolean;
  onExitComplete?: () => void;
  initial?: false | VariantLabels;
  custom?: any;
  presenceAffectsLayout: boolean;
}

let presenceId = 0;
function getPresenceId() {
  const id = presenceId;
  presenceId++;
  return id;
}

export const PresenceChild: FunctionalComponent<PresenceChildProps> = ({
  children,
  initial,
  isPresent,
  onExitComplete,
  custom,
  presenceAffectsLayout,
}) => {
  const presenceChildren = useConstant(newChildrenMap);
  const id = useConstant(getPresenceId);

  const context = useMemo(
    () => {
      return {
        id,
        initial,
        isPresent,
        custom,
        onExitComplete: (childId: number) => {
          presenceChildren.set(childId, true);
          let allComplete = true;
          presenceChildren.forEach((isComplete) => {
            if (!isComplete) allComplete = false;
          });

          allComplete && onExitComplete?.();
        },
        register: (childId: number) => {
          presenceChildren.set(childId, false);
          return () => presenceChildren.delete(childId);
        },
      };
    },
    /**
     * If the presence of a child affects the layout of the components around it,
     * we want to make a new context value to ensure they get re-rendered
     * so they can detect that layout change.
     */
    presenceAffectsLayout ? undefined : [isPresent],
  );

  useMemo(() => {
    presenceChildren.forEach((_, key) => presenceChildren.set(key, false));
  }, [isPresent]);

  return (
    <PresenceContext.Provider value={context}>
      {children}
    </PresenceContext.Provider>
  );
};

function newChildrenMap(): Map<number, boolean> {
  return new Map();
}
