import { Alpha3CurrencyCode, SupportedLocale } from '../types';
import formatLocale from './formatLocal';
import { getDecimalDigitsByCurrencyCode } from './getDecimalDigitsByCurrencyCode';
import createAmountLocalizer from './amountLocalizer';

export default function formatAmount(
  locale: SupportedLocale,
  amount: number,
  currencyCode: Alpha3CurrencyCode | string,
) {
  const formattedLocale = formatLocale(locale);
  const decimalDigits = getDecimalDigitsByCurrencyCode(currencyCode);
  const { localizeAmount } = createAmountLocalizer(
    amount,
    currencyCode,
    decimalDigits,
    formattedLocale,
  );
  try {
    return localizeAmount('narrowSymbol');
  } catch {
    return localizeAmount('symbol');
  }
}
