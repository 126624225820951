import { BasePaymentMethodManager } from './BasePaymentMethodManager';
import { HeadlessPaymentMethodButton } from './HeadlessPaymentMethodButton';
import { INativePaymentMethodManager, PaymentMethodType } from '../../types';
import { BasePaymentMethod } from '../../payment-methods/BasePaymentMethod';

export class NativePaymentMethodManager
  extends BasePaymentMethodManager
  implements INativePaymentMethodManager {
  static async create(
    type: PaymentMethodType,
    paymentMethod: BasePaymentMethod,
  ): Promise<NativePaymentMethodManager | null> {
    return new NativePaymentMethodManager(type, paymentMethod);
  }

  constructor(
    private readonly type: PaymentMethodType,
    private readonly paymentMethod: BasePaymentMethod,
  ) {
    super();
  }

  createButton(): HeadlessPaymentMethodButton {
    return new HeadlessPaymentMethodButton(this.paymentMethod);
  }
}
