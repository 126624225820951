import { useLayoutEffect, useRef } from 'preact/hooks';
import {
  useSceneLoaderContext,
  useSelector,
  useCheckoutStore,
  useCheckoutContext,
  useCheckoutModule,
} from '@primer-io/shared-library/contexts';
import { UseFormResult } from '@primer-io/shared-library/form';
import { CardPaymentMethodType } from '../../enums/Tokens';
import { SavePaymentMethodStore } from '../../checkout-modules/save-payment-method';
import { useBillingAddressForm } from '../../checkout-modules/billing-address';

import { SceneEnum } from '../../enums/Checkout';
import {
  useIsCurrentScene,
  useSceneEntering,
  useSceneExiting,
} from '../../utils/hooks';
import Scene from '../../scenes/Scene';
import CreditCardForm from './CreditCardForm';
import { noop } from '../../utils/noop';
import { WaitForComponents } from '../../utils/SceneLoader/WaitForComponents';
import { CreditCard } from './CreditCard';
import { PaymentMethodType } from '../../loader';
import { CardTokenizationService } from '../../checkout/CardTokenizationService';
import { CreditCardStore } from './CreditCardStore';
import { useCardDescriptionForm } from '../../checkout-modules/save-payment-method/form';

const SCENE = SceneEnum.CREDIT_CARD_FORM;
export const sceneRootId = 'primer-checkout-scene-credit-card-form';

const CreditCardFormScene = () => {
  const store = useCheckoutStore();
  const { options, clientSessionActionService } = useCheckoutContext();
  const { setIsMounted } = useSceneLoaderContext();
  const isCurrentScene = useIsCurrentScene(SCENE);
  const labels = useSelector((s) => s.translation);

  const cardTokenizationService = useRef(
    new CardTokenizationService(
      store,
      options,
      CardPaymentMethodType.PAYMENT_CARD,
    ),
  ).current;

  const card = store.getPaymentMethodWithType<CreditCard>(
    PaymentMethodType.PAYMENT_CARD,
  ) as CreditCard;
  const creditCardStore = store.getPaymentMethodStoreWithType<CreditCardStore>(
    PaymentMethodType.PAYMENT_CARD,
  ) as CreditCardStore;

  const savePaymentMethod = useCheckoutModule<SavePaymentMethodStore>(
    'SAVE_PAYMENT_METHOD',
  );

  const cardBillingAddressForm = useBillingAddressForm() as UseFormResult;

  const cardDescriptionForm = useCardDescriptionForm() as UseFormResult;

  useSceneEntering(() => {
    (async () => {
      store.setSubmitButtonContent(labels?.pay);
      store.setSubmitButtonCanDisplayAmount(true);
      store.setSubmitButtonVisible(true);
      await clientSessionActionService?.unselectPaymentMethod();
      store.setSubmitButtonDisabled(false);
    })();
  }, SCENE);

  useSceneExiting(() => {
    card.resetFormSubmission();
    cardBillingAddressForm?.resetFormValidation();

    creditCardStore.setFieldError('cardholderName', null);
  }, SCENE);

  useLayoutEffect(() => {
    if (!isCurrentScene) return noop;

    const handleSubmitButtonClick = async () => {
      await cardTokenizationService.validateCardForm();
      const submitBillingAddressForm = cardBillingAddressForm?.submitForm();
      const submitCardDescriptionform = cardDescriptionForm?.submitForm();

      if (
        submitCardDescriptionform &&
        submitCardDescriptionform.data.cardDescription.value
      ) {
        savePaymentMethod?.setUserDescription(
          submitCardDescriptionform.data.cardDescription.value as string,
        );
      }

      if (
        submitBillingAddressForm &&
        submitBillingAddressForm.isValid === false
      ) {
        return;
      }

      await cardTokenizationService.tokenizeCard();
    };

    store.addSubmitButtonClickListener(handleSubmitButtonClick);
    return () => {
      store.removeSubmitButtonClickListener(handleSubmitButtonClick);
    };
  }, [isCurrentScene]);

  return (
    <WaitForComponents onReady={setIsMounted}>
      <Scene
        previousScene={SceneEnum.CHOOSE_PAYMENT_METHOD}
        backButton={true}
        sceneRootId={sceneRootId}
        scene={SCENE}
        dimmable={true}
      >
        <CreditCardForm
          cardBillingAddressForm={cardBillingAddressForm}
          cardDescriptionForm={cardDescriptionForm}
          cardPaymentMethodType={CardPaymentMethodType.PAYMENT_CARD}
        />
      </Scene>
    </WaitForComponents>
  );
};

export default CreditCardFormScene;
