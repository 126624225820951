import { IBaseState, BaseStore } from '../../store/BaseStore';

export type ShippingMethod = {
  id: string;
  name: string;
  description?: string;
  amount: number;
};

interface ShippingState extends IBaseState {
  shippingMethods: ShippingMethod[];
  selectedShippingMethod?: string;
}

const defaultState: ShippingState = {
  shippingMethods: [],
  selectedShippingMethod: undefined,
};

export const ShippingStoreSelector = {
  getShippingMethods: (s: ShippingState) => s.shippingMethods,
  getSelectedShippingMethod: (s: ShippingState) => s.selectedShippingMethod,
};

export class ShippingStore extends BaseStore<ShippingState> {
  static type = 'SHIPPING';

  get type() {
    return ShippingStore.type;
  }

  setShippingMethods(shippingMethods: ShippingMethod[]) {
    this.produceState((draft) => {
      draft.shippingMethods = shippingMethods;
    });
  }

  setSelectedShippingMethod(selectedShippingMethod: string) {
    this.produceState((draft) => {
      draft.selectedShippingMethod = selectedShippingMethod;
    });
  }

  get shippingMethods() {
    return ShippingStoreSelector.getShippingMethods(this.getState());
  }

  get selectedShippingMethod() {
    return ShippingStoreSelector.getSelectedShippingMethod(this.getState());
  }
}

const createShippingStore = () => new ShippingStore(defaultState);
export default createShippingStore;
