export enum NetworkCallType {
  REQUEST_START = 'REQUEST_START',
  REQUEST_END = 'REQUEST_END',
}

export type NetworkEventProperties = {
  id: string;
  url: string;
  method: string;
  errorBody?: string;
  responseCode?: number;
  callType: NetworkCallType;
};

export interface CrashEventProperties {
  stacktrace: string;
}

export enum TimerType {
  START = 'START',
  END = 'END',
}

export interface TimerEventProperties {
  id: string;
  timerType: TimerType;
}

export enum MessageSeverity {
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  WARN = 'WARN',
  INFO = 'INFO',
}

export interface AnalyticsMessageProperties {
  messageType: string;
  message: string;
  severity: MessageSeverity;
  diagnosticId?: string;
}

export type AnalyticsV1Properties = any;

export type AnalyticEventProperties =
  | NetworkEventProperties
  | CrashEventProperties
  | TimerEventProperties
  | AnalyticsMessageProperties
  | AnalyticsV1Properties;
