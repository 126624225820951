import { FunctionalComponent } from 'preact';
import {
  useSelector,
  useCheckoutContext,
} from '@primer-io/shared-library/contexts';
import styled, { css } from 'styled-components';
import { getTextStyle } from '../utils';

const StyledLabel = styled.div`
  ${(p) => getTextStyle(p.theme.style?.inputLabel)}

  ${(p) =>
    p.hidden &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    `}
`;

type BaseLabelProps = {
  text?: string;
  inputId?: string;
  testId?: string;
  className?: string;
  hidden: boolean;
};

export const BaseLabel: FunctionalComponent<BaseLabelProps> = ({
  text,
  inputId,
  testId,
  className: labelClass,
  hidden,
}) => {
  const { className } = useCheckoutContext();
  const inputLabelClass = className('label', { hidden });
  return (
    <StyledLabel
      data-testId={testId}
      className={labelClass ?? inputLabelClass}
      for={inputId}
      hidden={hidden}
    >
      {text}
    </StyledLabel>
  );
};

type Props = {
  text?: string;
  inputId?: string;
  testId?: string;
  className?: string;
};

const Label: FunctionalComponent<Props> = (props) => {
  const inputLabelsVisible = useSelector((s) => s.form.inputLabelsVisible);
  return <BaseLabel {...props} hidden={!inputLabelsVisible} />;
};

export default Label;
