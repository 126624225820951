import urljoin from 'url-join';
import { createErrorMonitoring } from '../monitoring/ErrorMonitoring';
import { createAnalytics } from '../analytics/Analytics';
import { UniversalCheckoutOptions, ErrorCode } from '../types';
import { Environment } from '../utils/Environment';
import ModuleFactory from '../utils/ModuleFederation/ModuleFactory';

import { ScriptLoader } from '../utils/ScriptLoader';
import { uuid } from '../utils/uuid';
import { Api } from './Api';
import ClientConfigurationHandler from './ClientConfigurationHandler';
import {
  createClientTokenHandler,
  DecodedClientToken,
  IClientTokenHandler,
} from './ClientTokenHandler';
import { IFrameFactory } from './IFrameFactory';
import { IFrameMessageBus } from './IFrameMessageBus';
import { LongPoll } from './LongPoll';

import BaseClientContext, { ClientContext } from './ClientContext';
import { createStore } from '../store/CheckoutStoreFactory';
import InternalError from './InternalError';
import { PrimerClientError } from '../errors';
import { InternalFlowOptions } from '../internalTypes';

export const getAssetsURL = (): string => {
  const version = Environment.get('PRIMER_SDK_VERSION', '0.0.0-local');
  return urljoin(Environment.get('PRIMER_ASSETS_URL', ''), version);
};

const processClientToken = (
  clientToken: string,
  clientTokenHandler: IClientTokenHandler,
) => {
  let decodedClientToken: DecodedClientToken;
  try {
    decodedClientToken = clientTokenHandler.processClientToken(clientToken);
  } catch (e) {
    if (e instanceof InternalError) {
      throw PrimerClientError.fromErrorCode(ErrorCode.INITIALIZATION_ERROR, {
        message:
          {
            CLIENT_TOKEN_MALFORMED: 'The provided `clientToken` is malformed.',
            CLIENT_TOKEN_EXPIRED: 'The provided `clientToken` has expired.',
          }[e.code] ?? `Unknown client token error: ${e.code}`,
      });
    } else {
      throw PrimerClientError.fromErrorCode(ErrorCode.INITIALIZATION_ERROR, {
        message: `Unknown client token error: ${e.message}`,
      });
    }
  }

  return decodedClientToken;
};

export const ClientContextFactory = {
  create({
    options,
    clientOptions,
  }: {
    options: { clientToken: string };
    clientOptions: InternalFlowOptions;
  }): ClientContext {
    const assetsUrl = getAssetsURL();

    ///////////////////////////////////////////
    // Client Token
    ///////////////////////////////////////////

    const clientTokenHandler = createClientTokenHandler();

    const { accessToken, analyticsUrlV2: analyticsUrl } = processClientToken(
      options.clientToken,
      clientTokenHandler,
    );

    ///////////////////////////////////////////
    // Dependencies
    ///////////////////////////////////////////

    // Logging
    const errorMonitoring = createErrorMonitoring();
    const analytics = createAnalytics(analyticsUrl);

    // iFrame
    const messageBus = new IFrameMessageBus();
    const iframes = new IFrameFactory({ messageBus, assetsUrl });

    // API
    const ProxyApi = new Proxy(Api, {
      apply(target: any, thisArg: any, argArray?: any): any {
        console.log('ProxyApi', target, thisArg, argArray);
      },
    });
    const api = new ProxyApi({ iframes, messageBus, accessToken, analytics });

    const longPoll = new LongPoll({ api });

    // Module
    const scriptLoader = new ScriptLoader();
    const moduleFactory = new ModuleFactory(scriptLoader);

    // Client Configuration
    const clientConfigurationHandler = new ClientConfigurationHandler(api);

    // Store
    const store = createStore(clientOptions);

    clientTokenHandler.addNewClientTokenListener(
      ({ accessToken: newAccessToken }) => api.setAccessToken(newAccessToken),
    );

    const clientContext = new BaseClientContext();
    clientContext.clientTokenHandler = clientTokenHandler;
    clientContext.errorMonitoring = errorMonitoring;
    clientContext.analytics = analytics;
    clientContext.messageBus = messageBus;
    clientContext.iframes = iframes;
    clientContext.api = api;
    clientContext.longPoll = longPoll;
    clientContext.scriptLoader = scriptLoader;
    clientContext.moduleFactory = moduleFactory;
    clientContext.clientConfigurationHandler = clientConfigurationHandler;
    clientContext.clientOptions = clientOptions;
    clientContext.store = store;

    initializeAnalyticsProvider(clientContext);

    return clientContext;
  },
};

function initializeAnalyticsProvider(context: ClientContext): void {
  const clientSession = context.store.getClientSession();

  const options = {
    clientSessionId: clientSession?.clientSessionId,
    customerId: clientSession?.customer.customerId,
    checkoutSessionId: uuid(),
  };

  context.analytics.initProvider(context.api, options);
}

export function throwPrimerCheckoutError(
  options: UniversalCheckoutOptions,
  error: PrimerClientError,
) {
  if (options.onCheckoutFail) {
    return options.onCheckoutFail?.(error, {}, undefined);
  }

  throw error;
}
