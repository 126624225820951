import {
  PaymentMethodType,
  IAssetsManager,
  ButtonPaymentMethodAsset,
} from '../../types';
import CheckoutStore from '../../store/CheckoutStore';

export class AssetsManager implements IAssetsManager {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly store: CheckoutStore) {}

  async getPaymentMethodAsset(
    type: PaymentMethodType,
  ): Promise<ButtonPaymentMethodAsset | null> {
    const paymentMethods = this.store.getPaymentMethods();
    const paymentMethod = paymentMethods[type];

    if (!paymentMethod) {
      console.warn(`Can't find asset for ${type}`);
      return null;
    }

    const { backgroundColor, iconUrl, text: paymentMethodName } =
      paymentMethod.remoteConfig?.displayMetadata?.button || {};

    return { backgroundColor, iconUrl, paymentMethodName };
  }
}
