import { funcify } from '../../utils/funcify';
import { noop } from '../../utils/noop';
import { BasePaymentMethod, PaymentMethodSpecs } from '../BasePaymentMethod';
import { IFrameEventType } from '../../core/IFrameEventType';
import { ErrorCode, PrimerClientError } from '../../errors';
import { errorFromAPIResponse } from './errorFromAPIResponse';
import { IPaymentMethodContext } from '../../core/PaymentMethodContext';
import { APIResponse } from '../../core/Api';
import { PaymentMethodToken, Validation } from '../../types';
import {
  CardFieldRef,
  CreditCardFieldName,
  CreditCardFieldOptions,
  CreditCardOptions,
  CreditCardOptionsIn,
} from './types';
import { PaymentMethodType } from '../../enums/Tokens';
import { ApiEvent } from '../../analytics/constants/enums';
import createCreditCardStore, {
  CardFieldType,
  CreditCardStore,
} from './CreditCardStore';
import { SavePaymentMethodStore } from '../../checkout-modules/save-payment-method';
import createCardFormManager, { CardFormManager } from './CardFormManager';
import { Observer } from '../../core/Observable';

const PAYMENT_METHOD_NAME = 'Card';

export class CreditCard extends BasePaymentMethod {
  static specs: PaymentMethodSpecs = {
    key: 'card',
    canVault: true,
    buttonManagedByPaymentMethod: false,
    hasExportedButtonOptions: false,
  };

  public static create = (
    context: IPaymentMethodContext,
    options: CreditCardOptionsIn,
  ) => new CreditCard(context, options);

  private context: IPaymentMethodContext;

  private fields: Record<CreditCardFieldName, Nullable<CardFieldRef>>;

  private store: CreditCardStore;

  private cardFormManager: CardFormManager;

  options: CreditCardOptions;

  constructor(context: IPaymentMethodContext, opts: CreditCardOptionsIn) {
    super(PaymentMethodType.PAYMENT_CARD, PAYMENT_METHOD_NAME);
    const options = normalizeOptions(opts);

    this.context = context;
    this.options = options;
    this.fields = {
      cardNumber: null,
      cvv: null,
      expiryDate: null,
    };

    this.store = createCreditCardStore();
    this.cardFormManager = createCardFormManager(
      this.store,
      this.fields,
      this.options,
      this.context,
    );
  }

  public getStore() {
    return this.store;
  }

  getFields(): Record<CreditCardFieldName, Nullable<CardFieldRef>> {
    return this.fields;
  }

  async setupAndValidate() {
    const { fields } = this.options;
    Object.entries(fields).forEach(([key, value]) => {
      this.getStore().setInputId(
        key as CardFieldType,
        value.container.replace('#', ''),
      );

      this.getStore().setInputName(key as CardFieldType, value.name);

      this.getStore().setFieldEnabled(key as CardFieldType);
    });

    this.getStore().setInputId(
      'cardholderName',
      `primer-checkout-card-cardholder-name-input`,
    );
    return true;
  }

  async mount(): Promise<boolean> {
    await this.cardFormManager.mountCardForm();

    return true;
  }

  async initMessageBus() {
    return this.cardFormManager.initMessageBus();
  }

  async tokenize() {
    this.context.progress.start();

    this.context.analytics.callV1({ event: ApiEvent.tokenizeCalled });

    if (!this.context.store.getIsTokenizationEnabled()) {
      this.context.progress.didNotStart('TOKENIZATION_DISABLED');
      return;
    }
    const shouldStart = await this.context.progress.shouldStart();
    if (shouldStart === false) {
      this.context.progress.didNotStart('TOKENIZATION_SHOULD_NOT_START');
      return;
    }

    const isValid = await this.cardFormManager.submitAndValidateCardForm();

    if (!isValid) {
      return;
    }

    const vault = this.options.vault();
    const cardholderName = this.options.cardholderName();
    const savePaymentMethod = this.context.store.getCheckoutModuleWithType(
      'SAVE_PAYMENT_METHOD',
    ) as SavePaymentMethodStore;
    const userDescription = savePaymentMethod?.userDescription;

    const paymentInstrument: Record<string, string> = {
      number: '$.cardNumber-card',
      cvv: '$.cvv-card',
      expirationMonth: '$.expiryDate-card.month',
      expirationYear: '$.expiryDate-card.year',
    };

    if (cardholderName) {
      paymentInstrument.cardholderName = cardholderName;
    }
    const body: Record<string, unknown> = {
      paymentInstrument,
      userDescription,
    };
    if (vault) {
      body.tokenType = 'MULTI_USE';
      body.paymentFlow = 'VAULT';
    }
    try {
      const response = await this.context.tokenizePaymentMethod(body);

      this.handleTokenizationResponse(response);
      this.cardFormManager.updateFields();
    } catch (e) {
      this.context.progress.error(
        PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, {
          message: 'Call to /payment-instruments failed',
        }),
      );
    }
  }

  handleTokenizationResponse(response: APIResponse<PaymentMethodToken>): void {
    const { data, error } = response;
    if (error || !data) {
      const err = errorFromAPIResponse(error);
      this.context.progress.error(err);
      if (err.code === ErrorCode.CARD_NUMBER_ERROR) {
        this.context.messageBus.publish('cardNumber', {
          type: IFrameEventType.UPDATE_METADATA,
          payload: {
            error: 'invalid',
            valid: false,
          },
        });
      }
      return;
    }
    this.context.progress.success(data);
  }

  async validate(): Promise<Validation> {
    return this.cardFormManager.validate();
  }

  resetFormSubmission() {
    this.cardFormManager.resetFormSubmission();
  }

  // Used by VaultManager
  reset() {
    this.cardFormManager.reset();
  }

  async createField(
    name: CreditCardFieldName,
    config: CreditCardFieldOptions,
  ): Promise<void> {
    return this.cardFormManager.createField(name, config);
  }

  setCardHolderCallback(callback: () => string) {
    this.cardFormManager.setCardHolderCallback(callback);
  }

  subscribeToEvent(type: IFrameEventType, callback: Observer) {
    this.cardFormManager.context.messageBus.on(type, callback);
  }

  publishEvent(fieldName: string, event: IFrameEventType, payload?: any) {
    this.cardFormManager.context.messageBus.publish(fieldName, {
      type: event,
      payload,
    });
  }
}

/**
 *
 * @param {Primer.CreditCardConfig} opts
 */
function normalizeOptions(opts: CreditCardOptionsIn): CreditCardOptions {
  return {
    ...opts,
    cardholderName: funcify(opts.cardholderName),
    vault: funcify(opts.vault ?? false),
    onChange: opts.onChange || noop,
    onCardMetadata: opts.onCardMetadata || noop,
    disabled: opts.disabled || funcify(false),
  };
}
