import deepmerge from 'deepmerge';
import { SceneTransitionStyle } from '../../styles';
import { SceneTransitionOptions } from '../../types';

export class SceneTransitionManager {
  private sceneOptions: SceneTransitionOptions;

  constructor(sceneOptions: SceneTransitionOptions) {
    this.sceneOptions = sceneOptions;
  }

  baseTransitionStyle(): SceneTransitionStyle {
    const duration =
      (this.sceneOptions as SceneTransitionOptions).duration ?? 0;
    const linearTransitionDuration = duration < 900 ? 0.35 : 0.5;

    return {
      content: {
        transition: `height ${duration}ms cubic-bezier(0.4, 0, 0.2, 1)`,
      },
      entering: {
        transition: `transform ${duration}ms cubic-bezier(0.4, 0, 0.2, 1), opacity ${
          duration * 0.233
        }ms linear ${duration * linearTransitionDuration}ms`,
        transform: 'translateY(0px)',
        opacity: 1,
      },
      exit: {
        transform: 'translateY(0px) translateX(0px)',
        opacity: '1',
      },
      exiting: {
        transition: `transform ${duration}ms cubic-bezier(0.4, 0, 0.2, 1), opacity ${
          duration * 0.233
        }ms linear 0ms`,
      },
    };
  }

  getTransitionStyles(): SceneTransitionStyle {
    switch (this.sceneOptions.type) {
      case 'SLIDE_UP':
        return this.getSlideUpTransition();
      case 'SLIDE_DOWN':
        return this.getSlideDownTransition();
      case 'SLIDE_HORIZONTAL':
        return !this.sceneOptions.isRtlLocale
          ? this.getSlideHorizontalTransitionLeft()
          : this.getSlideHorizontalTransitionRight();
      default:
        return this.getSlideUpTransition();
    }
  }

  public static getNoTransitionStyle() {
    return {
      content: {
        transition: 'none',
      },
      entering: {
        transition: 'none',
      },
      exit: {
        transition: 'none',
      },
      exiting: {
        transition: 'none',
      },
    };
  }

  getSlideUpTransition() {
    return deepmerge(this.baseTransitionStyle(), {
      enter: {
        transform: 'translateY(100px)',
        opacity: '0',
      },
      exiting: {
        transform: 'translateY(-100px)',
        opacity: '0',
      },
    });
  }

  getSlideDownTransition() {
    return deepmerge(this.baseTransitionStyle(), {
      enter: {
        transform: 'translateY(-100px)',
        opacity: '0',
      },
      exiting: {
        transform: 'translateY(100px)',
        opacity: '0',
      },
    });
  }

  getSlideHorizontalTransitionLeft() {
    return deepmerge(this.baseTransitionStyle(), {
      push: {
        enter: {
          opacity: 0,
          transform: 'translateX(100px)',
        },
        entering: {
          opacity: 1,
          transform: 'translateX(0px)',
        },
        exiting: {
          transform: 'translateX(-100px)',
          opacity: '0',
        },
      },
      pop: {
        enter: {
          opacity: 0,
          transform: 'translateX(-100px)',
        },
        entering: {
          opacity: 1,
          transform: 'translateX(0px)',
        },
        exiting: {
          transform: 'translateX(100px)',
          opacity: '0',
        },
      },
    });
  }

  getSlideHorizontalTransitionRight() {
    return deepmerge(this.baseTransitionStyle(), {
      push: {
        enter: {
          opacity: 0,
          transform: 'translateX(-100px)',
        },
        entering: {
          opacity: 1,
          transform: 'translateX(0px)',
        },
        exiting: {
          transform: 'translateX(100px)',
          opacity: '0',
        },
      },
      pop: {
        enter: {
          opacity: 0,
          transform: 'translateX(100px)',
        },
        entering: {
          opacity: 1,
          transform: 'translateX(0px)',
        },
        exiting: {
          transform: 'translateX(-100px)',
          opacity: '0',
        },
      },
    });
  }
}
