import { Environment } from './utils/Environment';
import { ClientContextFactory } from './core/ClientContextFactory';
import {
  UniversalCheckoutOptions,
  VaultManagerOptions,
  SinglePaymentMethodCheckoutOptions,
  CheckoutUXFlow,
  PrimerCheckout,
  PrimerVaultManager,
  PrimerHeadlessCheckout,
} from './types';
import { initGlobalErrorMonitoring } from './monitoring/ErrorMonitoring';
import FlowFactory from './checkout/flows/Factory';
import { ApiEvent } from './analytics/constants/enums';
import { InternalFlowOptions } from './internalTypes';
import { ErrorCode, PrimerClientError } from './errors';
import isElement from './utils/isElement';

const version = Environment.get<string>('PRIMER_SDK_VERSION');

interface Primer {
  SDK_VERSION: string;

  createHeadless(clientToken: string): Promise<PrimerHeadlessCheckout>;

  showUniversalCheckout(
    clientToken: string,
    options: UniversalCheckoutOptions | SinglePaymentMethodCheckoutOptions,
  ): Promise<PrimerCheckout>;

  showVaultManager(
    clientToken: string,
    options: VaultManagerOptions,
  ): Promise<PrimerVaultManager>;

  showExpressCheckout(
    clientToken: string,
    options: any,
  ): Promise<PrimerCheckout>;
}

const startFlow = async (
  clientToken: string,
  clientOptions: InternalFlowOptions,
) => {
  initGlobalErrorMonitoring();

  //TODO: Clean up this stuff
  const context = ClientContextFactory.create({
    options: { clientToken },
    clientOptions,
  });
  const checkout = await FlowFactory.create(context, clientOptions);

  // End of loading
  // (start of loading is done by the context :/)
  // DEVNOTE: In Headless checkout we call start with delay and for that reason we should postpone analytics call
  if (
    !(
      (clientOptions.uxFlow as CheckoutUXFlow) ===
      CheckoutUXFlow.HEADLESS_CHECKOUT
    )
  ) {
    context.analytics.callV1({
      event: ApiEvent.loadedCheckoutUi,
      data: {
        uxFlow: clientOptions.uxFlow,
      },
    });
  }

  return checkout;
};

export const validateShowUniversalCheckoutArgs = (...args) => {
  const throwInvalidArgument = (message: string) => {
    throw PrimerClientError.fromErrorCode(ErrorCode.INVALID_ARGUMENT, {
      message,
    });
  };

  if (args.length !== 2) {
    throwInvalidArgument(
      `\`Primer.showUniversalCheckout\` received ${args.length} argument(s), but expects 2: \`clientToken\` and \`options\``,
    );
  }

  const [clientToken, options] = args as [string, UniversalCheckoutOptions];

  if (typeof clientToken !== 'string') {
    throwInvalidArgument(
      `The first argument of \`Primer.showUniversalCheckout(clientToken, options)\` should be a string. \`clientToken\` is currently: \`${JSON.stringify(
        clientToken,
      )}\``,
    );
  }

  if (!options) {
    throwInvalidArgument(
      `The second argument of \`Primer.showUniversalCheckout(clientToken, options)\` should be an object. \`options\` is currently: \`${JSON.stringify(
        options,
      )}\``,
    );
  }

  if (
    !options.container ||
    !['object', 'string'].includes(typeof options.container) ||
    (typeof options.container === 'object' && !isElement(options.container))
  ) {
    throwInvalidArgument(
      `In \`Primer.showUniversalCheckout(clientToken, options)\`: \`options.container\` should be a string or a DOM element. \`options.container\` is currently: ${JSON.stringify(
        options.container,
      )}`,
    );
  }

  if (!options.onCheckoutComplete) {
    console.warn(
      `In \`Primer.showUniversalCheckout(clientToken, options)\`: It is recommended to pass \`options.onCheckoutComplete\` to continue the flow once the checkout has been completed.`,
    );
  }

  if (
    options.onCheckoutComplete &&
    typeof options.onCheckoutComplete !== 'function'
  ) {
    throwInvalidArgument(
      `In \`Primer.showUniversalCheckout(clientToken, options)\`: \`options.onCheckoutComplete\` should be a function. \`options.onCheckoutComplete\` is currently: \`${JSON.stringify(
        options.onCheckoutComplete,
      )}\``,
    );
  }
};

// This class can't be called "PrimerClient" due to limitation of the loader + dts-bundle
const Client: Primer = {
  SDK_VERSION: version,

  async createHeadless(clientToken: string) {
    return startFlow(clientToken, {
      uxFlow: CheckoutUXFlow.HEADLESS_CHECKOUT,
    } as InternalFlowOptions) as Promise<PrimerHeadlessCheckout>;
  },

  async showUniversalCheckout(
    clientToken: string,
    options: UniversalCheckoutOptions,
  ) {
    validateShowUniversalCheckoutArgs(clientToken, options);
    return startFlow(
      clientToken,
      options as InternalFlowOptions,
    ) as Promise<PrimerCheckout>;
  },

  async showVaultManager(clientToken: string, options: VaultManagerOptions) {
    // TODO: validate type

    return startFlow(clientToken, {
      ...options,
      uxFlow: CheckoutUXFlow.MANAGE_PAYMENT_METHODS,
    } as InternalFlowOptions) as Promise<PrimerVaultManager>;
  },

  async showExpressCheckout(clientToken: string, options: any) {
    // TODO: validate type

    return startFlow(clientToken, {
      ...options,
      uxFlow: CheckoutUXFlow.EXPRESS_CHECKOUT,
    }) as Promise<PrimerCheckout>;
  },
};

export default Client;
