import window from './window';

enum ScriptStatus {
  UNINITIALIZED,
  LOADING,
  READY,
  ERROR,
}

interface ScriptLoadCallback {
  (loaded: boolean): void;
}

interface ScriptDescriptor {
  status: ScriptStatus;
  callbacks: ScriptLoadCallback[];
}

export class ScriptLoader {
  private scripts: Record<string, ScriptDescriptor>;

  private timeoutMilliseconds: number;

  constructor() {
    this.scripts = {};
    this.timeoutMilliseconds = 10000;
  }

  public load(
    src: string,
    options?: {
      crossorigin?: 'anonymous' | 'use-credentials';
      customAttributes?: Record<string, string>;
    },
  ): Promise<boolean> {
    if (typeof this.scripts[src] === 'undefined') {
      this.scripts[src] = { status: ScriptStatus.UNINITIALIZED, callbacks: [] };
    }

    const script = this.scripts[src];

    if (script.status === ScriptStatus.READY) {
      return Promise.resolve(true);
    }

    if (script.status === ScriptStatus.LOADING) {
      return new Promise((resolve) => script.callbacks.push(resolve));
    }

    script.status = ScriptStatus.LOADING;

    const el = window.document.createElement('script');

    const promise: Promise<boolean> = new Promise((resolve) => {
      script.callbacks.push(resolve);

      function onComplete(error?: string) {
        if (error) {
          window.console.error(error);
          script.status = ScriptStatus.ERROR;
        } else {
          script.status = ScriptStatus.READY;
        }

        script.callbacks.forEach((cb) => cb(!error));
        script.callbacks = [];
      }

      const timer = window.setTimeout(() => {
        onComplete('Script load timed out');
      }, this.timeoutMilliseconds);

      el.onload = () => {
        window.clearTimeout(timer);
        onComplete();
      };

      el.onerror = () => {
        window.clearTimeout(timer);
        onComplete('Failed to load script fom source: ');
      };
    });

    el.setAttribute('src', src);

    Object.entries(options?.customAttributes ?? {}).forEach(([name, value]) => {
      el.setAttribute(name, value);
    });

    if (options?.crossorigin) {
      el.setAttribute('crossorigin', options.crossorigin);
    }

    window.document.head.appendChild(el);

    return promise;
  }
}
