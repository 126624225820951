import { Alpha3CurrencyCode } from '../types';
import { getDecimalDigitsByCurrencyCode } from './getDecimalDigitsByCurrencyCode';

interface ValueParser {
  asString(): string;
  asNumber(): number;
}

export function parseAmountValue(
  value: unknown,
  currencyCode?: Alpha3CurrencyCode,
): ValueParser {
  let normalized: number | null = null;
  const decimalDigits = getDecimalDigitsByCurrencyCode(currencyCode);

  switch (typeof value) {
    case 'number':
      normalized = value >= 0 ? value : null;
      break;
    case 'string':
      normalized = /^\d+\.\d{2}$/.test(value)
        ? parseFloat(value.replace('.', ''))
        : null;
      break;
    default:
      normalized = null;
      break;
  }

  if (normalized == null) {
    normalized = 0;
  }

  return {
    asString() {
      const digitsCount = Math.round(Math.log(decimalDigits) / Math.log(10));

      return ((normalized as number) / decimalDigits).toFixed(digitsCount);
    },
    asNumber() {
      return normalized as number;
    },
  };
}
