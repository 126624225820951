import {
  createPaymentMethod,
  CreatePaymentMethodOptions,
} from '@primer-io/shared-library/async-payment-method/PaymentMethod';
import { BasePaymentMethod } from '../../BasePaymentMethod';
import { ClientContext } from '../../../core/ClientContext';
import { PaymentMethodConfig } from '../../../models/ClientConfiguration';

const formatPaymentMethodOptions = (
  paymentMethodConfig: PaymentMethodConfig,
): CreatePaymentMethodOptions => {
  const {
    name,
    type,
    displayMetadata: { button, overlay, popup },
  } = paymentMethodConfig;

  return {
    paymentMethodKey: type,
    clientTokenIntent: `${type}_REDIRECTION`,
    paymentMethodName: name,
    paymentMethodType: type,
    paymentMethodLabel: name,
    popup: {
      width: popup?.width ?? 1000,
      height: popup?.height ?? 500,
    },
    overlay: {
      logoSrc:
        overlay?.logo.colored ||
        button?.iconUrl.colored ||
        overlay?.logo.light ||
        button?.iconUrl.light ||
        overlay?.logo.dark ||
        button?.iconUrl.dark,
      background:
        overlay?.backgroundColor.colored ||
        button?.backgroundColor.colored ||
        overlay?.backgroundColor.light ||
        button?.backgroundColor.light ||
        overlay?.backgroundColor.dark ||
        button?.backgroundColor.dark,
    },
  };
};

const buildWebRedirectPaymentMethod = async ({
  paymentMethodConfig,
}: {
  context: ClientContext;
  paymentMethodConfig: PaymentMethodConfig;
}) => {
  return (createPaymentMethod(
    formatPaymentMethodOptions(paymentMethodConfig),
    paymentMethodConfig.implementationType,
  ) as unknown) as typeof BasePaymentMethod;
};

export default buildWebRedirectPaymentMethod;
