import { ClientContext } from '../../core/ClientContext';
import { PaymentMethodType } from '../../enums/Tokens';
import { PaymentMethodConfig } from '../../models/ClientConfiguration';
import { CreditCard } from '../credit-card/CreditCard';
import nativeSdk from './native-sdk';
import { ImplementationType } from './types';
import webRedirect from './web-redirect';

const paymentMethodImplementations: Record<
  ImplementationType,
  (_: {
    context: ClientContext;
    paymentMethodConfig: PaymentMethodConfig;
  }) => void
> = {
  [ImplementationType.NATIVE_SDK]: nativeSdk,
  [ImplementationType.WEB_REDIRECT]: webRedirect,
};

const localPaymentMethodDefinitions = {
  [PaymentMethodType.PAYMENT_CARD]: { PaymentMethod: CreditCard },
} as const;

const getPaymentMethod = async ({
  context,
  paymentMethodConfig,
}: {
  context: ClientContext;
  paymentMethodConfig: PaymentMethodConfig;
}) => {
  const { type, implementationType } = paymentMethodConfig;

  const localDefinition = localPaymentMethodDefinitions[type];

  if (localDefinition) return localDefinition.PaymentMethod;

  const paymentMethodImplementation =
    paymentMethodImplementations[implementationType];

  if (!paymentMethodImplementation) return null;

  return paymentMethodImplementation({ context, paymentMethodConfig });
};

export default getPaymentMethod;
