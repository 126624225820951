import { noop } from '../../utils/noop';
import { BaseStore, IBaseState } from '../../store/BaseStore';

interface IFrameOverlayState extends IBaseState {
  isVisible: boolean;
  src: string;

  onCloseClick?: () => void;
}

const defaultState: IFrameOverlayState = {
  isVisible: false,
  src: '',

  onCloseClick: () => noop,
};

export class IFrameOverlayStore extends BaseStore<IFrameOverlayState> {
  showIFrameOverlay(src: string, onCloseClick: () => void) {
    this.produceState((draft) => {
      draft.isVisible = true;
      draft.src = src;
      draft.onCloseClick = onCloseClick;
    });
  }

  hideIFrameOverlay() {
    this.produceState((draft) => {
      draft.isVisible = false;
      draft.onCloseClick = undefined;
    });
  }
}

const createIFrameOverlayStore = () => new IFrameOverlayStore(defaultState);
export default createIFrameOverlayStore;
