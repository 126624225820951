// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h, FunctionalComponent } from 'preact';
import { useCallback, useLayoutEffect, useState } from 'preact/hooks';
import {
  useCheckoutContext,
  useCheckoutStore,
  useSelector,
  SceneLoaderProvider,
} from '@primer-io/shared-library/contexts';
import { SceneEnum } from '../../enums/Checkout';
import * as CheckoutScene from '../../scenes/CheckoutScene';
import * as LoadingScene from '../../scenes/LoadingScene';
import * as PaymentMethodSuccessScene from '../../scenes/success/PaymentMethodSuccessScene';
import * as VaultManagerScene from '../../scenes/VaultManagerScene';
import { SceneStage } from '../../store/BaseStore';
import * as CheckSuccessScene from '../../scenes/success/CheckSuccessScene';
import * as CreditCardFormScene from '../../payment-methods/credit-card/CreditCardFormScene';
import { SceneTransitionOptions } from '../../types';

const SceneLoaders = {
  [SceneEnum.LOADING]: async () => LoadingScene,
  [SceneEnum.CHOOSE_PAYMENT_METHOD]: async () => CheckoutScene,
  [SceneEnum.MANAGE_PAYMENT_METHODS]: async () => VaultManagerScene,
  [SceneEnum.SUCCESS_PAYMENT_METHOD]: async () => PaymentMethodSuccessScene,
  [SceneEnum.SUCCESS_CHECK]: async () => CheckSuccessScene,
  [SceneEnum.CREDIT_CARD_FORM]: async () => CreditCardFormScene,
};

type Props = {
  scene: string;
};

const SceneLoader: FunctionalComponent<Props> = ({ scene }) => {
  const { transitions, context, options } = useCheckoutContext();
  const store = useCheckoutStore();
  const { duration } = store.getState().scene
    .transition as SceneTransitionOptions;
  const sceneStage = useSelector(
    (s) => s.sceneStates[scene]?.stage,
  ) as SceneStage;

  const sceneAction = useSelector((s) => s.sceneAction);

  const [SceneComponent, setSceneComponent] = useState({
    Component: null,
  });

  useLayoutEffect(() => {
    (async () => {
      let Component;
      let sceneRootId = scene;

      // Find component
      if (SceneLoaders[scene]) {
        const module = await SceneLoaders[scene]();
        Component = module.default;
        sceneRootId = module.sceneRootId;
      } else {
        const pack = (
          await Promise.all(
            context?.moduleFactory.getPackages().map(async (p) => {
              try {
                const declaration = await p.getDeclaration();
                const hasScene = declaration?.getScenePath?.(scene);
                return hasScene && p;
              } catch (e) {}

              return null;
            }) ?? [],
          )
        ).find((p) => !!p);

        if (!pack) {
          console.log("Can't find scene", scene);
          return;
        }

        const scenePath = (await pack.getDeclaration())?.getScenePath?.(scene);
        if (scenePath) {
          const module = pack.getModule(scenePath);
          Component = await module.import();
          sceneRootId = (await module.import('sceneRootId')) as string;
        }
      }

      setSceneComponent({ Component });

      if (sceneStage === SceneStage.Entering) {
        store.handleSceneEntering(scene);
        await transitions.onEnter(
          sceneRootId as SceneEnum,
          duration,
          sceneAction,
          options,
        );
        store.handleSceneEntered(scene);
      } else if (sceneStage === SceneStage.Exiting) {
        await transitions.onExit(
          sceneRootId as SceneEnum,
          duration,
          sceneAction,
        );
        store.handleSceneExited(scene);
      }
    })();
  }, [sceneStage]);

  const setIsMounted = useCallback(() => {
    store.handleSceneMounted(scene);
  }, []);

  const Component = SceneComponent.Component as FunctionalComponent | null;

  return (
    <SceneLoaderProvider value={{ setIsMounted }}>
      {Component && <Component />}
    </SceneLoaderProvider>
  );
};

export default SceneLoader;
