import { CardPaymentMethodManager } from './CardPaymentMethodManager';
import { CardPaymentMethodType, PaymentMethodType } from '../../enums/Tokens';
import CheckoutStore from '../../store/CheckoutStore';
import { CreditCard } from '../../payment-methods/credit-card/CreditCard';
import { CardTokenizationService } from '../CardTokenizationService';
import {
  HeadlessUniversalCheckoutOptions,
  IAssetsManager,
  PaymentMethodManagerOptions,
  PaymentMethodManagers,
} from '../../types';
import { ClientContext } from '../../core/ClientContext';
import { NativePaymentMethodManager } from './NativePaymentMethodManager';
import { IPaymentMethodContextFactory } from '../../core/PaymentMethodContextFactory';
import { createStyleManager, IStyleManager } from '../ui/StyleManager';
import { RedirectPaymentMethodManager } from './RedirectPaymentMethodManager';
import getPaymentMethod from '../../payment-methods/payment-method-implementations';
import { BasePaymentMethod } from '../../payment-methods/BasePaymentMethod';
import { ImplementationType } from '../../payment-methods/payment-method-implementations/types';
import { AssetsManager } from './AssetsManager';

const SUPPORTED_NATIVE_PAYMENT_METHODS = [
  PaymentMethodType.PAYPAL,
  PaymentMethodType.GOOGLE_PAY,
  PaymentMethodType.APPLE_PAY,
];

interface PaymentMethodManagerInitOptions {
  store: CheckoutStore;
  options: HeadlessUniversalCheckoutOptions;
  context: ClientContext;
  paymentMethodContextFactory: IPaymentMethodContextFactory;
  paymentMethodContextConfig: Record<string, unknown>;
  styleManager?: IStyleManager;
}

export class PaymentMethodManager {
  private store: CheckoutStore;

  private context: ClientContext;

  private paymentMethodContextFactory: IPaymentMethodContextFactory;

  private paymentMethodContextConfig: Record<string, unknown>;

  private styleManager: IStyleManager;

  private options: HeadlessUniversalCheckoutOptions;

  static create() {
    return new PaymentMethodManager();
  }

  init(initOptions: PaymentMethodManagerInitOptions) {
    this.store = initOptions.store;
    this.context = initOptions.context;
    this.options = initOptions.options;
    this.paymentMethodContextFactory = initOptions.paymentMethodContextFactory;
    this.paymentMethodContextConfig = initOptions.paymentMethodContextConfig;
    this.styleManager = initOptions.styleManager || createStyleManager();
  }

  async createPaymentMethodManager(
    type: PaymentMethodType,
    options?: PaymentMethodManagerOptions,
  ): Promise<PaymentMethodManagers | null> {
    const paymentMethod = this.store.getPaymentMethodWithType(type);

    if (type === PaymentMethodType.PAYMENT_CARD) {
      return this.createCardPaymentMethodManager(
        paymentMethod as CreditCard,
        options,
      );
    }

    const Manager = this.getPaymentManagerByType(
      paymentMethod?.remoteConfig?.implementationType,
      type,
    );
    if (!Manager) {
      console.warn(`Payment method type ${type} is not supported`);
      return null;
    }

    const paymentMethodImpl = await this.getPaymentMethodByType(type);
    if (!paymentMethodImpl) {
      console.warn(`Can't get ${type} payment method`);
      return null;
    }

    return Manager.create(type, paymentMethodImpl);
  }

  getAssetsManager(): IAssetsManager {
    return new AssetsManager(this.store);
  }

  private async getPaymentMethodByType(
    type: PaymentMethodType,
  ): Promise<BasePaymentMethod | null> {
    const paymentMethodConfig = this.context.session.paymentMethods.find(
      (pm) => pm.type === type,
    );
    if (!paymentMethodConfig) {
      console.warn(`Can't find ${type}`);
      return null;
    }

    const PaymentMethod = await getPaymentMethod({
      context: this.context,
      paymentMethodConfig,
    });
    if (!PaymentMethod) {
      console.warn(`Can't init ${type}`);
      return null;
    }

    const paymentMethodContext = this.paymentMethodContextFactory.createPaymentMethodContext(
      { paymentMethodType: type },
    );
    const paymentMethod = PaymentMethod.create(
      paymentMethodContext,
      this.paymentMethodContextConfig,
      paymentMethodConfig,
      this.styleManager,
    ) as BasePaymentMethod;

    try {
      await paymentMethod.setupAndValidate();
    } catch (e) {
      console.warn(`Can't init ${type}`, e);
      return null;
    }

    return paymentMethod;
  }

  private createCardPaymentMethodManager(
    paymentMethod: CreditCard,
    options?: PaymentMethodManagerOptions,
  ): PaymentMethodManagers {
    const tokenizationService = new CardTokenizationService(
      this.store,
      this.options,
      CardPaymentMethodType.PAYMENT_CARD,
    );
    return new CardPaymentMethodManager(
      paymentMethod as CreditCard,
      tokenizationService,
      options,
    );
  }

  private getPaymentManagerByType(
    implementationType: ImplementationType,
    paymentMethodType: PaymentMethodType,
  ) {
    switch (implementationType) {
      case ImplementationType.WEB_REDIRECT: {
        return RedirectPaymentMethodManager;
      }
      case ImplementationType.NATIVE_SDK: {
        if (!SUPPORTED_NATIVE_PAYMENT_METHODS.includes(paymentMethodType)) {
          return null;
        }

        return NativePaymentMethodManager;
      }
      default: {
        throw new Error(
          `Payment method type ${implementationType} is not supported`,
        );
      }
    }
  }
}
