import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { OverlayContentCloseButton } from '../../components/OverlayContentCloseButton';

interface Props {
  src: string;
  onCloseClick?: () => void;
}

const Root = styled.div`
  height: 600px;
  width: 500px;
  position: relative;
  border: none;
  margin: 30px;
`;

const StyledIFrame = styled.iframe`
  background: white;
  position: absolute;
  border: none;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const IFrame: FunctionalComponent<Props> = ({ src, onCloseClick }) => {
  return (
    <Root>
      <OverlayContentCloseButton onCloseClick={onCloseClick} />
      <StyledIFrame data-testId='Overlay.iFrame' src={src}></StyledIFrame>
    </Root>
  );
};
