import {
  createResumeCallbackHandler,
  ResumeCallbackHandler,
  ResumeCallbackHandlerContext,
} from '@primer-io/shared-library/payment-method-utils';

export class PaymentMethodResumePollService {
  private context: ResumeCallbackHandlerContext;

  private statusUrl: string;

  private resumeCallbackHandler: ResumeCallbackHandler | undefined;

  constructor(context: ResumeCallbackHandlerContext, statusUrl: string) {
    this.context = context;

    this.statusUrl = statusUrl;
  }

  async startPolling(pollInterval?: number, timeout?: number) {
    const resumeCallbackHandler = createResumeCallbackHandler(this.context, {
      url: this.statusUrl,
      pollInterval,
      timeout,
    });

    const { promise } = resumeCallbackHandler as ResumeCallbackHandler;

    this.resumeCallbackHandler = resumeCallbackHandler;

    let resumeToken: string | undefined;

    const response = await promise;

    if (response) {
      resumeToken = response.id;
    } else {
      this.resumeCallbackHandler = undefined;

      return undefined;
    }

    this.resumeCallbackHandler = undefined;

    return resumeToken;
  }

  stopPolling() {
    this.resumeCallbackHandler?.stop();
  }
}
