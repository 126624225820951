import Set from '@ungap/set';
import { PaymentFlow } from '../types';
import { base64 } from '../utils/base64';
import InternalError from './InternalError';

export type DecodeClientTokenErrorCode =
  | 'CLIENT_TOKEN_MALFORMED'
  | 'CLIENT_TOKEN_EXPIRED';

export enum ClientTokenIntent {
  CHECKOUT = 'CHECKOUT',
  THREEDS_AUTHENTICATION = '3DS_AUTHENTICATION',
  PROCESSOR_3DS = 'PROCESSOR_3DS',
  REDIRECTION_END = 'REDIRECTION_END',
}

export type CommonClientToken = {
  configurationUrl: string;
  analyticsUrl?: string;
  analyticsUrlV2: string;
  accessToken: string;
  paymentFlow: PaymentFlow;
  exp: number;
};

type IntentClientToken =
  | {
      intent: ClientTokenIntent.CHECKOUT | undefined;
    }
  | {
      intent: ClientTokenIntent.THREEDS_AUTHENTICATION;
      tokenId: string;
      threeDSProvider: string;
      threeDSToken: string;
      threeDSInitUrl: string;
    }
  | {
      intent: ClientTokenIntent.REDIRECTION_END;
      paymentId: string;
      resumeToken: string;
    }
  | ({
      intent: string;
    } & Record<string, unknown>);

export type DecodedClientToken = CommonClientToken & IntentClientToken;

type NewClientTokenListener = (decodedClientToken: DecodedClientToken) => void;

export interface IClientTokenHandler {
  processClientToken(clientToken: string): DecodedClientToken;
  getOriginalClientToken(): string | undefined;
  getCurrentClientToken(): string | undefined;
  getCurrentDecodedClientToken(): DecodedClientToken | undefined;
  addNewClientTokenListener(listener: NewClientTokenListener);
  removeNewClientTokenListener(listener: NewClientTokenListener);
}

export const decodeClientToken = (clientToken: string): DecodedClientToken => {
  const tokens = clientToken.split('.');
  const encoded = tokens.length === 1 ? tokens[0] : tokens[1];

  let decoded = null;

  try {
    decoded = JSON.parse(base64.decode(encoded));
  } catch (e) {
    /* Ignore: `decoded` will be empty */
  }

  if (decoded == null) {
    throw InternalError.fromErrorCode('CLIENT_TOKEN_MALFORMED');
  }

  return decoded as DecodedClientToken;
};

export const throwIfExpired = ({ exp }: DecodedClientToken) => {
  if (exp <= Math.ceil(Date.now() / 1000)) {
    throw InternalError.fromErrorCode('CLIENT_TOKEN_EXPIRED', {
      exp,
      expirationDate: new Date(1651861648 * 1000),
    });
  }
};

export class ClientTokenHandler implements IClientTokenHandler {
  private originalClientToken: string | undefined;

  private newClientTokenListeners: Set<NewClientTokenListener>;

  private currentClientToken: string | undefined;

  private currentDecodedClientToken: DecodedClientToken | undefined;

  constructor() {
    this.newClientTokenListeners = new Set<NewClientTokenListener>();
  }

  private setOriginalClientToken(clientToken: string) {
    this.originalClientToken = clientToken;
  }

  getOriginalClientToken(): string | undefined {
    return this.originalClientToken;
  }

  getCurrentClientToken() {
    return this.currentClientToken;
  }

  getCurrentDecodedClientToken() {
    return this.currentDecodedClientToken;
  }

  processClientToken(clientToken: string) {
    if (!this.originalClientToken) {
      this.setOriginalClientToken(clientToken);
    }

    // Decode
    const decodedClientToken = decodeClientToken(clientToken);

    // Check expiration
    throwIfExpired(decodedClientToken);

    //TODO: validate token signature

    // Return
    this.currentClientToken = clientToken;
    this.currentDecodedClientToken = decodedClientToken;

    this.newClientTokenListeners.forEach((listener) =>
      listener(decodedClientToken),
    );

    return decodedClientToken;
  }

  addNewClientTokenListener(listener: NewClientTokenListener) {
    this.newClientTokenListeners.add(listener);
  }

  removeNewClientTokenListener(listener: NewClientTokenListener) {
    this.newClientTokenListeners.delete(listener);
  }
}

export const createClientTokenHandler = () => new ClientTokenHandler();
