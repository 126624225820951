import { BasePaymentMethod } from '../../payment-methods/BasePaymentMethod';
import {
  EventTypes,
  EventListener,
  HeadlessButtonRenderOptions,
  IHeadlessPaymentMethodButton,
} from '../../types';

export class HeadlessPaymentMethodButton
  implements IHeadlessPaymentMethodButton {
  private containerId: string;

  private eventListeners: Map<EventTypes, EventListener> = new Map();

  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly paymentMethod: BasePaymentMethod) {}

  async render(
    containerId: string,
    options: HeadlessButtonRenderOptions = {},
  ): Promise<void> {
    this.containerId = containerId;

    const container = document.getElementById(containerId);
    if (!container) {
      console.warn(`Container with id ${containerId} not found`);
      return;
    }
    this.paymentMethod.container = container;
    this.paymentMethod.setOption('container', container);
    this.paymentMethod.setOption('onClick', this.handleClick.bind(this));
    if (options.style) {
      Object.entries(options.style).forEach(([key, value]) => {
        this.paymentMethod.setOption(key, value);
      });
    }

    await this.paymentMethod.mount();
    this.subscribeToEvents();
  }

  setDisabled(disabled: boolean): Promise<void> {
    return this.paymentMethod.setDisabled(disabled);
  }

  clean() {
    if (!this.containerId) {
      console.warn('Button is not rendered');
      return;
    }

    document.getElementById(this.containerId)?.remove();
    this.containerId = '';
  }

  blur(): void {
    return this.paymentMethod.blur();
  }

  focus(): void {
    return this.paymentMethod.focus();
  }

  addEventListener(event: EventTypes, callback: EventListener): void {
    this.eventListeners.set(event, callback);
  }

  private subscribeToEvents() {
    const button = this.paymentMethod.getPaymentElement();

    button?.addEventListener('blur', (e) => {
      this.eventListeners.get(EventTypes.BLUR)?.(e);
    });
    button?.addEventListener('focus', (e) => {
      this.eventListeners.get(EventTypes.FOCUS)?.(e);
    });
  }

  private handleClick() {
    this.eventListeners.get(EventTypes.CLICK)?.();
  }
}
