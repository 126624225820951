import { BasePaymentMethodManager } from './BasePaymentMethodManager';
import { CreditCard } from '../../payment-methods/credit-card/CreditCard';
import { CreditCardFieldName } from '../../payment-methods/credit-card/types';
import { HeadlessHostedInput } from './HeadlessHostedInput';
import {
  CardPaymentMethodManagerOptions,
  ICardPaymentMethodManager,
  IHeadlessHostedInput,
  Validation,
} from '../../types';
import { IFrameEventType } from '../../core/IFrameEventType';
import { CardMetadata } from '../../hosted-scripts/CardMetadata';
import { CardTokenizationService } from '../CardTokenizationService';

const threeDSModalId = 'primer-headless-checkout-3ds-modal';
const threeDSModalContent =
  '<div id="primer-checkout-scene-3ds" class="PrimerCheckout__scene--3dsModal PrimerCheckout--hidden"><div id="primer-checkout-3ds-modal" class="PrimerCheckout__modalBody"/></div>';

export class CardPaymentMethodManager
  extends BasePaymentMethodManager
  implements ICardPaymentMethodManager {
  private readonly creditCard: CreditCard;

  private readonly tokenizationService: CardTokenizationService;

  private readonly options?: CardPaymentMethodManagerOptions;

  private cardholderName: string;

  constructor(
    creditCard: CreditCard,
    tokenizationService: CardTokenizationService,
    options?: CardPaymentMethodManagerOptions,
  ) {
    super();

    this.creditCard = creditCard;
    this.tokenizationService = tokenizationService;
    this.options = options;

    creditCard.setCardHolderCallback(() => this.cardholderName);

    this.subscribeToEvents();
    this.add3dsPopup();
  }

  createHostedInputs(): {
    cardNumberInput: IHeadlessHostedInput;
    expiryInput: IHeadlessHostedInput;
    cvvInput: IHeadlessHostedInput;
  } {
    const inputs = {
      cardNumberInput: this.createHostedInput('cardNumber'),
      expiryInput: this.createHostedInput('expiryDate'),
      cvvInput: this.createHostedInput('cvv'),
    };

    this.creditCard.initMessageBus();

    return inputs;
  }

  removeHostedInputs(): void {
    const fields = this.creditCard.getFields();

    Object.values(fields).forEach((field) => {
      field?.frame?.remove();
    });
    this.remove3dsPopup();
  }

  setCardholderName(cardholderName: string): void {
    this.cardholderName = cardholderName;
  }

  submit(): Promise<void> {
    return this.creditCard.tokenize();
  }

  validate(): Promise<Validation> {
    return this.tokenizationService.validateCardForm();
  }

  reset(): void {
    this.creditCard.reset();
  }

  private createHostedInput(name: CreditCardFieldName): IHeadlessHostedInput {
    return new HeadlessHostedInput(this.creditCard, name, {});
  }

  private subscribeToEvents(): void {
    this.creditCard.subscribeToEvent(IFrameEventType.CARD_METADATA, (data) => {
      this.options?.onCardMetadataChange?.(data.payload as CardMetadata);
    });
  }

  private add3dsPopup() {
    const headlessContainer = document.createElement('div');
    headlessContainer.setAttribute('id', threeDSModalId);
    headlessContainer.innerHTML = threeDSModalContent;
    document.body.append(headlessContainer);
  }

  private remove3dsPopup() {
    document.getElementById(threeDSModalId)?.remove();
  }
}
