import { PackageDeclaration } from '../modules/types';
import { IModule, IModuleFactory, IPackage, PackageKey } from './types';

type Props = {
  moduleFactory: IModuleFactory;
  key: PackageKey;
};

export default class Package implements IPackage {
  readonly key: PackageKey;

  private moduleFactory: IModuleFactory;

  private declarationModule: IModule;

  private declarationPromise: Promise<PackageDeclaration>;

  constructor({ moduleFactory, key }: Props) {
    this.key = key;
    this.moduleFactory = moduleFactory;

    this.declarationModule = this.moduleFactory.getModule({
      ...this.key,
      path: 'declaration',
    });
  }

  async getDeclaration() {
    if (!this.declarationPromise) {
      this.declarationPromise = this.declarationModule.import() as Promise<PackageDeclaration>;
    }

    const declaration = await this.declarationPromise;
    return declaration;
  }

  getModule(path: string) {
    return this.moduleFactory.getModule({ ...this.key, path });
  }
}
