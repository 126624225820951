import { BaseStore, IBaseState } from '../../store/BaseStore';

export type Fields = {
  firstName: boolean;
  lastName: boolean;
  addressLine1: boolean;
  addressLine2: boolean;
  city: boolean;
  postalCode: boolean;
  state: boolean;
  countryCode: boolean;
};

interface ShippingAddressState extends IBaseState {
  fields: Fields;
}

const defaultState: ShippingAddressState = {
  fields: {
    firstName: false,
    lastName: false,
    addressLine1: false,
    addressLine2: false,
    city: false,
    postalCode: false,
    state: false,
    countryCode: false,
  },
};

export const ShippingAddressStoreSelector = {
  getFields: (s: ShippingAddressState) => s.fields,
};

export class ShippingAddressStore extends BaseStore<ShippingAddressState> {
  static type = 'SHIPPING_ADDRESS';

  get type() {
    return ShippingAddressStore.type;
  }

  setFields(fields: Fields) {
    this.produceState((draft) => {
      Object.entries(fields).forEach(([key, value]) => {
        draft.fields[key] = value;
      });
    });
  }

  get fields() {
    return ShippingAddressStoreSelector.getFields(this.getState());
  }
}

const createShippingAddressStore = () => new ShippingAddressStore(defaultState);
export default createShippingAddressStore;
