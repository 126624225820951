import { FunctionalComponent } from 'preact';
import {
  useSelector,
  useCheckoutContext,
  useCheckoutStore,
} from '@primer-io/shared-library/contexts';
import VaultedPaymentMethod from '../checkout-modules/vault/components/VaultedPaymentMethod/VaultedPaymentMethod';
import VaultMenu from '../checkout-modules/vault/components/VaultMenu';
import { UniversalCheckoutOptions } from '../types';
import SurchargeVaultedPaymentMethods from '../components/SurchargeVaultedPaymentMethods';
import { PaymentMethodOption } from '../models/ClientSession';
import VaultTitle from '../components/VaultTitle';

export const sceneRootId = 'primer-checkout-vault';

const CheckoutVaultScene: FunctionalComponent = ({ children }) => {
  const store = useCheckoutStore();
  const { options } = useCheckoutContext();

  const vault = useSelector((s) => s.vault);

  const paymentMethodOptions: PaymentMethodOption[] = useSelector(
    (s) => s.clientSession?.paymentMethod?.options as PaymentMethodOption[],
  );

  const currencyCode = useSelector((s) => s.clientSession?.order.currencyCode);

  const labels = useSelector((s) => s.translation);

  const vaultOptions = options as UniversalCheckoutOptions;

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////

  return (
    <div id='primer-checkout-vault-methods'>
      <div
        id={sceneRootId}
        className='PrimerCheckout__sceneElement PrimerCheckout__savedPaymentMethods'
      >
        <div className='PrimerCheckout__savedPaymentMethods__title'>
          <VaultTitle>{labels?.previouslyUsed}</VaultTitle>
          {!vaultOptions.vault?.deletionDisabled && <VaultMenu />}
        </div>
        {(store.hasSurcharge || store.hasCardSurcharge) && (
          <SurchargeVaultedPaymentMethods
            vaultedPaymentMethods={vault.items}
            paymentMethodOptions={paymentMethodOptions}
            currencyCode={currencyCode}
          />
        )}
        {!store.hasSurcharge &&
          !store.hasCardSurcharge &&
          vault.items.map((vaultedItem) => (
            <VaultedPaymentMethod
              key={vaultedItem.id}
              vaultedItem={vaultedItem}
            />
          ))}
      </div>

      {children}

      <div></div>
    </div>
  );
};

export default CheckoutVaultScene;
