import { FunctionalComponent } from 'preact';
import styled, { css } from 'styled-components';
import { useState } from 'preact/hooks';
import { ChevronDown } from 'preact-feather';
import { useLocaleData } from '@primer-sdk-web/utils/hooks';
import { getTextStyle } from '../utils';
import InputContainer from '../InputContainer/InputContainer';
import { useCheckoutContext } from '../../contexts';

interface Props {
  name: string;
  id: string;
  placeholder: string;
  data: SelectOption[];
  hasErrors: boolean;
  disabled?: boolean;
  preselected?: boolean;
  onChange: (value: string | number) => void;
}

interface SelectOption {
  name: string;
  value: string | number;
  selected?: boolean;
}

const getInputCss = (selector) => css`
  ${(p) => ({
    paddingLeft: selector(p)?.paddingHorizontal,
    paddingRight: selector(p)?.paddingHorizontal,
  })}

  ${(p) => getTextStyle(selector(p))}

  &:hover {
    ${(p) => getTextStyle(selector(p)?.hover)}
  }

  &:focus,
  &:focus-within {
    ${(p) => getTextStyle(selector(p)?.focus)}
  }

  &::placeholder {
    ${(p) => getTextStyle(selector(p)?.placeholder)}
  }

  &::selection {
    ${(p) => getTextStyle(selector(p)?.selection)}
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover {
    ${(p) => getTextStyle(selector(p)?.webkitAutofill)}
  }
`;

const BaseInputCss = getInputCss((p) => p.theme.style?.input.base);

const ErrorInputCss = getInputCss((p) => p.theme.style?.input.error);

const StyledSelectContainer = styled.div`
  height: 36px;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: flex-end;
`;

const StyledSelect = styled.select`
  box-sizing: border-box;
  z-index: 1;
  height: 100%;
  width: 100%;
  border: none !important;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ellipsis;
  cursor: pointer;

  & > -ms-expand {
    display: none;
  }

  ${BaseInputCss}
  ${(p) => p.hasErrors && ErrorInputCss}
  ${(p) => !p.isRtlLocale && 'padding-right: 25px !important;'}
`;

const StyledOption = styled.option``;

const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  align-self: center;
  ${(p) => (p.isRtlLocale ? 'padding-right: 10px;' : 'padding-left: 10px;')}
`;

const StyledPlaceholderOption = styled.option``;

const Select: FunctionalComponent<Props> = ({
  name,
  id,
  placeholder,
  data,
  preselected,
  disabled,
  onChange,
  hasErrors,
}) => {
  const [isSelected, setIsSelected] = useState(!!preselected);

  const { viewUtils } = useCheckoutContext();
  const { isRtlLocale } = useLocaleData();

  const InputPlaceholderColor = viewUtils.styleManager.getStyle()?.input?.base
    ?.placeholder?.color;

  const InputTextColor = viewUtils.styleManager.getStyle()?.input?.base?.color;

  const handleSelect = (value) => {
    setIsSelected(true);
    onChange(value);
  };

  return (
    <InputContainer hasErrors={hasErrors}>
      <StyledSelectContainer>
        <StyledSelect
          isRtlLocale={isRtlLocale}
          hasErrors={hasErrors}
          name={name}
          id={id}
          disabled={disabled}
          onChange={(e) => handleSelect(e.target.value)}
          style={{
            color:
              preselected || isSelected
                ? InputTextColor
                : InputPlaceholderColor,
          }}
        >
          {!preselected && (
            <StyledPlaceholderOption value={''} selected disabled hidden>
              {placeholder}
            </StyledPlaceholderOption>
          )}

          {data.map((option) => {
            return (
              <StyledOption
                isRtlLocale={isRtlLocale}
                value={option.value}
                selected={option.selected}
              >
                {option.name}
              </StyledOption>
            );
          })}
        </StyledSelect>
        <StyledChevronDown />
      </StyledSelectContainer>
    </InputContainer>
  );
};

export default Select;
