import bem from 'easy-bem';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h, render } from 'preact';
import {
  StoreProvider,
  CheckoutProvider,
  StyleProvider,
} from '@primer-io/shared-library/contexts';

import { createPaymentMethodContextFactory } from '../../core/PaymentMethodContextFactory';
import {
  PaymentMethodToken,
  PrimerVaultManager,
  SupportedLocale,
  VaultManagerOptions,
} from '../../types';

import { Vault } from '../../checkout-modules/vault/Vault';

import { locateElement } from '../../utils/dom';
import { Translations } from '../../utils/i18n/TranslationFactory';
import { setValue } from '../DomUtilities';
import { ElementID, SceneEnum } from '../../enums/Checkout';
import normalizeOptions from '../../payment-methods/normalizeOptions';

import { PaymentMethods } from '../../payment-methods/PaymentMethods';
import { BasePaymentMethod } from '../../payment-methods/BasePaymentMethod';
import { VaultManagerPaymentMethodConfig } from '../VaultManagerPaymentMethodConfig';
import { IFrameEventType } from '../../core/IFrameEventType';
import { IFrameMessagePayload } from '../../core/IFrameMessage';
import { ClientContext } from '../../core/ClientContext';

import { ErrorCode, PrimerClientError } from '../../errors';
import { createVaultManagerViewUtils } from '../ui/ViewUtils';

import VaultRoot from '../../scenes/VaultRoot';
import { SceneTransition } from '../SceneTransition';
import { getSystemLocale } from '../../utils/getSystemLocale';
import {
  CardPaymentMethodType,
  PaymentInstrumentType,
} from '../../enums/Tokens';
import { CreditCard } from '../../payment-methods/credit-card/CreditCard';
import { teardownUI } from '../Teardown';
import { CallbackHandlers } from '../CallbackHandlers';
import { CardTokenizationService } from '../CardTokenizationService';
import { DeprecationWarningType, logWarning } from '../../utils/consoleWarn';
import { paymentMethodTokenToVaultedPaymentMethod } from '../../checkout-modules/vault/utils';
import GlobalStyle from '../../components/GlobalStyle';
import initializeClientContext from '../ClientContextInitializer';

const renderRoot = ({
  options,
  viewUtils,
  context,
  transitions,
  store,
  root,
}: {
  options;
  viewUtils;
  context?;
  transitions;
  store;
  root;
}) =>
  render(
    <CheckoutProvider
      value={{
        options,
        viewUtils,
        context,
        transitions,
        className: bem('PrimerCheckout'),
      }}
    >
      <StoreProvider value={store}>
        <StyleProvider value={{ style: viewUtils.styleManager.getStyle() }}>
          <GlobalStyle />
          <VaultRoot />
        </StyleProvider>
      </StoreProvider>
    </CheckoutProvider>,
    root,
  );

export class VaultManager {
  static async create(
    context: ClientContext,
    options: VaultManagerOptions,
  ): Promise<PrimerVaultManager> {
    const parent = locateElement(options.container);

    if (!parent) {
      return onError(
        `Attempted to mount vault UI at "${options.container}" but the element could not be found`,
      );
    }

    const root = document.createElement('div');
    parent.insertBefore(root, null);

    ///////////////////////////////////////////
    // Context
    ///////////////////////////////////////////
    await initializeClientContext(context);

    ///////////////////////////////////////////
    // Render
    ///////////////////////////////////////////
    const { store } = context;
    store.setLocale(options.locale ?? (getSystemLocale() as SupportedLocale));

    const viewUtils = createVaultManagerViewUtils(store, options);

    const tokenizationService = new CardTokenizationService(
      store,
      options,
      CardPaymentMethodType.PAYMENT_CARD,
    );

    async function validate() {
      logWarning(DeprecationWarningType.VALIDATE);
      const {
        valid,
        validationErrors,
      } = await tokenizationService.validateCardForm();
      return { valid, validationErrors };
    }

    function teardown() {
      if (context.store.getIsTeardown()) {
        throw new Error('Checkout has already been torn down');
      }

      context.store.setIsTeardown(true);

      teardownUI(root);
    }

    function submit() {
      return store.triggerSubmitButtonClick();
    }

    function tokenize() {
      logWarning(DeprecationWarningType.TOKENIZE);
      return submit();
    }

    const checkout = {
      validate,
      tokenize,
      teardown,
      submit,
    };
    const transitions = new SceneTransition();
    renderRoot({ options, viewUtils, transitions, store, root });

    ///////////////////////////////////////////
    // State
    ///////////////////////////////////////////
    const translation = await Translations.get(options.locale);
    store.setTranslation(translation);

    store.setIsLoading(true);

    if (context.session?.clientSession) {
      store.setClientSession(context.session.clientSession);
    }

    store.produceState((draft) => {
      draft.options.showSavePaymentMethod = false;
      draft.form.inputLabelsVisible = options.form?.inputLabelsVisible ?? true;
    });

    ///////////////////////////////////////////
    // Vault & Payment Methods
    ///////////////////////////////////////////
    const processVault = async () => {
      const vault = new Vault(context);
      store.setVault(vault);
      const vaulted = await vault.fetch();
      vaulted.forEach((pm) => {
        store.addVault(pm);
      });
    };

    const config = VaultManagerPaymentMethodConfig.create(
      context,
      store,
      viewUtils,
      {
        ...options,
        vaultOnly: true,
        onTokenizeError(err: PrimerClientError) {
          switch (err.code) {
            case ErrorCode.DUPLICATE_PAYMENT_METHOD_ERROR:
              store.setErrorMessage(
                store.getTranslations().duplicatePaymentMethodError ??
                  'duplicatePaymentMethodError',
              );

              break;
            default:
              break;
          }

          if (options.onTokenizeError) {
            options.onTokenizeError(err);
          }
        },
        onTokenizeSuccess(data: PaymentMethodToken) {
          options.onTokenizeSuccess?.(data);
          store.addVault(paymentMethodTokenToVaultedPaymentMethod(data));

          // Reset
          if (data.paymentInstrumentType === PaymentInstrumentType.CARD) {
            (store.getPaymentMethodWithType(
              CardPaymentMethodType.PAYMENT_CARD,
            ) as CreditCard).reset();
          }
        },
      },
    );

    const normalizedConfig = normalizeOptions(config);

    const paymentMethodContextFactory = createPaymentMethodContextFactory(
      context,
      normalizedConfig,
      store,
      store,
    );

    let paymentMethods: Record<string, BasePaymentMethod> = {};
    const processPaymentMethods = async () => {
      paymentMethods = await PaymentMethods.create(
        context,
        paymentMethodContextFactory,
        config,
        viewUtils.styleManager,
      );
      store.setPaymentMethods(paymentMethods);
    };
    await Promise.all([processVault(), processPaymentMethods()]);

    ///////////////////////////////////////////
    // Callback Handlers
    ///////////////////////////////////////////

    CallbackHandlers.create(store, context, options);

    ///////////////////////////////////////////
    // End
    ///////////////////////////////////////////
    context.messageBus.on(
      IFrameEventType.CARDHOLDER_NAME,
      (e: IFrameMessagePayload<string>) => {
        setValue(ElementID.CARDHOLDER_NAME_INPUT, e.payload);
      },
    );

    store.setIsLoading(false);
    store.setScene(SceneEnum.MANAGE_PAYMENT_METHODS);

    return checkout;
  }
}

function onError(message: string): PrimerVaultManager {
  window.console.error(message);
  return {
    teardown: () => undefined,
    submit: () => undefined,
  };
}
