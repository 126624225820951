import { useCheckoutStore } from '@primer-io/shared-library/contexts';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { Icons } from '../assets/icons/Icons';

interface Props {
  onCloseClick?: () => void;
}

const StyledCloseButton = styled.button`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  background: #565656;
  border-radius: 20px;

  border: none;
  color: white;
  cursor: pointer;
  z-index: 2000;
`;

export const OverlayContentCloseButton: FunctionalComponent<Props> = ({
  onCloseClick,
}) => {
  const store = useCheckoutStore();
  const labels = store.getTranslations();

  return (
    <StyledCloseButton
      data-testId='OverlayContent.closeButton'
      aria-label={labels?.paymentMethodPopupOverlayClose}
      onClick={onCloseClick}
    >
      {Icons.x}
    </StyledCloseButton>
  );
};
