import { Analytics } from '../analytics/Analytics';
import { InternalFlowOptions } from '../internalTypes';
import { ErrorMonitoring } from '../monitoring/ErrorMonitoring';
import ModuleFactory from '../utils/ModuleFederation/ModuleFactory';
import { ScriptLoader } from '../utils/ScriptLoader';
import { Api } from './Api';
import ClientConfigurationHandler from './ClientConfigurationHandler';

import { IClientTokenHandler } from './ClientTokenHandler';
import { IFrameFactory } from './IFrameFactory';
import { IFrameMessageBus } from './IFrameMessageBus';
import { LongPoll } from './LongPoll';

import CheckoutStore from '../store/CheckoutStore';
import { ClientSessionInfo } from '../models/ClientConfiguration';

export interface ClientContext {
  clientTokenHandler: IClientTokenHandler;
  clientConfigurationHandler: ClientConfigurationHandler;
  iframes: IFrameFactory;
  messageBus: IFrameMessageBus;
  api: Api;
  longPoll: LongPoll;
  analytics: Analytics;
  errorMonitoring: ErrorMonitoring;
  scriptLoader: ScriptLoader;
  session: ClientSessionInfo;
  moduleFactory: ModuleFactory;
  clientOptions: InternalFlowOptions;
  store: CheckoutStore;
}

export default class BaseClientContext implements ClientContext {
  public clientTokenHandler: IClientTokenHandler;

  public clientConfigurationHandler: ClientConfigurationHandler;

  public iframes: IFrameFactory;

  public messageBus: IFrameMessageBus;

  public api: Api;

  public longPoll: LongPoll;

  public analytics: Analytics;

  public errorMonitoring: ErrorMonitoring;

  public scriptLoader: ScriptLoader;

  public moduleFactory: ModuleFactory;

  public clientOptions: InternalFlowOptions;

  public store: CheckoutStore;

  /**
   * @deprecated use context.store instead
   */
  public get session(): ClientSessionInfo {
    const session = this.store.getState().clientSessionInfo;
    if (!session) {
      throw new Error('Client Context Session does not exist');
    }

    return session;
  }
}
