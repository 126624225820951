import { ErrorCode, PrimerClientError } from '../../errors';
import { iosInputBlurFix } from '../../utils/iosInputBlurFix';
import { nextTick } from '../../utils/nextTick';
import { CardMetadata } from '../../hosted-scripts/CardMetadata';
import { IFrameMessagePayload } from '../../core/IFrameMessage';
import { defaultFieldMetadata } from '../../utils/field-metadata';
import { funcify } from '../../utils/funcify';
import { HTMLElementEventType } from '../../enums/HTMLElementEventType';
import { noop } from '../../utils/noop';
import { addEventListener } from '../../utils/addEventListener';
import { IFrameEventType } from '../../core/IFrameEventType';
import { setElementDisabled } from '../../utils/disableElement';
import { IPaymentMethodContext } from '../../core/PaymentMethodContext';
import { InputMetadata, InputValidationError, Validation } from '../../types';
import {
  CardFieldRef,
  CreditCardFieldName,
  CreditCardFieldOptions,
  CreditCardOptions,
  CreditCardOptionsIn,
  FormMeta,
} from './types';
import { ApiEvent } from '../../analytics/constants/enums';
import { CardFieldType, CreditCardStore } from './CreditCardStore';

export class CardFormManager {
  context: IPaymentMethodContext;

  private options: CreditCardOptions;

  fields: Record<CreditCardFieldName, Nullable<CardFieldRef>>;

  store: CreditCardStore;

  private isSubmitted: boolean;

  constructor(
    store: CreditCardStore,
    fields: Record<CreditCardFieldName, Nullable<CardFieldRef>>,
    options: CreditCardOptions,
    context: IPaymentMethodContext,
  ) {
    this.isSubmitted = false;
    this.options = options;
    this.fields = fields;
    this.context = context;
    this.store = store;
  }

  async mountCardForm() {
    const { fields } = this.options;

    this.initMessageBus();

    await Promise.all(
      Object.keys(this.fields).map((key) => {
        return nextTick(() =>
          this.createField(key as CreditCardFieldName, fields[key]),
        );
      }),
    );
  }

  async createField(
    name: CreditCardFieldName,
    config: CreditCardFieldOptions,
  ): Promise<void> {
    if (config == null) {
      return;
    }
    const field: CardFieldRef = {
      name: config.name,
      meta: { ...defaultFieldMetadata },
      onChange: (data) => {
        const { meta } = data;
        config.onChange?.(data);

        const hasError =
          Boolean(meta.submitted && meta.error) ||
          meta.errorCode === 'unsupportedCardType';

        this.store.setFieldError(name, hasError ? meta.error : null);
        this.store.setFieldFocused(name, meta.active);
      },
      frame: null,
    };

    this.fields[name] = field;

    const createIFrameOptions = {
      filename: 'hosted-input.html',
      container: config.container,
      placement: config.placement || 'append',
      meta: {
        id: config.id,
        name: config.name,
        placeholder: config.placeholder,
        css: this.options.css,
        style: config.style,
        stylesheets: this.options.stylesheets,
        ariaLabel: config.ariaLabel,
        allowedCardNetworks: this.context.allowedCardNetworks,
      },
    };

    await new Promise<void>((resolve) => {
      field.frame = this.context.iframes.create({
        ...createIFrameOptions,
        onReady: resolve,
      });
    });

    addEventListener(field.frame, HTMLElementEventType.FOCUS, () => {
      this.context.messageBus.publish(field.name, {
        type: IFrameEventType.SET_FOCUSED,
      });
    });
  }

  async submitAndValidateCardForm(): Promise<boolean> {
    this.isSubmitted = true;

    const validation = await this.internalValidate();

    this.updateFields();

    if (this.options.disabled()) {
      this.context.progress.error('Disabled');
      return false;
    }

    if (!validation.valid) {
      this.context.progress.error(
        PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, {
          message: 'Form is invalid',
          data: validation,
        }),
      );
    }
    return validation.valid;
  }

  reset() {
    this.isSubmitted = false;

    // Reset hosted fields
    Object.keys(this.fields).forEach((name) => {
      this.context.messageBus.publish(
        this.store.getInputName(name as CardFieldType),
        {
          type: IFrameEventType.RESET_FIELD,
        },
      );
    });

    // Reset name input
    const nameInput = document.getElementById(
      this.store.getInputId('cardholderName'),
    ) as HTMLInputElement;
    if (nameInput) {
      nameInput.value = '';
    }
  }

  async validate(): Promise<Validation> {
    const validationResult = await this.internalValidate();

    if (validationResult.valid) {
      this.context.analytics.callV1({
        event: ApiEvent.creditCardValidationSuccess,
      });
    } else {
      const data = validationResult.validationErrors.reduce((acc, cur) => {
        acc[cur.name] = cur.error.toString();
        return acc;
      }, {});

      this.context.analytics.callV1({
        event: ApiEvent.creditCardValidationError,
        data,
      });
    }

    return validationResult;
  }

  internalValidate(): Promise<Validation> {
    this.isSubmitted = true;

    this.updateFields();

    const validationErrors = this.getValidationErrors();

    return Promise.resolve({
      valid: validationErrors.length === 0,
      validationErrors,
    });
  }

  async setDisabled(disabled: boolean): Promise<void> {
    this.eachField((field) => {
      setElementDisabled(field.frame, disabled);
      this.context.messageBus.publish(field.name, {
        type: IFrameEventType.SET_DISABLED,
        payload: {
          disabled,
        },
      });
    });

    return Promise.resolve();
  }

  setSubmitted() {
    this.isSubmitted = true;
  }

  resetFormSubmission() {
    this.isSubmitted = false;
    this.updateFields();
  }

  private eachField(iterator: (field: CardFieldRef) => void): void {
    Object.values(this.fields).forEach((field) => {
      if (field !== null) {
        iterator(field);
      }
    });
  }

  private getValidationErrors(): InputValidationError[] {
    const errors: InputValidationError[] = [];

    this.eachField((field) => {
      if (field.meta.error) {
        errors.push({
          name: field.name,
          error: field.meta.error,
          message: this.context.translations[field.meta.error],
        });
      }
    });

    return errors;
  }

  updateFields(fields?: CardFieldRef[]) {
    const targets = fields || Object.values(this.fields);
    const formState = this.deriveFormState();

    this.options.onChange(formState);

    targets.forEach((field) => {
      if (!field) {
        return;
      }

      if (!field.meta.submitted && this.isSubmitted) {
        this.context.messageBus.publish(field.name, {
          type: IFrameEventType.SET_SUBMITTED,
          payload: true,
        });
      }

      const meta = { ...field.meta, submitted: this.isSubmitted };

      meta.errorCode = meta.error;
      meta.error = meta.error ? this.context.translations[meta.error] : null;

      field.onChange({ meta });
    });
  }

  setCardHolderCallback(callback: () => string) {
    this.options.cardholderName = callback;
  }

  initMessageBus() {
    this.context.messageBus.on(IFrameEventType.SET_SUBMITTED, () => {
      this.context.store.triggerSubmitButtonClick();
    });

    this.context.messageBus.on(
      IFrameEventType.INPUT_METADATA,
      (e: IFrameMessagePayload<InputMetadata>) => {
        const { source } = e.meta;
        const fieldName = source.split('-')[0];
        if (this.store.isRelevantInputEvent(source)) {
          const field = this.fields[fieldName];

          field.meta = e.payload;

          this.updateFields([field]);
        }
      },
    );

    this.context.messageBus.on(
      IFrameEventType.CARD_METADATA,
      (e: IFrameMessagePayload<CardMetadata>) => {
        if (e.meta.source === this.store.getInputName('cardNumber')) {
          this.options.onCardMetadata(e.payload);

          this.store.setMetadata(e.payload);
        }
      },
    );

    this.context.messageBus.on(IFrameEventType.IOS_BLUR_FIX, (e) => {
      const { source } = e.meta;
      const field = this.fields[source];

      if (field == null) {
        return;
      }

      iosInputBlurFix(field.frame);
    });
  }

  private deriveFormState(): FormMeta {
    const allFields = Object.values(this.fields);

    const state = allFields.reduce((acc, elm) => {
      if (elm == null) {
        return acc;
      }

      return {
        dirty: some(acc as InputMetadata, elm.meta, 'dirty'),
        touched: some(acc as InputMetadata, elm.meta, 'touched'),
        active: some(acc as InputMetadata, elm.meta, 'active'),
        valid: every(acc as InputMetadata, elm.meta, 'valid'),
      };
    }, {});

    const derivedState = state as Pick<
      InputMetadata,
      'dirty' | 'touched' | 'active' | 'valid'
    >;

    return { ...derivedState, submitted: this.isSubmitted };
  }

  /**
   *
   * @param {Primer.CreditCardConfig} opts
   */
  normalizeOptions(opts: CreditCardOptionsIn): CreditCardOptions {
    return {
      ...opts,
      cardholderName: funcify(opts.cardholderName),
      vault: funcify(opts.vault ?? false),
      onChange: opts.onChange || noop,
      onCardMetadata: opts.onCardMetadata || noop,
      disabled: opts.disabled || funcify(false),
    };
  }
}

function some(
  a: InputMetadata,
  b: InputMetadata,
  name: 'dirty' | 'touched' | 'active' | 'valid',
): boolean {
  return a[name] || b[name];
}

function every(
  a: InputMetadata,
  b: InputMetadata,
  name: 'dirty' | 'touched' | 'active' | 'valid',
): boolean {
  return a[name] && b[name];
}

const createCardFormManager = (store, fields, options, context) =>
  new CardFormManager(store, fields, options, context);
export default createCardFormManager;
