import { getSystemLocale } from '../getSystemLocale';

import { createTranslation, TranslationUnit } from './Translation';

import en from '../../assets/translations/en.json';

export const allTranslations: Record<string, any> = {
  ar: () => import('../../assets/translations/ar.json'),
  bg: () => import('../../assets/translations/bg.json'),
  cs: () => import('../../assets/translations/cs.json'),
  da: () => import('../../assets/translations/da.json'),
  de: () => import('../../assets/translations/de.json'),
  el: () => import('../../assets/translations/el.json'),
  en: () => en,
  es: () => import('../../assets/translations/es.json'),
  esAR: () => import('../../assets/translations/es-AR.json'),
  etEE: () => import('../../assets/translations/et-EE.json'),
  fr: () => import('../../assets/translations/fr.json'),
  hr: () => import('../../assets/translations/hr.json'),
  hu: () => import('../../assets/translations/hu.json'),
  it: () => import('../../assets/translations/it.json'),
  ja: () => import('../../assets/translations/ja.json'),
  lt: () => import('../../assets/translations/lt.json'),
  nb: () => import('../../assets/translations/nb.json'),
  nl: () => import('../../assets/translations/nl.json'),
  pl: () => import('../../assets/translations/pl.json'),
  ptBR: () => import('../../assets/translations/pt-BR.json'),
  pt: () => import('../../assets/translations/pt.json'),
  ro: () => import('../../assets/translations/ro.json'),
  ru: () => import('../../assets/translations/ru.json'),
  sk: () => import('../../assets/translations/sk.json'),
  sv: () => import('../../assets/translations/sv.json'),
  tr: () => import('../../assets/translations/tr.json'),
  vi: () => import('../../assets/translations/vi.json'),
};

export const defaultTranslation = createTranslation('en', en);

export const Translations = {
  getDefault(): TranslationUnit {
    return defaultTranslation;
  },

  async get(
    twoOrFiveCharCode: string = getSystemLocale(),
  ): Promise<TranslationUnit> {
    return getByCode(twoOrFiveCharCode.replace('_', '').replace('-', ''));
  },
};

async function getByCode(twoOrFourDigitCode: string): Promise<TranslationUnit> {
  const code = twoOrFourDigitCode.slice(0, 2);
  if (code === 'en') {
    return defaultTranslation;
  }

  try {
    if (
      allTranslations[twoOrFourDigitCode] &&
      twoOrFourDigitCode.length === 4
    ) {
      const unit = await allTranslations[twoOrFourDigitCode]();
      return createTranslation(twoOrFourDigitCode, unit, defaultTranslation);
    }

    if (allTranslations[code]) {
      const unit = await allTranslations[code]();
      return createTranslation(code, unit, defaultTranslation);
    }
  } catch (e) {
    console.warn(
      `Failed to load locale "${twoOrFourDigitCode}" due to a Network error. Fallback to English`,
      e,
    );
    return defaultTranslation;
  }

  console.warn(
    `Unsupported locale "${twoOrFourDigitCode}". Fallback to English`,
  );
  return defaultTranslation;
}
