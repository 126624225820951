import { CardMetadata } from '../../hosted-scripts/CardMetadata';
import { BaseStore, IBaseState } from '../../store/BaseStore';

type FieldState = {
  error?: string;
  id: string;
  name: string;
  isFocused: boolean;
  isEnabled: boolean;
};
interface State extends IBaseState {
  cardNumber: FieldState;
  expiryDate: FieldState;
  cvv: FieldState;
  cardholderName: FieldState;

  metadata: CardMetadata;
}

export type CardFieldType =
  | 'cardNumber'
  | 'expiryDate'
  | 'cvv'
  | 'cardholderName';

const getDefaultFieldState = () => ({
  id: '',
  name: '',
  error: undefined,
  isFocused: false,
  isEnabled: false,
});

const defaultState: State = {
  cardNumber: getDefaultFieldState(),
  expiryDate: getDefaultFieldState(),
  cvv: getDefaultFieldState(),
  cardholderName: getDefaultFieldState(),

  metadata: {
    cvvLength: 3,
    type: null,
    possibleTypes: [],
    cardNumberLength: 0,
  },
};

export const CreditCardStoreSelector = {
  getNumberFieldError: (s: State) => s.cardNumber.error,
  getNumberFieldFocused: (s: State) => s.cardNumber.isFocused,
  getNumberInputId: (s: State) => s.cardNumber.id,

  getCvvFieldError: (s: State) => s.cvv.error,
  getCvvFieldFocused: (s: State) => s.cvv.isFocused,
  getCvvInputId: (s: State) => s.cvv.id,
  getIsCvvFieldEnabled: (s: State) => s.cvv.isEnabled,

  getExpiryDateFieldError: (s: State) => s.expiryDate.error,
  getExpiryDateFieldFocused: (s: State) => s.expiryDate.isFocused,
  getExpiryDateInputId: (s: State) => s.expiryDate.id,

  getCardholderNameFieldError: (s: State) => s.cardholderName.error,
  getCardholderNameFieldFocused: (s: State) => s.cardholderName.isFocused,
  getCardholderNameInputId: (s: State) => s.cardholderName.id,

  getMetadata: (s: State) => s.metadata,
  getCardNetwork: (s: State) => s.metadata.type,
  getInputId: (s: State, cardFieldType: CardFieldType) => {
    return s[cardFieldType].id;
  },
  getInputName: (s: State, cardFieldType: CardFieldType) => {
    return s[cardFieldType].name;
  },
};

export class CreditCardStore extends BaseStore<State> {
  setFieldError(cardFieldType: CardFieldType, error?: string | null) {
    this.produceState((draft) => {
      draft[cardFieldType].error = error ?? undefined;
    });
  }

  setFieldFocused(cardFieldType: CardFieldType, isFocused: boolean) {
    this.produceState((draft) => {
      draft[cardFieldType].isFocused = isFocused;
    });
  }

  setFieldEnabled(cardFieldType: CardFieldType) {
    this.produceState((draft) => {
      draft[cardFieldType].isEnabled = true;
    });
  }

  async setInputId(cardFieldType: CardFieldType, id: string): Promise<void> {
    this.produceState((draft) => {
      draft[cardFieldType].id = id;
    });
  }

  async setInputName(
    cardFieldType: CardFieldType,
    name: string,
  ): Promise<void> {
    this.produceState((draft) => {
      draft[cardFieldType].name = name;
    });
  }

  setMetadata(metadata: CardMetadata) {
    this.produceState((draft) => {
      draft.metadata = metadata;
    });
  }

  get currentCardNetwork(): string | null {
    return CreditCardStoreSelector.getCardNetwork(this.getState());
  }

  getInputId(name: CardFieldType): string {
    return CreditCardStoreSelector.getInputId(this.getState(), name);
  }

  getInputName(name: CardFieldType): string {
    return CreditCardStoreSelector.getInputName(this.getState(), name);
  }

  // This is used once there's an additional card form to which
  // specific events should apply
  // COMMENT WILL BE REMOVED IN NEXT PR
  isRelevantInputEvent(eventSource: string): boolean {
    const cardFieldType = eventSource.split('-')[0];
    return (
      CreditCardStoreSelector.getInputName(
        this.getState(),
        cardFieldType as CardFieldType,
      ) === eventSource
    );
  }
}

const createCreditCardStore = () => new CreditCardStore(defaultState);
export default createCreditCardStore;
