import {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'preact/hooks';
import {
  useCheckoutContext,
  useCheckoutStore,
  useSelector,
  useSceneLoaderContext,
  useCheckoutModule,
} from '@primer-io/shared-library/contexts';

import { CardPaymentMethodType } from '../enums/Tokens';
import { SavePaymentMethodStore } from '../checkout-modules/save-payment-method';
import { useBillingAddressForm } from '../checkout-modules/billing-address';

import { getFirstFocusableChild } from '../checkout/focus';
import CheckoutVaultScene from './CheckoutVaultScene';
import { delay } from '../utils/delay';
import CheckoutPaymentScene from './CheckoutPaymentScene';
import {
  useSceneEntering,
  useScene,
  useSceneMounting,
  useSceneState,
  useDisableComponent,
} from '../utils/hooks';
import { SceneEnum } from '../enums/Checkout';
import { SceneStage } from '../store/BaseStore';
import {
  useWait,
  WaitForComponents,
} from '../utils/SceneLoader/WaitForComponents';
import { Environment } from '../utils/Environment';
import Dim from '../components/Dim';
import { CardTokenizationService } from '../checkout/CardTokenizationService';
import ShowPaymentMethodsButton from '../components/ShowPaymentMethodsButton';
import { PaymentMethodType } from '../types';
import { useCardDescriptionForm } from '../checkout-modules/save-payment-method/form';

const SCENE = SceneEnum.CHOOSE_PAYMENT_METHOD;

export const sceneRootId = 'primer-checkout-scene-checkout';

const CheckoutScene = () => {
  const store = useCheckoutStore();
  const {
    vaultPaymentTokenizationService,
    viewUtils,
    options,
    clientSessionActionService,
  } = useCheckoutContext();
  const tokenizationService = new CardTokenizationService(
    store,
    options,
    CardPaymentMethodType.PAYMENT_CARD,
  );
  const sceneState = useSceneState(SCENE);

  const labels = useSelector((s) => s.translation);
  const vault = useSelector((s) => s.vault);
  const { disableControls } = useDisableComponent({ scene: SCENE });

  const [isShowingOtherWaysToPay, setIsShowingOtherWaysToPay] = useState(false);

  const cardBillingAddressForm = useBillingAddressForm();

  const cardDescriptionForm = useCardDescriptionForm();

  const savePaymentMethod = useCheckoutModule<SavePaymentMethodStore>(
    'SAVE_PAYMENT_METHOD',
  );

  const otherPaymentMethodsRef = useRef<HTMLDivElement>();

  const hasCreditCardScene = useSelector(
    (s) => s.card.flow === 'DEDICATED_SCENE',
  );

  const paymentMethods = useSelector((s) => s.paymentMethods);
  const paymentMethodsWithoutCard = useMemo(() => {
    return Object.entries(paymentMethods).filter(
      ([key]) => key !== PaymentMethodType.PAYMENT_CARD,
    );
  }, [paymentMethods]);

  const { hasVault, hasCard } = store;
  const hasOnlyCard =
    Object.keys(store.getPaymentMethods()).length === 1 && hasCard;
  const hasSurchage = store.hasSurcharge || store.hasCardSurcharge;

  const canShowCardButton =
    (hasOnlyCard && hasVault && hasSurchage) ||
    (hasCreditCardScene && !hasOnlyCard) ||
    (!hasCreditCardScene && !hasOnlyCard && hasSurchage);

  const showOtherWaysToPay = (animated: boolean) =>
    new Promise<void>((resolve) => {
      if (isShowingOtherWaysToPay) {
        resolve();
        return;
      }
      setIsShowingOtherWaysToPay(true);

      if (
        (store.hasVault && !store.hasCard) ||
        (store.hasCreditCardScene && !hasOnlyCard)
      ) {
        store.setSubmitButtonDisabled(true);
      }
      clientSessionActionService?.unselectPaymentMethod(false);
      store.selectVault(null);

      const el = document.getElementById(
        'primer-checkout-other-payment-methods',
      ) as HTMLElement;

      viewUtils.toggleVisibilityAnimated(
        'primer-checkout-other-payment-methods',
        true,
        {
          animateHeight: true,
          autoHeight: true,
          duration: animated ? 500 : 0,
          onFinish: () => {
            if (viewUtils.styleManager.getStyle()?.focusCheckoutOnInit) {
              getFirstFocusableChild(el)?.focus();
            }

            resolve();
          },
        },
      );

      // Accessibility
      if (
        !Environment.get('PRIMER_BUILD_INTEGRATION_BUILDER', false) &&
        viewUtils.styleManager.getStyle()?.focusCheckoutOnInit
      ) {
        setTimeout(() => {
          getFirstFocusableChild(el)?.focus();
        }, 300);
      }
    });

  ///////////////////////////////////////////
  // Side effects
  ///////////////////////////////////////////
  const { setIsMounted } = useSceneLoaderContext();

  const { ready } = useWait(() => {
    setIsMounted();
  }, ['components', 'animation']);

  useSceneMounting(() => {
    (async () => {
      if (!store.hasVault) {
        viewUtils.toggleVisibilityAnimated(
          'primer-checkout-vault-methods',
          false,
          {
            duration: 0,
            animateHeight: true,
            autoHeight: true,
          },
        );

        await showOtherWaysToPay(false);
      }

      ready('animation');
    })();
  }, SCENE);

  useEffect(() => {
    const submitButtonDisabled =
      cardBillingAddressForm?.isSendingBillingAddress ?? false;

    store.setSubmitButtonDisabled(submitButtonDisabled);
  }, [cardBillingAddressForm?.isSendingBillingAddress]);

  useLayoutEffect(() => {
    (async () => {
      if (sceneState?.stage === SceneStage.Entered && !store.hasVault) {
        if (!store.hasVault) {
          viewUtils.toggleVisibilityAnimated(
            'primer-checkout-vault-methods',
            false,
            {
              duration: 500,
              animateHeight: true,
              autoHeight: true,
            },
          );

          await delay(300);

          showOtherWaysToPay(true);
        }
      }
    })();
  }, [sceneState, vault]);

  useSceneEntering(() => {
    if (store.hasVault) {
      store.setSubmitButtonVisible(true);
      store.setSubmitButtonContent(labels?.pay ?? '[PAY]');
      store.setSubmitButtonCanDisplayAmount(true);
    } else if (store.hasCard) {
      if (!canShowCardButton) {
        store.setSubmitButtonDisabled(false);
        store.setSubmitButtonVisible(true);
        store.setSubmitButtonContent(labels?.pay ?? '[PAY]');
        store.setSubmitButtonCanDisplayAmount(true);
      } else {
        store.setSubmitButtonVisible(false);
      }
    } else {
      store.setSubmitButtonVisible(false);
    }
  }, SCENE);

  useScene(() => {
    const handleSubmitButtonClick = async () => {
      const token = store.getSelectedVaultToken();

      if (token) {
        vaultPaymentTokenizationService?.tokenize(token);
      } else {
        await tokenizationService.validateCardForm();
        const submitBillingAddressForm = cardBillingAddressForm?.submitForm();
        const submitCardDescriptionform = cardDescriptionForm?.submitForm();

        if (
          submitCardDescriptionform &&
          submitCardDescriptionform.data.cardDescription.value
        ) {
          savePaymentMethod?.setUserDescription(
            submitCardDescriptionform.data.cardDescription.value as string,
          );
        }

        if (
          submitBillingAddressForm &&
          submitBillingAddressForm.isValid === false
        ) {
          return;
        }

        await tokenizationService.tokenizeCard();
      }
    };

    store.addSubmitButtonClickListener(handleSubmitButtonClick);
    return () => {
      store.removeSubmitButtonClickListener(handleSubmitButtonClick);
    };
  }, SCENE);

  useSceneEntering(() => {
    if (store.hasVault) {
      store.setSubmitButtonDisabled(!store.getSelectedVaultToken());

      store.setSubmitButtonVisible(true);
      store.setSubmitButtonContent(labels?.pay ?? '[PAY]');
    } else if (store.hasCard) {
      if (store.hasVault || (hasCreditCardScene && !hasOnlyCard)) {
        store.setSubmitButtonDisabled(true);
      } else {
        store.setSubmitButtonDisabled(false);
      }

      if (!canShowCardButton) {
        store.setSubmitButtonVisible(true);
        store.setSubmitButtonContent(labels?.pay ?? '[PAY]');
      } else {
        store.setSubmitButtonVisible(false);
      }
    } else {
      store.setSubmitButtonVisible(false);
    }
  }, SCENE);

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////

  return (
    <WaitForComponents
      onReady={() => {
        ready('components');
      }}
    >
      <Dim dim={disableControls}>
        <div
          id={sceneRootId}
          className='PrimerCheckout__scene PrimerCheckout__scene--savedPaymentMethods PrimerCheckout--exited'
        >
          <CheckoutVaultScene>
            <ShowPaymentMethodsButton
              aria-label={
                !isShowingOtherWaysToPay
                  ? labels?.navigateToPaymentMethods
                  : labels?.otherWaysToPay
              }
              disabled={isShowingOtherWaysToPay}
              onClick={() => showOtherWaysToPay(true)}
            >
              {!isShowingOtherWaysToPay
                ? labels?.navigateToPaymentMethods
                : labels?.otherWaysToPay}
            </ShowPaymentMethodsButton>
          </CheckoutVaultScene>
          <CheckoutPaymentScene
            canShowCardButton={canShowCardButton}
            otherPaymentMethodsRef={otherPaymentMethodsRef}
            cardBillingAddressForm={cardBillingAddressForm}
            cardDescriptionForm={cardDescriptionForm}
            hasCard={hasCard}
            hasCreditCardScene={hasCreditCardScene}
            paymentMethodsWithoutCard={paymentMethodsWithoutCard}
          />
        </div>
      </Dim>
    </WaitForComponents>
  );
};

export default CheckoutScene;
