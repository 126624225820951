export enum SceneEnum {
  LOADING = 'Loading',
  THREE_DS = 'UniversalCheckout/3ds',
  CHOOSE_PAYMENT_METHOD = 'UniversalCheckout/Home',
  MANAGE_PAYMENT_METHODS = 'VaultManager/Home',
  DIRECT_DEBIT_FORM = 'DirectDebit/Form',
  DIRECT_DEBIT_MANDATE = 'DirectDebit/Mandate',
  CREDIT_CARD_FORM = 'CreditCard/Form',
  SUCCESS_PAYMENT_METHOD = 'UniversalCheckout/Success/PaymentMethod',
  SUCCESS_CHECK = 'UniversalCheckout/Success/Check',
}
export type SceneId = SceneEnum | string;

export enum ElementID {
  ROOT = 'primer-checkout',
  ROOT_CONTENT = 'primer-checkout-content',
  SUBMIT_BUTTON = 'primer-checkout-submit-button',
  THREE_DS_MODAL = 'primer-checkout-3ds-modal',
  VAULT = 'primer-checkout-vault',
  VAULT_INLINE = 'primer-checkout-vault-inline',
  NAVIGATE_PAYMENT_METHODS = 'primer-checkout-navigate-to-payment-methods',
  DIVIDER = 'primer-checkout-payment-method-divider',
  APM_BUTTONS = 'primer-checkout-apm-button-container',
  CARDHOLDER_NAME = 'primer-checkout-cardholder-name',
  CARDHOLDER_NAME_INPUT = 'primer-checkout-cardholder-name-input',
  ACTIONS = 'primer-checkout-actions',
  ACTIONS_MENU = 'primer-checkout-actions-menu',
  SAVE_PAYMENT_METHOD_CHECKBOX = 'primer-checkout-save-payment-method-input',
  SAVE_PAYMENT_METHOD_FIELD = 'primer-checkout-save-payment-method-field',
  SAVED_PAYMENT_METHODS_EMPTY = 'primer-checkout-saved-payment-methods-empty-text',
  CARD_FORM = 'primer-checkout-card-form',
  DD_SWITCH = 'primer-checkout-switch-dd-inputs',
  DD_IBAN_FIELD = 'primer-checkout-dd-iban-field',
  DD_LOCAL_FIELD = 'primer-checkout-dd-local-fields',
  DD_CUSTOMER_NAME_INPUT = 'primer-checkout-dd-customer-name-input',
  DD_CUSTOMER_EMAIL_INPUT = 'primer-checkout-dd-customer-email-input',
  DD_CUSTOMER_ADDRESS_LINE1 = 'primer-checkout-dd-customer-address-input',
  DD_CUSTOMER_ADDRESS_LINE2 = 'primer-checkout-dd-customer-address-line2-input',
  DD_CUSTOMER_ADDRESS_CITY = 'primer-checkout-dd-customer-city-input',
  DD_CUSTOMER_ADDRESS_POSTAL_CODE = 'primer-checkout-dd-customer-postal-code-input',
  DD_IBAN_INPUT = 'primer-checkout-dd-iban-input',
  DD_ACCOUNT_NUMBER_INPUT = 'primer-checkout-dd-account-number-input',
  DD_BANK_CODE_INPUT = 'primer-checkout-dd-bank-code-input',
  DD_BRANCH_CODE_INPUT = 'primer-checkout-dd-branch-code-input',
  DD_MANDATE_SMALL_PRINT = 'primer-checkout-small-print',
  ERROR_MESSAGE = 'primer-checkout-error-message',
}

export enum ClassName {
  TOKEN = 'PrimerCheckout__token',
  HIDDEN = 'PrimerCheckout--hidden',
  FOCUSED = 'PrimerCheckout--focused',
  SELECTED = 'PrimerCheckout--selected',
  LOADING = 'PrimerCheckout--loading',
  ERROR = 'PrimerCheckout--error',
  CARD_TYPE = 'PrimerCheckout__cardType',
  SAVED_PAYMENT_METHOD = 'PrimerCheckout__savedPaymentMethod',
  SAVED_PAYMENT_METHOD_CONTAINER = 'PrimerCheckout__savedPaymentMethodContainer',
  APM_BUTTON = 'PrimerCheckout__apmButton',
  EDIT_BUTTON = 'PrimerCheckout__editButton',
  MENU = 'PrimerCheckout__dropDownMenu',
  MENU_ITEM = 'PrimerCheckout__dropDownMenuItem',
  PAYMENT_METHOD_MENU_ITEM = 'PrimerCheckout__paymentMethodDropDownMenuItem',
  DELETE_PAYMENT_METHOD_BUTTON = 'PrimerCheckout__paymentMethodDropDownMenuItem__deleteButton',
  ENTER = 'PrimerCheckout--enter',
  ENTERING = 'PrimerCheckout--entering',
  ENTERED = 'PrimerCheckout--entered',
  EXIT = 'PrimerCheckout--exit',
  EXITING = 'PrimerCheckout--exiting',
  EXITED = 'PrimerCheckout--exited',
}

export enum CheckoutUXFlow {
  CHECKOUT = 'CHECKOUT',
  EXPRESS_CHECKOUT = 'EXPRESS_CHECKOUT',
  HEADLESS_CHECKOUT = 'HEADLESS_CHECKOUT',
  MANAGE_PAYMENT_METHODS = 'MANAGE_PAYMENT_METHODS',
  SINGLE_PAYMENT_METHOD_CHECKOUT = 'SINGLE_PAYMENT_METHOD_CHECKOUT',
}
