import { IAnalyticsProvider } from './providers/BaseAnalyticsProvider';
import { AnalyticsV2Provider } from './providers/AnalyticsV2Provider';
import { MockAnalyticsProvider } from './providers/MockAnalyticsProvider';
import {
  AnalyticEventProperties,
  AnalyticsEventTypes,
  AnalyticsAction,
  IAnalyticsOptions,
} from './models';
import { BaseAnalyticsModule, EmptyModule } from './modules';
import { Api } from '../core/Api';

const modules = [EmptyModule];

export class Analytics {
  private provider: IAnalyticsProvider;

  private modules: BaseAnalyticsModule[] = [];

  constructor(provider: IAnalyticsProvider) {
    this.provider = provider;
    this.initModules();
  }

  private initModules(): void {
    this.modules = modules.map((Module) => new Module({ analytics: this }));
  }

  initProvider(api: Api, options: IAnalyticsOptions): void {
    this.provider.initProvider(api, options);
  }

  getModuleByType(type: AnalyticsEventTypes): Nullable<BaseAnalyticsModule> {
    return this.modules.find((module) => module.type === type) || null;
  }

  /** track event and send to Primer. */
  async trackEvent(
    eventType: AnalyticsEventTypes,
    payload: AnalyticEventProperties,
  ): Promise<void | boolean> {
    return this.provider.trackEvent(eventType, payload);
  }

  /**
   * @deprecated The method should be removed in the next PR
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPaymentIntent(_paymentIntent: string): void {}

  /**
   * @deprecated The method should be removed in the next PR
   */
  callV1(action: AnalyticsAction): void {
    this.trackEvent(AnalyticsEventTypes.V1_EVENT, { action });
  }
}

export function createAnalytics(url?: string): Analytics {
  const provider = url
    ? new AnalyticsV2Provider(url)
    : new MockAnalyticsProvider();

  return new Analytics(provider);
}
