import { delay } from '@primer-sdk-web/utils/delay';
import { AnalyticsData } from '@primer-sdk-web/analytics/models/AnalyticsAction';

import toCamelCase from '@primer-sdk-web/utils/toCamelCase';
import { isMobile } from '@primer-sdk-web/utils/browser';
import { getBrowserInfo } from '@primer-sdk-web/utils/browserInfo';
import { ImplementationType } from '@primer-sdk-web/payment-methods/payment-method-implementations/types';
import { PaymentMethodType } from '../../primer-sdk-web/src/enums/Tokens';
import { IPaymentMethodContext } from '../../primer-sdk-web/src/core/PaymentMethodContext';
import {
  createPopupService,
  PopupService,
} from '../../primer-sdk-web/src/core/PopupService/PopupService';
import { LongPoll } from '../../primer-sdk-web/src/core/LongPoll';
import { SuccessClientTokenHandlerOptions } from '../../primer-sdk-web/src/core/ProgressNotifier';
import { ErrorCode, PrimerClientError } from '../../primer-sdk-web/src/errors';
import { CommonClientToken } from '../../primer-sdk-web/src/core/ClientTokenHandler';
import {
  UniversalCheckoutOptions,
  PaymentMethodToken,
} from '../../primer-sdk-web/src/types';
import { interpolate } from '../../primer-sdk-web/src/utils/interpolate';
import { PaymentMethodPopupOverlayStore } from '../../primer-sdk-web/src/core/PopupService/PaymentMethodPopupOverlayStore';
import PopupLoading from './PopupLoading.html';
import BaseAsyncPaymentMethod, {
  AsyncPaymentMethodRemoteOptions,
  AsyncPaymentMethodTokenizeOptions,
} from './BaseAsyncPaymentMethod';

const eventName = (event: string) => `ASYNC_PAYMENT_METHOD__${event}`;

type LongPollResults = { id: string; status: string };

type Stage =
  | 'BEFORE_TOKENIZATION'
  | 'TOKENIZATION'
  | 'TOKENIZATION_SUCCESS'
  | 'TOKENIZATION_ERROR'
  | 'SHOW_CONTENT'
  | 'AFTER_LONG_POLL';

const checkResumeToken = ({ id, status }: LongPollResults) =>
  typeof id === 'string' && id.length > 0 && status === 'COMPLETE';

export type CreatePaymentMethodOptions = {
  paymentMethodKey: string;
  clientTokenIntent: string;
  paymentMethodName: string;
  paymentMethodType: string;
  paymentMethodLabel: string;
  popup: {
    width: number;
    height: number;
  };
  overlay: {
    logoSrc: string;
    background: string;
  };
  homeScene?: string;
  hasExportedButtonOptions?: boolean;
};

type PaymentMethodDecodedClientToken = CommonClientToken & {
  intent: string;
  redirectUrl: string;
  statusUrl: string;
};

export const createPaymentMethod = (
  {
    paymentMethodKey,
    clientTokenIntent,
    paymentMethodName,
    paymentMethodType,
    paymentMethodLabel,
    popup,
    overlay,
    hasExportedButtonOptions = true,
    homeScene,
  }: CreatePaymentMethodOptions,
  implementationType?: ImplementationType,
) => {
  class PaymentMethod extends BaseAsyncPaymentMethod {
    static specs = {
      key: paymentMethodKey,
      canVault: false,
      buttonManagedByPaymentMethod: false,
      implementationType: implementationType ?? undefined,
      hasExportedButtonOptions,
      homeScene,
    };

    static create = (
      context: IPaymentMethodContext,
      options: any,
      remoteConfig: AsyncPaymentMethodRemoteOptions,
    ) => new PaymentMethod(context, options, remoteConfig);

    private context: IPaymentMethodContext;

    protected options: Record<string, any> | undefined;

    // For analytics
    private stage: Stage | undefined;

    private closePopupSource: string;

    private paymentMethodName: string;

    private paymentMethodPopupOverlayStore: PaymentMethodPopupOverlayStore;

    constructor(
      context: IPaymentMethodContext,
      options: Record<string, any> | undefined,
      remoteConfig: AsyncPaymentMethodRemoteOptions,
    ) {
      super(paymentMethodType as PaymentMethodType, paymentMethodLabel);

      this.context = context;
      this.options = options;
      this.remoteConfig = remoteConfig;

      this.tokenize = this.tokenize.bind(this);

      this.paymentMethodPopupOverlayStore = this.context.store.getStore(
        'paymentMethodPopupOverlay',
      ) as PaymentMethodPopupOverlayStore;

      if (this.options) {
        overlay.logoSrc = this.options.logoSrc ?? overlay.logoSrc;
        overlay.background = this.options.background ?? overlay.background;
      }
    }

    private shouldRedirect() {
      return (
        (this.context.clientOptions as UniversalCheckoutOptions)?.redirect
          ?.forceRedirect ?? false
      );
    }

    private async fetchToken(options?: AsyncPaymentMethodTokenizeOptions) {
      this.context.progress.start();

      const merchantRedirectUrl = (this.context
        .clientOptions as UniversalCheckoutOptions)?.redirect?.returnUrl;

      // Fetch token
      const tokenizationRequestBody = {
        paymentInstrument: {
          type: 'OFF_SESSION_PAYMENT',
          paymentMethodType,
          paymentMethodConfigId: this.remoteConfig.id,
          sessionInfo: {
            locale: this.context.store.getLocale(),
            platform: isMobile() ? 'MOBILE_WEB' : 'WEB',
            browserInfo: getBrowserInfo(),
            merchantRedirectUrl,
            ...options,
          },
        },
      };

      const { error, data } = await this.context.tokenizePaymentMethod(
        tokenizationRequestBody,
      );

      if (error) {
        throw new Error(error.message);
      }

      if (!data) {
        throw new Error('No token received');
      }

      return data;
    }

    private showPaymentMethodOverlay(popupService: PopupService) {
      return this.context.store
        .getStore<PaymentMethodPopupOverlayStore>('paymentMethodPopupOverlay')
        ?.showOverlay({
          paymentMethodName: this.paymentMethodName,
          logoSrc: overlay.logoSrc,
          background: overlay.background,
          logoAltLabel: this.paymentMethodName,

          onCloseClick: () => {
            this.closePopupSource = 'OVERLAY';
            popupService.close();
          },
          onFocusClick: () => {
            this.track('FOCUSED_POPUP', { source: 'OVERLAY' });
            popupService.focus();
          },
        });
    }

    private translatePaymentMethodName(name: string) {
      return this.context.store.getTranslations()._(toCamelCase(name), {
        defaultMessage: paymentMethodName,
      });
    }

    async tokenize(options?: AsyncPaymentMethodTokenizeOptions) {
      this.setLoading();
      this.context.store.setErrorMessage(null);

      this.paymentMethodName = this.translatePaymentMethodName(
        paymentMethodName,
      );

      if (!this.context.store.getIsTokenizationEnabled()) {
        this.context.progress.didNotStart('TOKENIZATION_DISABLED');
        return;
      }

      let shouldStart = this.context.progress.shouldStart();
      if (shouldStart === false) {
        this.context.progress.didNotStart('TOKENIZATION_SHOULD_NOT_START');
        return;
      }

      let popupService: PopupService | undefined;
      let popupCloseListener;
      if (!this.shouldRedirect()) {
        this.trackStage('BEFORE_TOKENIZATION');

        // Open popup (empty for now)
        popupService = this.setupPopupService();

        this.track('OPENED_POPUP');
        this.time('POPUP');
        this.time('FLOW');

        // If popup is not open, that means that the opening failed due to a native popup blocker or an incompatible webview
        if (!popupService.getIsOpen()) {
          this.closePopupSource = 'NATIVE_POPUP_BLOCKER';
          this.trackClosedPopup();
          this.closePopupService(popupService);
          return;
        }

        // If popup is not open after ~100ms, that means that the opening likely failed due to a popup blocker (usually a plugin)
        await delay(100);
        if (!popupService.getIsOpen()) {
          this.closePopupSource = 'POPUP_BLOCKER';
          this.trackClosedPopup();
          this.closePopupService(popupService);
          return;
        }

        this.closePopupSource = 'POPUP';

        // If the popup closes during tokenization, reset everything
        popupCloseListener = async () => {
          this.trackClosedPopup();
          this.options?.onPopupClose?.();
          popupService && this.closePopupService(popupService);
        };
        popupService.addEventListener('close', popupCloseListener);

        // Short delay to not show the overlay directly
        await delay(200);

        // If popup is not open, that means that the opening failed due to a popup blocker (usually a plugin)
        if (!popupService.getIsOpen()) {
          return;
        }

        // Show overlay
        this.showPaymentMethodOverlay(popupService);
      }

      // Check if shouldStart is ready
      try {
        shouldStart = await shouldStart;
      } catch (e) {
        shouldStart = false;
      }

      if (shouldStart === false) {
        this.context.progress.didNotStart('TOKENIZATION_SHOULD_NOT_START');
        this.closePopupSource = 'FORM_VALIDATION';
        popupService && this.closePopupService(popupService);
        return;
      }

      //
      let token: PaymentMethodToken;
      try {
        this.trackStage('TOKENIZATION');
        await this.context.clientSessionActionService?.selectPaymentMethod(
          paymentMethodType as PaymentMethodType,
          false,
        );
        const data = await this.fetchToken(options);

        // If popup is not open anymore, don't do anything
        if (!this.shouldRedirect() && !popupService?.getIsOpen()) {
          this.resetLoading();
          await this.context.clientSessionActionService?.unselectPaymentMethod();
          return;
        }

        token = data;
      } catch (e) {
        // If popup is not open anymore, don't consider it an error
        if (!this.shouldRedirect() && !popupService?.getIsOpen()) {
          this.resetLoading();
          await this.context.clientSessionActionService?.unselectPaymentMethod();
          return;
        }

        this.handleTokenizationFail(popupService);
        return;
      }

      this.trackStage('TOKENIZATION_SUCCESS');

      this.context.progress.success(token, {
        clientTokenHandler: {
          clientTokenIntent,
          handler: async (...args) => {
            if (popupService) {
              // this.handleClientToken already listen to 'close', so we should remove the event listener
              popupService.removeEventListener('close', popupCloseListener);

              // Cancel everything if the popup has been closed since
              if (!popupService.getIsOpen()) {
                this.resetLoading();
                return undefined;
              }
            }

            return this.handleClientToken(...args, popupService);
          },

          onError: () => {
            this.context.clientSessionActionService?.unselectPaymentMethod();

            if (popupService) {
              this.closePopupSource = 'RESUME_ERROR';
              this.closePopupService(popupService);
              popupService.removeEventListener('close', popupCloseListener);
            }

            this.resetLoading();
          },
        },
      });
    }

    private async handleClientToken(
      { decodedClientToken }: SuccessClientTokenHandlerOptions,
      popupService?: PopupService,
    ): Promise<any> {
      this.throwIfInvalidClientToken(
        decodedClientToken as PaymentMethodDecodedClientToken,
      );

      const {
        redirectUrl,
        statusUrl,
      } = decodedClientToken as PaymentMethodDecodedClientToken;

      if (!popupService || this.shouldRedirect()) {
        window.location.href = redirectUrl;
        return false;
      }

      // Prepare popupClose
      const popupClosePromise = new Promise<'popupClose'>((resolve) => {
        popupService.addEventListener('close', async () => {
          this.options?.onPopupClose?.();
          this.trackClosedPopup();
          resolve('popupClose');
        });
      });

      // Show redirectUrl + focus
      popupService.setUrl(redirectUrl);

      this.trackStage('SHOW_CONTENT');

      // Start LongPolling
      const { longPoll, promise: longPollPromise } = this.startLongPollStatus(
        statusUrl,
      );

      try {
        const raceResult = await Promise.race([
          popupClosePromise,
          longPollPromise,
        ]);

        this.trackStage('AFTER_LONG_POLL');

        // Close the popup if long poll finished
        if (popupService.getIsOpen()) {
          this.closePopupSource = 'LONG_POLL';
          popupService.close();
        }

        // Stop long poll if popup closed
        longPoll.stop();

        this.context.store
          .getStore<PaymentMethodPopupOverlayStore>('paymentMethodPopupOverlay')
          ?.hideOverlay();

        // Long poll failed
        // Reset
        if (!raceResult) {
          this.resetLoading();
          return undefined;
        }

        let resumeToken: string;

        // Popup has been closed by the user
        if (raceResult === 'popupClose') {
          // Manually fetch resume key from long poll, just in case the user closes
          // the page after the payment is done but before the long poll resolves
          const data = await this.fetchStatusOnce(statusUrl);
          if (typeof data === 'string') {
            resumeToken = data;
          } else {
            await this.context.clientSessionActionService?.unselectPaymentMethod();
            this.resetLoading();
            return undefined;
          }
        }

        // Resume key long poll succeeded
        else {
          resumeToken = raceResult.id;
        }

        this.trackStage();
        this.track('FLOW');
        return { resumeToken };
      } catch (e) {
        await this.context.clientSessionActionService?.unselectPaymentMethod();
        this.resetLoading();
        throw PrimerClientError.fromErrorCode(ErrorCode.RESUME_ERROR, {
          message: "Can't fetch resume key",
        });
      }
    }

    private setupPopupService() {
      const popupService = createPopupService();

      // Open popup
      popupService.open({
        width: popup.width,
        height: popup.height,
        popupName: this.paymentMethodName,
      });

      // Set loading screen
      popupService.setContent(
        interpolate(PopupLoading, {
          paymentMethodName: this.paymentMethodName,
          logoSrc: overlay.logoSrc,
          background: overlay.background,
          logoAlt: this.paymentMethodName,
          message: interpolate(
            this.context.store
              .getTranslations()
              ._('paymentMethodPopupLoadingScreenText', {
                paymentMethodName: this.paymentMethodName,
              }),
          ),
        }),
      );

      return popupService;
    }

    private closePopupService(popupService: PopupService) {
      popupService.close();
      this.context.store
        .getStore<PaymentMethodPopupOverlayStore>('paymentMethodPopupOverlay')
        ?.hideOverlay();

      this.resetLoading();
    }

    private resetLoading() {
      this.context.store.setIsLoading(false);
      this.context.store.setIsProcessing(false);
    }

    private setLoading() {
      this.context.store.setIsLoading(true);
      this.context.store.setIsProcessing(true);
    }

    private async fetchStatusOnce(statusUrl: string) {
      const { data } = await this.context.api.get<LongPollResults>(statusUrl);

      if (data && checkResumeToken(data)) {
        return data?.id as string;
      }
      return false;
    }

    private startLongPollStatus(statusUrl: string) {
      const longPoll = new LongPoll({
        api: this.context.api,
      });

      const promise = longPoll.start<LongPollResults>({
        url: statusUrl,
        timeout: 1000 * 60 * 60 * 24, //24h poll
        pollInterval: 1000, // Wait 1sec before making another call
        predicate: checkResumeToken,
      });

      return { longPoll, promise };
    }

    private handleTokenizationFail(popupService?: PopupService) {
      this.context.clientSessionActionService?.unselectPaymentMethod();

      if (popupService) {
        this.stage = 'TOKENIZATION_ERROR';
        this.closePopupSource = 'TOKENIZATION_ERROR';
        this.closePopupService(popupService);
      }

      this.context.progress.error(
        PrimerClientError.fromErrorCode(ErrorCode.TOKENIZATION_ERROR, {
          message: 'Call to /payment-instruments failed',
        }),
      );
    }

    private async throwIfInvalidClientToken(
      decodedClientToken: PaymentMethodDecodedClientToken,
    ) {
      const { intent } = decodedClientToken;

      if (intent !== clientTokenIntent) {
        await this.context.clientSessionActionService?.unselectPaymentMethod();
        throw PrimerClientError.fromErrorCode(ErrorCode.RESUME_ERROR, {
          message: 'Unexpected client token intent',
        });
      }
    }

    // Analytics

    private track(event: string, data: AnalyticsData = {}) {
      return this.context.analytics.callV1({
        event: eventName(event),
        data: {
          paymentMethodType,
          stage: this.stage,
          ...data,
        },
      });
    }

    private time(event: string) {
      return this.context.analytics.callV1({ event: eventName(event) });
    }

    private trackClosedPopup() {
      this.track('CLOSED_POPUP', { closeReason: this.closePopupSource });
      this.track('POPUP', { closeReason: this.closePopupSource });
    }

    private trackStage(stage?: Stage) {
      if (this.stage) {
        this.track(this.stage, { nextStage: stage, stage: undefined });
      }

      if (stage) {
        this.time(stage);
      }
      this.stage = stage;
    }
  }

  return PaymentMethod;
};

export default createPaymentMethod;
