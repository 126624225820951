import { useStoreSelector, useStore } from '@primer-io/shared-library/contexts';
import { canFocusOnPopup } from './PopupService';
import { PaymentMethodPopupOverlayStore } from './PaymentMethodPopupOverlayStore';
import { Overlay } from '../../components/Overlay';
import { PaymentMethodOverlayContent } from './PaymentMethodOverlayContent';

const PaymentMethodPopupOverlay = () => {
  const store = useStore<PaymentMethodPopupOverlayStore>(
    'paymentMethodPopupOverlay',
  );

  const props = useStoreSelector(store, (state) => state);
  const canFocus = canFocusOnPopup();

  return (
    <Overlay isVisible={props.isVisible} onCloseClick={props.onCloseClick}>
      <PaymentMethodOverlayContent
        canFocus={canFocus}
        {...props}
      ></PaymentMethodOverlayContent>
    </Overlay>
  );
};

export default PaymentMethodPopupOverlay;
