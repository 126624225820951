export const CardNetworkMapping: Record<string, string> = {
  'american-express': 'AMEX',
  'dankort': 'DANKORT',
  'diners': 'DINERS_CLUB',
  'discover': 'DISCOVER',
  'enroute': 'ENROUTE',
  'elo': 'ELO',
  'hiper': 'HIPER',
  'interac': 'INTERAC',
  'jcb': 'JCB',
  'maestro': 'MAESTRO',
  'mastercard': 'MASTERCARD',
  'mir': 'MIR',
  'private_label': 'PRIVATE_LABEL',
  'unionpay': 'UNIONPAY',
  'visa': 'VISA',
  'other': 'OTHER',
};
