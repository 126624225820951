import { FunctionalComponent } from 'preact';

import { useLayoutEffect } from 'preact/hooks';
import {
  useCheckoutContext,
  useCheckoutStore,
  useSelector,
} from '@primer-io/shared-library/contexts';
import styled, { css } from 'styled-components';
import {
  getBlockStyle,
  getTextStyle,
  BaseButton,
  animationCurve,
  Dash,
  createWithStyle,
} from '@primer-io/shared-library/components';
import buildClassName from '../utils/buildClassName';
import { UniversalCheckoutOptions } from '../types';

import { ClickEvent } from '../analytics/constants/enums';
import { useFirstRender } from '../utils/hooks';
import { PaymentMethodType } from '../enums/Tokens';

const withSubmitButtonStyle = createWithStyle((style) => style?.submitButton);

const Root = styled.div`
  position: relative;
  transition: all 300ms ${animationCurve};
  height: 0px;
  opacity: 0;
  &.PrimerCheckout--entering,
  &.PrimerCheckout--entered {
    height: 50px;
    opacity: 1;
  }
`;

const getSubmitButtonStyle = (style) => {
  return {
    ...getTextStyle(style),
    ...getBlockStyle(style),
  };
};

const StyledButton = styled(BaseButton)<{ loading: boolean }>`
  cursor: pointer;

  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;

  border: none;

  text-align: center;

  transition: opacity 300ms ${animationCurve},
    box-shadow 300ms ${animationCurve}, background-color 300ms ${animationCurve};

  justify-self: flex-end;
  flex: 0 0 auto;

  &:not(:disabled) {
    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }

  ${(p) =>
    p.loading &&
    withSubmitButtonStyle((style) => getSubmitButtonStyle(style?.loading))}
  ${withSubmitButtonStyle((style) => getSubmitButtonStyle(style?.base))}


  &:disabled {
    ${withSubmitButtonStyle((style) => getSubmitButtonStyle(style?.disabled))}
  }

  ${(p) =>
    p.loading &&
    css`
      pointer-events: none;
      & .PrimerCheckout__loadingRing {
        opacity: 1;
        transform: translate(calc(0% - 16px), -50%);
      }
    `}
`;

const LoadingRing = styled.div`
  position: absolute;
  right: 0px;
  top: 50%;
  width: 20px;
  height: 20px;
  transition: all 300ms ${animationCurve};

  transform: translate(100%, -50%);
  opacity: 0;

  & .spinner {
    animation: rotate 1.2s linear infinite;
    z-index: 2;
  }

  & .path {
    stroke: white;
    stroke-linecap: round;
    animation: ${Dash} 1.5s ease-in-out infinite;
  }

  ${withSubmitButtonStyle((style) => ({
    stroke: style?.loading?.color ?? style?.base?.color,
  }))}
`;

const SubmitButton: FunctionalComponent = () => {
  const { viewUtils, options, context, className } = useCheckoutContext();

  const store = useCheckoutStore();
  const { UIOrderAmount } = store;
  const isVisible = useSelector((s) => s.submitButton.isVisible);
  const isDisabled = useSelector((s) => s.submitButton.isDisabled);
  const message = useSelector((s) => s.submitButton.message);
  const canDisplayAmount = useSelector((s) => s.submitButton.canDisplayAmount);
  const isLoading = useSelector((s) => s.isLoading);

  const shouldDisplayAmount = options.submitButton?.amountVisible ?? false;
  const shouldUseBuiltInButton = options.submitButton?.useBuiltInButton ?? true;

  useFirstRender(() => {
    viewUtils.toggleVisibilityAnimated(
      'primer-checkout-submit-button-container',
      false,
      {
        duration: 0,
        animateHeight: true,
        autoHeight: false,
      },
    );
  });

  useLayoutEffect(() => {
    if (!shouldUseBuiltInButton) {
      viewUtils.toggleVisibilityAnimated(
        'primer-checkout-submit-button-container',
        false,
        {
          duration: 0,
          animateHeight: true,
          autoHeight: false,
        },
      );
      return;
    }
    viewUtils.toggleVisibilityAnimated(
      'primer-checkout-submit-button-container',
      isVisible,
      {
        duration: 500,
        animateHeight: true,
        autoHeight: false,
      },
    );
  }, [isVisible]);

  const handleClick = () => {
    context?.analytics.setPaymentIntent(PaymentMethodType.PAYMENT_CARD);
    context?.analytics.callV1({ event: ClickEvent.clickedSubmitButton });

    if (isLoading) return;
    store.triggerSubmitButtonClick();
  };

  ///////////////////////////////////////////
  // Render
  ///////////////////////////////////////////

  const submitButtonContainerClassName = buildClassName([
    className('submitButtonContainer'),
    (options as UniversalCheckoutOptions)?.advanced?.platform === 'MAGENTO' &&
      'actions-toolbar',
  ]);

  const submitButtonClassName = buildClassName([
    className('submitButton'),
    (options as UniversalCheckoutOptions)?.advanced?.platform === 'MAGENTO' &&
      'action checkout',
  ]);

  return (
    <Root
      id='primer-checkout-submit-button-container'
      className={submitButtonContainerClassName}
      data-testId='SubmitButton/Container'
    >
      <StyledButton
        type='button'
        id='primer-checkout-submit-button'
        className={submitButtonClassName}
        disabled={isDisabled || isLoading}
        loading={isLoading}
        onClick={handleClick}
        data-testId='SubmitButton/Button'
      >
        <span>
          {message} {canDisplayAmount && shouldDisplayAmount && UIOrderAmount}
        </span>
        <LoadingRing
          id='primer-checkout-submit-button-loading'
          className='PrimerCheckout__loadingRing'
        >
          <svg className='spinner' viewBox='0 0 50 50'>
            <circle
              className='path'
              cx='25'
              cy='25'
              r='20'
              fill='none'
              stroke-width='5'
            ></circle>
          </svg>
        </LoadingRing>
      </StyledButton>
    </Root>
  );
};

export default SubmitButton;
