import { useCheckoutContext } from '@primer-io/shared-library/contexts';
import { SceneEnum } from '@primer-sdk-web/enums/Checkout';
import {
  useDisableComponent,
  useLocaleData,
} from '@primer-sdk-web/utils/hooks';
import {
  createWithStyle,
  getTextStyle,
} from '@primer-io/shared-library/components';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { FeatherProps } from 'preact-feather/dist/types';
import { BaseButton } from '../Button';

const withPaymentMethodButtonStyle = createWithStyle(
  (style) => style?.paymentMethodButton,
);

const TextButtonWithIcon = styled(BaseButton)`
  display: flex;
  align-items: center;
  padding: 1px 6px;
  cursor: pointer;
  width: 100%;
  border-radius: 0;
  justify-content: center;

  &:enabled:hover {
    opacity: 0.7;
  }

  ${withPaymentMethodButtonStyle((style) => getTextStyle(style?.primaryText))}

  ${withPaymentMethodButtonStyle((style) => ({
    background: style?.background,
  }))}
`;

const Label = styled.span`
  ${(p) => !p.isRtlLocale && 'margin-left: 10px;'}
  ${(p) => p.isRtlLocale && 'margin-right: 10px;'}
`;

type Props = {
  buttonId?: string;
  rootId?: string;
  backgroundColor: string;
  text?: string;
  textColor?: string;
  icon?: any;
  iconAlt?: string;
  iconHeight?: string;
  iconWidth?: string;
  featherIcon?: FunctionalComponent<FeatherProps>;
  onClick: () => void;
  onLoad?: () => void;
  onError?: () => void;
};

const IconTextButton: FunctionalComponent<Props> = ({
  buttonId,
  rootId,
  backgroundColor,
  text,
  textColor,
  icon,
  iconAlt,
  iconHeight,
  iconWidth,
  featherIcon: FeatherIcon,
  onClick,
  onLoad,
  onError,
}) => {
  const { className } = useCheckoutContext();
  const { isRtlLocale } = useLocaleData();

  const { disableNavigation } = useDisableComponent({
    scene: SceneEnum.CHOOSE_PAYMENT_METHOD,
  });

  return (
    <div
      id={rootId}
      style={{ background: backgroundColor }}
      className={className('apmButton', { managed: true, logo: true })}
    >
      <TextButtonWithIcon
        id={buttonId}
        onClick={onClick}
        disabled={disableNavigation}
        style={{ color: textColor }}
      >
        {icon && (
          <img
            style={{ height: iconHeight, maxWidth: iconWidth }}
            alt={iconAlt}
            src={icon}
            onLoad={onLoad}
            onError={onError}
          />
        )}
        {FeatherIcon && !icon && <FeatherIcon className='icon' />}
        {text && <Label isRtlLocale={isRtlLocale}>{text}</Label>}
      </TextButtonWithIcon>
    </div>
  );
};

export default IconTextButton;
