import { CreditCard } from '../../payment-methods/credit-card/CreditCard';
import { CreditCardFieldName } from '../../payment-methods/credit-card/types';
import { IFrameMessagePayload } from '../../core/IFrameMessage';
import { IFrameEventType } from '../../core/IFrameEventType';
import {
  EventTypes,
  HeadlessHostedInputOptions,
  IHeadlessHostedInput,
  InputMetadata,
} from '../../types';

export class HeadlessHostedInput implements IHeadlessHostedInput {
  private creditCard: CreditCard;

  private readonly placeholder?: string;

  private readonly ariaLabel?: string;

  private readonly name: CreditCardFieldName;

  private readonly configName: string;

  private eventListeners: Map<EventTypes, EventListener> = new Map();

  constructor(
    creditCard: CreditCard,
    name: CreditCardFieldName,
    options: HeadlessHostedInputOptions,
  ) {
    this.placeholder = options.placeholder;
    this.ariaLabel = options.ariaLabel;
    this.creditCard = creditCard;
    this.name = name;
    this.configName = `${name}-card`;

    this.subscribeToEvents();
  }

  getName(): string {
    return this.name;
  }

  getOptions(): HeadlessHostedInputOptions {
    return {
      placeholder: this.placeholder,
      ariaLabel: this.ariaLabel,
    };
  }

  setOptions(options: HeadlessHostedInputOptions): void {
    Object.assign(this, options);
  }

  addEventListener(event: EventTypes, callback: EventListener): void {
    this.eventListeners[event] = callback;
  }

  handleChange({ meta }: { meta: InputMetadata }) {
    this.eventListeners[EventTypes.CHANGE]?.(meta);
  }

  render(
    container: string,
    options: HeadlessHostedInputOptions,
  ): Promise<void> {
    this.setOptions(options);

    return this.creditCard.createField(this.name, {
      id: `primer-${this.name}-field`.toLocaleLowerCase(),
      name: this.configName,
      container: `#${container}`,
      placeholder: this.placeholder,
      ariaLabel: this.ariaLabel,
      placement: 'prepend',
      style: options?.style ?? {},
      onChange: this.handleChange.bind(this),
    });
  }

  focus(): void {
    this.creditCard.publishEvent(this.configName, IFrameEventType.SET_FOCUSED);
  }

  blur(): void {
    this.creditCard.publishEvent(this.configName, IFrameEventType.SET_BLURRED);
  }

  setDisabled(status: boolean): void {
    this.creditCard.publishEvent(
      this.configName,
      IFrameEventType.SET_DISABLED,
      {
        disabled: status,
      },
    );
  }

  private processIFrameEvent(eventType: EventTypes) {
    return (event: IFrameMessagePayload): void => {
      if (event.meta.source === this.configName) {
        this.eventListeners[eventType]?.({ source: this.name });
      }
    };
  }

  private subscribeToEvents(): void {
    this.creditCard.subscribeToEvent(
      IFrameEventType.INPUT_BLUR,
      this.processIFrameEvent(EventTypes.BLUR),
    );

    this.creditCard.subscribeToEvent(
      IFrameEventType.INPUT_FOCUS,
      this.processIFrameEvent(EventTypes.FOCUS),
    );
  }
}
