import { noop } from './noop';
import { getCss } from '../checkout/ui/style/CssStyle';

export const createPromiseHandler = <T>() => {
  let promiseFunctions: {
    resolve: (data: T) => void;
    reject: (error: any) => void;
  } = {
    resolve: noop,
    reject: noop,
  };

  let resolvedValue: T | undefined;

  const promise = new Promise<T>((resolve, reject) => {
    promiseFunctions = {
      resolve: (arg: T) => {
        resolvedValue = arg;
        resolve(arg);
      },
      reject,
    };
  });

  return { promise, promiseFunctions, getResolvedValue: () => resolvedValue };
};

type CreateCallbackHandlerOptions<Handler> = {
  functionNames: (keyof Handler)[];
  warning?: {
    timeout?: number;
    onTimeout?: ({ timeout: number }) => void;
  };
};

export const createCallbackHandler = <Handler>({
  functionNames,
  warning,
}: CreateCallbackHandlerOptions<Handler>): {
  handler: Handler;
  promise: Promise<{
    functionName: keyof Handler;
    args: any[];
  }>;
} => {
  const { promiseFunctions, promise, getResolvedValue } = createPromiseHandler<{
    functionName: keyof Handler;
    args: any[];
  }>();

  // Handle Timeout
  let timeoutTimer;
  if ((warning?.timeout ?? 0) > 0 && warning?.onTimeout) {
    timeoutTimer = setTimeout(
      () => warning?.onTimeout?.({ timeout: warning?.timeout }),
      warning?.timeout,
    );
  }

  // Create a proxy from all function names to "promiseFunctions.resolve"
  // We could technically use a Proxy for this but the debugging experience would be worse
  // With this solution, the developer can "console.log" the handler and see exactly what they can pass.
  // With a proxy, doing "console.log" would return just a Proxy...
  const handler = functionNames.reduce((acc, functionName) => {
    acc[functionName as string] = (...args) => {
      // Reset timeout
      clearTimeout(timeoutTimer);

      // Prevent duplicate
      const resolvedValue = getResolvedValue();
      if (resolvedValue) {
        const message = `This completion handler has already been called with \`${resolvedValue.functionName}\`. The call to \`${functionName}\` has been ignored.`;
        console.error(message);
        return;
      }

      // Resolve function
      promiseFunctions.resolve({
        functionName,
        args,
      });
    };

    return acc;
  }, {});

  return {
    promise,
    handler: handler as Handler,
  };
};

interface PrintWarningOptions {
  timeout: number;
  methodDefinition: string;
  functionDescriptions: { definition: string; description: string }[];
}

export const printHandlerTimeoutWarning = ({
  timeout,
  methodDefinition,
  functionDescriptions,
}: PrintWarningOptions) => {
  const BASE_CSS = {
    display: 'inline-block',
  };

  const getConsoleCss = (obj?: Record<string, any>) =>
    getCss({ ...BASE_CSS, ...obj });

  console.warn(
    `%cIt seems that the \`handler\` of \`${methodDefinition}\` has not yet been called after ${timeout}ms.%c\nThe Primer SDK will stay in a loading state until one of the following handler functions is called:\n${functionDescriptions
      .map(
        ({ definition, description }) => `%c\n${definition}%c\n${description}`,
      )
      .join('\n')}`,

    // First line
    getConsoleCss({ 'font-weight': 'bold', 'font-style': 'italic' }),
    getConsoleCss(),

    // Prepare other lines
    ...functionDescriptions.flatMap(() => [
      getConsoleCss({
        'border-left': '2px solid rgba(255, 255, 255, 0.1)',
        'padding-left': '8px',
        'font-weight': 'bold',
      }),
      getConsoleCss({
        'border-left': '2px solid rgba(255, 255, 255, 0.1)',
        'padding-left': '8px',
      }),
    ]),
  );
};
