import { PayPalOptions } from './payment-methods/paypal/types';
import { PaymentFlow, ThreeDSecureStatus } from './enums/Tokenization';
import {
  TokenType,
  PaymentInstrumentType,
  PaymentMethodType,
} from './enums/Tokens';
import { GooglePayOptions } from './payment-methods/google-pay/types';
import { ApplePayOptions } from './payment-methods/apple-pay/types';
import { DirectDebitOptions } from './payment-methods/go-cardless/types';
import { ErrorCode, PrimerClientError } from './errors';
import { CheckoutStyle } from './styles';
import { Nullable } from './utilities';
import { CheckoutUXFlow } from './enums/Checkout';
import { ClientSession, ProductType } from './models/ClientSession';
import { PaymentMethodData } from './models/PaymentMethodData';
import { CardMetadata } from './hosted-scripts/CardMetadata';
import {
  BackgroundColor,
  IconUrl,
} from './models/PaymentMethodDisplayMetadata';

///////////////////////////////////////////
// Options
///////////////////////////////////////////

export interface TransitionDurationOptions {
  enter?: number;
  exit?: number;
}

export interface SubmitButtonOptions {
  amountVisible?: boolean;
  useBuiltInButton?: boolean;

  onVisible?: (
    isVisible: boolean,
    context: { currentSceneId: string; previousSceneId?: string },
  ) => void;

  onContentChange?: (
    content: string,
    context: { currentSceneId: string },
  ) => void;

  onDisable?: (
    isDisabled: boolean,
    context: { currentSceneId: string },
  ) => void;

  onLoading?: (isLoading: boolean, context: { currentSceneId: string }) => void;
}

export interface ProcessingIndicatorOptions {
  visible?: boolean;
}

export interface FormOptions {
  inputLabelsVisible?: boolean;
}

export type CardPreferredFlow = 'DEDICATED_SCENE' | 'EMBEDDED_IN_HOME';

export interface CheckoutCardOptions {
  cardholderName?: {
    /**
     * Only works if the cardholder name is visible
     */
    required?: boolean;

    /**
     * @deprecated Set it on your Dashboard
     */
    visible?: boolean;
    placeholder?: Label;
  };

  cardNumber?: {
    placeholder?: Label;
  };

  expiryDate?: {
    placeholder?: Label;
  };

  cvv?: {
    placeholder?: Label;
  };

  preferredFlow?: CardPreferredFlow;
}

export interface ErrorMessageOptions {
  disabled?: boolean;
  onErrorMessageShow?: (message: string) => void;
  onErrorMessageHide?: () => void;
}

export enum SuccessScreenType {
  PAYMENT_METHOD = 'PAYMENT_METHOD',

  CHECK = 'CHECK',
}

///////////////////////////////////////////
// Payment Methods
///////////////////////////////////////////
export interface CardDetails {
  last4Digits: string;
  cardNumber: string;
  name: string;
  icon: string;
  network: string;
  userDescription?: string;
}

export type CardNetwork =
  | 'american-express'
  | 'diners-club'
  | 'discover'
  | 'elo'
  | 'hiper'
  | 'hipercard'
  | 'interac'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'mir'
  | 'unionpay'
  | 'private-label'
  | 'visa';

export interface PayPalDetails {
  icon: string;
  email?: string;
}

export interface CustomizablePaymentMethodButton {
  logoSrc: string;
  background: string;
  logoAlt?: string;
  text?: string;
}

export type KlarnaPaymentCategoryType =
  | 'pay_now'
  | 'pay_later'
  | 'pay_over_time';

export type KlarnaSupportedLocale =
  | 'de-AT'
  | 'en-AT'
  | 'da-DK'
  | 'en-DK'
  | 'fi-FI'
  | 'sv-FI'
  | 'en-FI'
  | 'de-DE'
  | 'en-DE'
  | 'nl-NL'
  | 'en-NL'
  | 'nb-NO'
  | 'en-NO'
  | 'sv-SE'
  | 'en-SE'
  | 'de-CH'
  | 'fr-CH'
  | 'it-CH'
  | 'en-CH'
  | 'en-GB'
  | 'en-US'
  | 'en-AU'
  | 'nl-BE'
  | 'fr-BE'
  | 'es-ES'
  | 'it-IT';

export interface KlarnaAddress {
  title?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  postalCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  countryCode?: Alpha2CountryCode;
  city?: string;
  email?: string;
}

export interface KlarnaCustomerDetails {
  dateOfBirth?: string;
  gender?: 'MALE' | 'FEMALE';
  nationalIdentificationNumber?: string;
  title?: string;
}

export interface KlarnaOptions {
  paymentFlow?: PaymentFlow;
  recurringPaymentDescription?: string;
  allowedPaymentCategories?: KlarnaPaymentCategoryType[];
}

export {
  TokenType,
  PaymentInstrumentType,
  PaymentMethodType,
  PaymentMethodData,
  CheckoutUXFlow,
  PaymentFlow,
  ThreeDSecureStatus,
  ErrorCode,
};

export type SupportedLocale = string;

export type Alpha2CountryCode = string;

export type Alpha3CurrencyCode = string;

export type ElementOrID = Nullable<string | HTMLElement>;

export type ValidationErrorType = 'required' | 'invalid';

export type Label<
  T extends Record<string, unknown> = Record<string, unknown>
> = string | ((options: { locale: SupportedLocale } | T) => string);

export type ResumeToken = {
  resumeToken: string;
  paymentId?: string;
};

export interface InputValidationError {
  name: string;
  error: string;
  message: string;
}

export interface Validation {
  valid: boolean;
  validationErrors: InputValidationError[];
  error?: string;
}
export interface InputMetadata {
  errorCode: string | null;
  error: string | null;
  valid: boolean;
  active: boolean;
  dirty: boolean;
  touched: boolean;
  submitted: boolean;
}

export interface FormState {
  dirty: boolean;
  touched: boolean;
  active: boolean;
  valid: boolean;
  submitted: boolean;
}

export type InlineStyleMap = Partial<Record<keyof CSSStyleDeclaration, string>>;

export interface ExternalPayerInfo {
  externalPayerId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface CustomerAddress {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  state?: string;
  countryCode?: Alpha2CountryCode;
  postalCode?: string;
}

export interface CustomerDetails {
  customerTaxId?: string;
  shippingAddress?: CustomerAddress;
  billingAddress?: CustomerAddress;
}

export interface BusinessDetails {
  address?: CustomerAddress;
  nexusAddresses?: [CustomerAddress];
}

export interface MonetaryAmount {
  value: number | string;
  currency: Alpha3CurrencyCode;
}

export interface OrderItem {
  name: string;
  unitAmount: number | string;
  taxAmount?: number;
  reference?: string;
  quantity?: number;
  discountAmount?: number | string;
  taxCode?: string;
  productType?: ProductType;
}

export interface OrderDetails {
  totalAmount?: number | string;
  totalTaxAmount?: number | string;
  currencyCode?: Alpha3CurrencyCode;
  items?: OrderItem[];
  shippingAmount?: number | string;
  taxExemptionType?: string;
}

export interface ThreeDSecureOrderDetails {
  amount: MonetaryAmount;
  email: string;
  billingAddress: CustomerAddress;
  orderId: string;
}

export interface ThreeDSVerificationOptions {
  token: string;
  container: string;
  order: ThreeDSecureOrderDetails;
  testScenario?: string;
  onChallengeStart?: () => void;
  onChallengeEnd?: () => void;
}

export interface ThreeDSAuthenticationData {
  responseCode: ThreeDSecureStatus;
  reasonCode?: string;
  reasonText?: string;
  protocolVersion: string;
  challengeIssued: boolean;
}

export interface VaultData {
  customerId: string;
}

export interface PaymentCardDetails {
  last4Digits: string;
  cardholderName: string;
  network: string;
}

export interface PayPalBillingAgreementDetails {
  paypalBillingAgreementId: string;
  externalPayerInfo?: ExternalPayerInfo;
  shippingAddress?: CustomerAddress;
}

export interface GoCardlessDetails {
  gocardlessMandateId: string;
}

export interface IPaymentMethodToken<T, U extends PaymentInstrumentType> {
  token: string;
  analyticsId: string;
  tokenType: TokenType;
  paymentInstrumentData: T;
  paymentInstrumentType: U;
  threeDSecureAuthentication: Nullable<ThreeDSAuthenticationData>;
  vaultData: Nullable<VaultData>;
}

export type BaseAmountChange = {
  totalAmount: number;
};

export type TaxAmountChange = {
  reason: 'TAX';
  totalTaxAmount: number;
};

export type AmountChange = BaseAmountChange & TaxAmountChange;

export type PaymentCardToken = IPaymentMethodToken<
  PaymentCardDetails,
  PaymentInstrumentType.CARD
>;

export type PayPalBillingAgreementToken = IPaymentMethodToken<
  PayPalBillingAgreementDetails,
  PaymentInstrumentType.PAYPAL_VAULTED
>;

export type GoCardlessToken = IPaymentMethodToken<
  GoCardlessDetails,
  PaymentInstrumentType.GO_CARDLESS
>;

export type IdealPayToken = IPaymentMethodToken<
  Record<string, never>,
  PaymentInstrumentType.PAY_NL_IDEAL
>;

export type PaymentMethodToken =
  | PaymentCardToken
  | PayPalBillingAgreementToken
  | GoCardlessToken
  | IdealPayToken
  | IPaymentMethodToken<any, any>;

export interface ThreeDSVerificationResult<T extends ThreeDSecureStatus> {
  status: T;
  error: Nullable<PrimerClientError>;
  data: PaymentMethodToken;
  resumeToken: string;
}

export type ThreeDSVerification =
  | ThreeDSVerificationResult<ThreeDSecureStatus.SUCCESS>
  | ThreeDSVerificationResult<ThreeDSecureStatus.FAILED>
  | ThreeDSVerificationResult<ThreeDSecureStatus.SKIPPED>;

export interface PurchaseInformation {
  totalAmount: MonetaryAmount;
}

export interface VaultInformation {
  totalAmount: {
    currency: Alpha3CurrencyCode;
  };
}

export interface VaultDetails {
  currencyCode: Alpha3CurrencyCode;
}

export type CheckSuccessScreenOptions = {
  type: SuccessScreenType.CHECK;
  title: Label;
};

export type PaymentMethodSuccessScreenOptions = {
  type: SuccessScreenType.PAYMENT_METHOD;
};

export type SuccessScreenOptions =
  | /* No success screen will be displayed */ false
  | /* Show the default success screen of the payment method*/ undefined
  // Proposed success screens
  | CheckSuccessScreenOptions
  | PaymentMethodSuccessScreenOptions;

export type SuccessCallbackReturnType =
  | /* Show success screen (for backward compatibility) */ undefined
  | /* Show success screen */ true
  | /* Refresh client token and perform new step */ { clientToken: string };

export type VaultOptions = {
  visible?: boolean;
  deletionDisabled?: boolean;
};

export type TransitionType = 'SLIDE_UP' | 'SLIDE_DOWN' | 'SLIDE_HORIZONTAL';

export type SceneTransitionOptions = {
  type: TransitionType;
  duration: number;
  isRtlLocale?: boolean;
};

export type SceneOptions = {
  onEntering?: (sceneId: string) => void;

  transition?: SceneTransitionOptions | false;
};

export interface BinData {
  network?: string;
  issuerCountryCode?: string;
  issuerName?: string;
  issuerCurrencyCode?: string;
  regionalRestriction?: string;
  accountNumberType?: string;
  accountFundingType?: string;
  prepaidReloadableIndicator?: string;
  productUsageType?: string;
  productCode?: string;
  productName?: string;
}

export type RedirectOptions = {
  returnUrl?: string;

  /**
   * default: false
   */
  forceRedirect?: boolean;
};

export type AdvancedOptions = {
  platform?: 'STANDALONE' | 'MAGENTO';
};

export type PaymentMethodAction =
  | 'PAYMENT_METHOD_SELECTED'
  | 'PAYMENT_METHOD_UNSELECTED';

///////////////////////////////////////////
// Payment Handlers
///////////////////////////////////////////
type PaymentHandling = 'AUTO' | 'MANUAL';

export type Payment = {
  id: string;
  orderId: string;
  paymentMethodData?: PaymentMethodData;
};

export interface onBeforePaymentCreateHandler {
  continuePaymentCreation: () => void;
  abortPaymentCreation: () => void;
}

export interface OnCheckoutFailHandler {
  showErrorMessage: (errorMessage?: string) => void;
}

export interface PaymentHandlers {
  onBeforePaymentCreate?: (
    data: {
      paymentMethodType?: PaymentMethodType;
    },
    handler: onBeforePaymentCreateHandler,
  ) => void;
  onPaymentCreationStart?: () => void;
  onCheckoutComplete?: (data: { payment: Payment }) => void;
  onCheckoutFail?: (
    error: PrimerClientError,
    data: {
      payment?: Payment;
    },
    handler: OnCheckoutFailHandler | undefined,
  ) => void;
}

///////////////////////////////////////////
// Tokenization Handlers
///////////////////////////////////////////
export type OnTokenizeShouldStart = (data: {
  paymentMethodType?: PaymentMethodType;
}) => boolean | Promise<boolean>;

export type OnTokenizeDidNotStart = (reason: string) => void;

export type OnTokenizeStart = () => void;

export type OnTokenizeError = (error: PrimerClientError) => void;

export interface OnTokenizeSuccessHandler {
  handleSuccess();
  handleFailure(errorMessage?: string);
  continueWithNewClientToken(clientToken: string);
}

export type OnTokenizeSuccess = (
  data: PaymentMethodToken,
  handler: OnTokenizeSuccessHandler,
) => void | Promise<void>;

export interface OnResumeSuccessHandler {
  handleSuccess();
  handleFailure(errorMessage?: string);
  continueWithNewClientToken(clientToken: string);
}

export type OnResumeSuccess = (
  data: ResumeToken,
  handler: OnResumeSuccessHandler,
) => void;

export type onResumeError = (error: PrimerClientError) => void;

export type OnResumePending = (paymentMethodData: PaymentMethodData) => void;

export interface TokenizationHandlers {
  onTokenizeShouldStart?: OnTokenizeShouldStart;
  onTokenizeDidNotStart?: OnTokenizeDidNotStart;
  onTokenizeStart?: OnTokenizeStart;
  onTokenizeSuccess?: OnTokenizeSuccess;
  onTokenizeError?: OnTokenizeError;
  onResumeSuccess?: OnResumeSuccess;
  onResumePending?: OnResumePending;
  onResumeError?: onResumeError;
}

export interface PaymentMethodHandlers {
  onPaymentMethodAction?: (
    paymentMethodAction: PaymentMethodAction,
    {
      paymentMethodType,
    }: { paymentMethodType: Nullable<PaymentMethodType | string> },
  ) => void;
}

///////////////////////////////////////////
// Client Session Handlers
///////////////////////////////////////////
export interface ClientSessionHandlers {
  onClientSessionUpdate?: (clientSession: ClientSession) => void;
  onBeforeClientSessionUpdate?: () => void;
}

///////////////////////////////////////////
// Checkout + Vault Manager
///////////////////////////////////////////
export interface SinglePaymentMethodCheckoutOptions
  extends TokenizationHandlers,
    PaymentHandlers,
    ClientSessionHandlers,
    PaymentMethodHandlers {
  // General
  uxFlow: CheckoutUXFlow.SINGLE_PAYMENT_METHOD_CHECKOUT;
  container: string | Element;
  paymentMethod: PaymentMethodType;
  locale?: SupportedLocale;

  // Customization
  style?: CheckoutStyle;
  submitButton?: SubmitButtonOptions;
  processingIndicator?: ProcessingIndicatorOptions;
  errorMessage?: ErrorMessageOptions;
  successScreen?: SuccessScreenOptions;
  form?: FormOptions;
  scene?: SceneOptions;
  vault?: VaultOptions;

  // Payment methods
  allowedCardNetworks?: CardNetwork[];
  redirect?: RedirectOptions;
  card?: CheckoutCardOptions;
  giftCard?: CustomizablePaymentMethodButton;
  paypal?: Omit<PayPalOptions, 'container'>;
  googlePay?: Omit<GooglePayOptions, 'container'>;
  applePay?: Omit<ApplePayOptions, 'container'>;
  klarna?: KlarnaOptions;
  directDebit?: DirectDebitOptions;

  // Advanced
  paymentHandling?: PaymentHandling;
}

export interface VaultManagerOptions {
  // General
  container: string | Element;
  locale?: SupportedLocale;

  // Vault
  vaultOnly?: boolean;
  deletionDisabled?: boolean;

  // Customization
  style?: CheckoutStyle;
  scene?: SceneOptions;
  errorMessage?: ErrorMessageOptions;
  form?: FormOptions;
  submitButton?: SubmitButtonOptions;
  processingIndicator?: ProcessingIndicatorOptions;

  // Payment methods
  allowedCardNetworks?: CardNetwork[];
  card?: CheckoutCardOptions;
  threeDSecure?: ThreeDSVerificationOptions;
  giftCard?: CustomizablePaymentMethodButton;
  directDebit?: DirectDebitOptions;
  paypal?: Omit<PayPalOptions, 'container'>;

  // Handlers
  onTokenizeShouldStart?: OnTokenizeShouldStart;
  onTokenizeDidNotStart?: OnTokenizeDidNotStart;
  onTokenizeStart?: () => void;
  onTokenizeSuccess?: (data: PaymentMethodToken) => void;
  onTokenizeError?: (message: PrimerClientError) => void;
}

export interface UniversalCheckoutOptions
  extends TokenizationHandlers,
    PaymentHandlers,
    PaymentMethodHandlers,
    ClientSessionHandlers {
  // General
  uxFlow?: CheckoutUXFlow.CHECKOUT;
  container: string | Element;
  locale?: SupportedLocale;

  // Customization
  style?: CheckoutStyle;
  scene?: SceneOptions;
  vault?: VaultOptions;
  submitButton?: SubmitButtonOptions;
  processingIndicator?: ProcessingIndicatorOptions;
  errorMessage?: ErrorMessageOptions;
  successScreen?: SuccessScreenOptions;
  form?: FormOptions;

  // Payment methods
  allowedPaymentMethods?: PaymentMethodType[];
  allowedCardNetworks?: CardNetwork[];
  card?: CheckoutCardOptions;
  redirect?: RedirectOptions;
  paypal?: Omit<PayPalOptions, 'container'>;
  googlePay?: Omit<GooglePayOptions, 'container'>;
  applePay?: Omit<ApplePayOptions, 'container'>;
  klarna?: KlarnaOptions;
  directDebit?: DirectDebitOptions;
  giftCard?: CustomizablePaymentMethodButton;

  // Advanced
  paymentHandling?: PaymentHandling;
  advanced?: AdvancedOptions;
}

export interface HeadlessUniversalCheckoutOptions
  extends TokenizationHandlers,
    PaymentHandlers,
    PaymentMethodHandlers,
    ClientSessionHandlers {
  style?: CheckoutStyle;
  paymentHandling?: PaymentHandling;
  locale?: SupportedLocale;
  allowedCardNetworks?: CardNetwork[];
  card?: CheckoutCardOptions;
  redirect?: RedirectOptions;
  paypal?: Omit<PayPalOptions, 'container'>;
  googlePay?: Omit<GooglePayOptions, 'container'>;
  applePay?: Omit<ApplePayOptions, 'container'>;
  klarna?: KlarnaOptions;
  directDebit?: DirectDebitOptions;
  giftCard?: CustomizablePaymentMethodButton;
  onAvailablePaymentMethodsLoad: (paymentMethods: PaymentMethodInfo[]) => void;
}

export interface PrimerCheckout {
  teardown(): void;
  submit(): void;

  setPaymentCreationEnabled(isEnabled: boolean): void;
  setTokenizationEnabled(isEnabled: boolean): void;
  refreshClientSession(): Promise<boolean>;
  /**
   * @deprecated The method should not be used
   */
  setClientToken(): Promise<boolean>;
}

export type EventListener = (event?: Event) => void;
export enum EventTypes {
  CHANGE = 'change',
  ERROR = 'error',
  FOCUS = 'focus',
  BLUR = 'blur',
  CLICK = 'click',
  CLOSE = 'close',
}

export interface HeadlessHostedInputOptions {
  placeholder?: string;
  ariaLabel?: string;
  style?: Record<string, any>;
}

export interface IHeadlessHostedInput {
  getOptions(): HeadlessHostedInputOptions;
  setOptions(options: HeadlessHostedInputOptions): void;
  render(container: string, options: HeadlessHostedInputOptions): Promise<void>;
  addEventListener(event: EventTypes, callback: EventListener): void;
  focus(): void;
  blur(): void;
  setDisabled(status: boolean): void;
}
export interface ICardPaymentMethodManager {
  createHostedInputs(): {
    cardNumberInput: IHeadlessHostedInput;
    expiryInput: IHeadlessHostedInput;
    cvvInput: IHeadlessHostedInput;
  };
  setCardholderName(cardholderName: string): void;
  removeHostedInputs(): void;
  submit(): Promise<void>;
  validate(): Promise<Validation>;
  reset(): void;
}

export interface PayPalStyles {
  buttonColor?: 'gold' | 'blue' | 'silver' | 'white' | 'black';
  buttonShape?: 'pill' | 'rect';
  buttonSize?: 'small' | 'medium' | 'large' | 'responsive';
  buttonHeight?: number;
  buttonLabel?:
    | 'checkout'
    | 'credit'
    | 'pay'
    | 'buynow'
    | 'paypal'
    | 'installment';
  buttonTagline?: boolean;
}

export interface GooglePayStyles {
  buttonType?: 'long' | 'short';
  buttonColor?: 'default' | 'black' | 'white';
}

export interface ApplePayStyles {
  buttonType?:
    | 'plain'
    | 'buy'
    | 'set-up'
    | 'donate'
    | 'check-out'
    | 'book'
    | 'subscribe';

  buttonStyle?: 'white' | 'white-outline' | 'black';
}

export interface HeadlessButtonRenderOptions {
  style?:
    | GooglePayStyles
    | PayPalStyles
    | ApplePayStyles
    | Record<string, unknown>;
}

export interface IHeadlessPaymentMethodButton {
  render(
    containerId: string,
    options: HeadlessButtonRenderOptions,
  ): Promise<void>;
  setDisabled(disabled: boolean): Promise<void>;
  clean(): void;

  focus(): void;

  blur(): void;
  addEventListener(event: EventTypes, callback: EventListener): void;
}

export interface INativePaymentMethodManager {
  createButton(): IHeadlessPaymentMethodButton;
}

export interface IRedirectPaymentMethodManager {
  start(): Promise<void>;
  addEventListener(event: EventTypes, callback: EventListener): void;
}

export type PaymentMethodManagers =
  | ICardPaymentMethodManager
  | INativePaymentMethodManager
  | IRedirectPaymentMethodManager;

export enum HeadlessManagerType {
  CARD = 'CARD',
  NATIVE = 'NATIVE',
  REDIRECT = 'REDIRECT',
}

export type PaymentMethodInfo = {
  type: PaymentMethodType;
  managerType: HeadlessManagerType;
};

export type ButtonPaymentMethodAsset = {
  backgroundColor: BackgroundColor;
  iconUrl: IconUrl;
  paymentMethodName?: string;
};

export interface IAssetsManager {
  getPaymentMethodAsset(
    type: PaymentMethodType,
  ): Promise<ButtonPaymentMethodAsset | null>;
}

export interface CardPaymentMethodManagerOptions {
  onCardMetadataChange?: (metadata: CardMetadata) => void;
}

export type PaymentMethodManagerOptions = CardPaymentMethodManagerOptions;

export interface PrimerHeadlessCheckout {
  createPaymentMethodManager(
    type: 'PAYMENT_CARD',
    options?: PaymentMethodManagerOptions,
  ): Promise<ICardPaymentMethodManager | null>;
  createPaymentMethodManager(
    type: PaymentMethodType.PAYPAL | 'PAYPAL' | 'GOOGLE_PAY' | 'APPLE_PAY',
    options?: PaymentMethodManagerOptions,
  ): Promise<INativePaymentMethodManager | null>;
  createPaymentMethodManager(
    type: PaymentMethodType,
    options?: PaymentMethodManagerOptions,
  ): Promise<IRedirectPaymentMethodManager | null>;
  getAssetsManager(): IAssetsManager;
  configure: (options: HeadlessUniversalCheckoutOptions) => void;
  start: () => void;
}

export interface PrimerSinglePaymentMethodCheckout {
  submit(): void;
  refreshClientSession(): Promise<boolean>;
  /**
   * @deprecated The method should not be used
   */
  setClientToken(): Promise<boolean>;
}

export interface PrimerVaultManager {
  teardown(): void;
  submit(): void;
}

export interface VaultListItem {
  id: string;
  type: PaymentInstrumentType;
  details: CardDetails | PayPalDetails;
}

export interface RemotePaymentMethodConfiguration<T> {
  id: string;
  options: T;
}
