import { BasePaymentMethod } from '../../BasePaymentMethod';
import { ClientContext } from '../../../core/ClientContext';
import {
  getPaymentMethodModule,
  getPaymentMethodUrl,
} from '../../../utils/ModuleResolver';
import toCamelCase from '../../../utils/toCamelCase';
import { PaymentMethodConfig } from '../../../models/ClientConfiguration';

const fetchRemotePaymentMethod = async ({
  context,
  paymentMethodConfig,
}: {
  context: ClientContext;
  paymentMethodConfig: PaymentMethodConfig;
}) => {
  const { type } = paymentMethodConfig;

  try {
    const remotePath = getPaymentMethodUrl(context, type);
    const pack = context.moduleFactory.getPackage({
      remotePath,
      scope: toCamelCase(type),
    });

    let PaymentMethod: typeof BasePaymentMethod;

    const declaration = await pack.getDeclaration();
    if (declaration?.PaymentMethod) {
      PaymentMethod = declaration.PaymentMethod;
    } else {
      const PaymentMethodModule = getPaymentMethodModule(context, type);
      PaymentMethod = ((await PaymentMethodModule.import()) as unknown) as typeof BasePaymentMethod;
    }

    return PaymentMethod;
  } catch (e) {
    console.error(e);
    console.warn(`Can't load ${type}`);
    return null;
  }
};

export default fetchRemotePaymentMethod;
