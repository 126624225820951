import urljoin from 'url-join';
import { ClientContext } from '../core/ClientContext';
import { PaymentMethodType } from '../enums/Tokens';
import toCamelCase from './toCamelCase';

export const getPaymentMethodUrl = (
  context: ClientContext,
  paymentMethod: PaymentMethodType,
): string => {
  const moduleName = paymentMethod.toLowerCase().replace(/_/g, '-');
  const remotePath = urljoin(
    context.session.modulesUrl,
    '/payment-methods/',
    moduleName,
  );

  return remotePath;
};

export const getPaymentMethodModule = (
  context: ClientContext,
  paymentMethodType: PaymentMethodType,
) => {
  const url = getPaymentMethodUrl(context, paymentMethodType);

  const PaymentMethodModule = context.moduleFactory.getModule({
    remotePath: url,
    path: 'PaymentMethod',
    scope: toCamelCase(paymentMethodType),
  });

  return PaymentMethodModule;
};
