import { useSelector } from '@primer-io/shared-library/contexts';
import { FunctionalComponent } from 'preact';
import styled from 'styled-components';
import { OverlayContentCloseButton } from '../../components/OverlayContentCloseButton';

const Root = styled.div`
  background: white;
  border-radius: 8px;
  position: relative;
  margin: 30px;
`;

const PopupHeader = styled.div`
  padding: 24px;
  height: 75px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #9f9f9f45;
`;

const PopupBody = styled.div`
  padding: 24px;
`;

const StyledDescription = styled.div`
  color: #767676;
  max-width: 280px;
`;

const StyledFocusButton = styled.button`
  cursor: pointer;

  margin-top: 32px;
  background-color: black;
  padding: 12px 24px;
  border-radius: 100px;
  border: none;

  color: white;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledIcon = styled.img`
  max-width: 100px;
  max-height: 80px;
`;

type ContentProps = {
  paymentMethodName?: string;
  logoSrc?: string;
  background?: string;
  logoAltLabel?: string;
  canFocus?: boolean;
  onCloseClick?: () => void;
  onFocusClick?: () => void;
};

export const PaymentMethodOverlayContent: FunctionalComponent<ContentProps> = ({
  paymentMethodName,
  logoSrc,
  background,
  logoAltLabel,
  canFocus,
  onCloseClick,
  onFocusClick,
}) => {
  const labels = useSelector((s) => s.translation);

  return (
    <Root>
      <PopupHeader
        style={{ backgroundColor: background }}
        data-testId='PaymentMethodPopupOverlay.header'
      >
        <OverlayContentCloseButton onCloseClick={onCloseClick} />

        {logoSrc && logoAltLabel && (
          <StyledIcon
            src={logoSrc}
            alt={logoAltLabel}
            data-testId={'PaymentMethodPopupOverlay.logoImg'}
          />
        )}
      </PopupHeader>
      <PopupBody data-testId='PaymentMethodPopupOverlay.body'>
        <StyledDescription data-testId='PaymentMethodPopupOverlay.body.description'>
          {canFocus &&
            labels._('paymentMethodPopupOverlayDescription', {
              paymentMethodName: paymentMethodName ?? '',
            })}
          {!canFocus &&
            labels._('paymentMethodPopupOverlayNonFocusDescription', {
              paymentMethodName: paymentMethodName ?? '',
            })}
        </StyledDescription>
        {canFocus && (
          <StyledFocusButton
            data-testId='PaymentMethodPopupOverlay.focusButton'
            onClick={onFocusClick}
          >
            {labels?.paymentMethodPopupOverlayBringBackThePaymentPage}
          </StyledFocusButton>
        )}
      </PopupBody>
    </Root>
  );
};
