import { useSelector } from '@primer-io/shared-library/contexts';
import IFrameOverlay from '../core/IFrameService/IFrameOverlay';
import ErrorMessage from '../components/ErrorMessage';
import ProcessingIndicator from '../components/ProcessingIndicator';
import SmallPrint from '../components/SmallPrint';
import SubmitButton from '../components/SubmitButton';
import PaymentMethodPopupOverlay from '../core/PopupService/PaymentMethodPopupOverlay';
import SceneLoader from '../utils/SceneLoader/SceneLoader';
import ThreeDSScene from './ThreeDSScene';

const CheckoutRoot = () => {
  const isLoading = useSelector((s) => s.isLoading);
  const sceneStates = useSelector((s) => s.sceneStates);

  return (
    <div
      id='primer-checkout'
      className={`PrimerCheckout ${isLoading ? `PrimerCheckout--loading` : ''}`}
    >
      <div id='primer-checkout-content' className='PrimerCheckout__content'>
        {Object.keys(sceneStates).map((key) => (
          <SceneLoader key={key} scene={key} />
        ))}
      </div>
      <ThreeDSScene />
      <SubmitButton />
      <SmallPrint />
      <ErrorMessage />

      <ProcessingIndicator />
      <PaymentMethodPopupOverlay />
      <IFrameOverlay />
    </div>
  );
};

export default CheckoutRoot;
