import { IPaymentMethodContext } from '@primer-sdk-web/core/PaymentMethodContext';
import { PaymentMethodToken } from '@primer-sdk-web/types';

type TokenizeOptions = {
  tokenizationData?: any;
  defaultTokenizationError?: any;
};

const shouldTokenize = async (context: IPaymentMethodContext) => {
  if (!context.store.getIsTokenizationEnabled()) {
    context.progress.didNotStart('TOKENIZATION_DISABLED');
    return false;
  }

  const shouldStart = await context.progress.shouldStart();
  if (shouldStart === false) {
    context.progress.didNotStart('TOKENIZATION_SHOULD_NOT_START');
    return false;
  }

  return true;
};

const tokenize = async (
  context: IPaymentMethodContext,
  { tokenizationData = {}, defaultTokenizationError }: TokenizeOptions = {},
) => {
  const canTokenize = await shouldTokenize(context);
  if (!canTokenize) {
    return false;
  }

  context.progress.start();

  const { data, error } = await context.api.post<unknown, PaymentMethodToken>(
    '/payment-instruments',
    {
      paymentInstrument: tokenizationData,
    },
  );

  if (error || !data) {
    context.progress.error(error ?? defaultTokenizationError);
    return false;
  }

  context.progress.success(data);
  return true;
};

export type TokenizationHandler = {
  shouldTokenize: () => Promise<boolean>;
  tokenize: (options: TokenizeOptions) => Promise<boolean>;
};

const createTokenizationHandler = (
  context: IPaymentMethodContext,
): TokenizationHandler => ({
  shouldTokenize: () => shouldTokenize(context),
  tokenize: (options: TokenizeOptions) => tokenize(context, options),
});

export default createTokenizationHandler;
