import {
  useCheckoutStore,
  useSelector,
} from '@primer-io/shared-library/contexts';
import { FunctionalComponent } from 'preact';
import { BasePaymentMethod } from '../payment-methods/BasePaymentMethod';
import CreditCardButton from '../payment-methods/credit-card/CreditCardButton';
import { PaymentMethodOption } from '../models/ClientSession';
import PaymentMethodButton from './PaymentMethodButton';
import SurchargeItem from './SurchargeItem';
import SurchargePaymentMethods from './SurchargePaymentMethods';

type Props = {
  paymentMethods: [string, BasePaymentMethod][];
  showCreditCardButton: boolean;
  hasCreditCardSurcharge: boolean;
};

const PaymentMethodButtonContainer: FunctionalComponent<Props> = ({
  paymentMethods,
  showCreditCardButton,
  hasCreditCardSurcharge,
}) => {
  const store = useCheckoutStore();
  const paymentMethodOptions: PaymentMethodOption[] = useSelector(
    (s) => s.clientSession?.paymentMethod?.options as PaymentMethodOption[],
  );

  const currencyCode = useSelector((s) => s.clientSession?.order.currencyCode);

  const renderCreditCardButton = () => {
    if (!showCreditCardButton) {
      return undefined;
    }

    const creditCardButton = <CreditCardButton />;

    if (hasCreditCardSurcharge) {
      return (
        <SurchargeItem isSurchargeUnknown={true}>
          {creditCardButton}
        </SurchargeItem>
      );
    }
    return creditCardButton;
  };

  return (
    <div
      id='primer-checkout-apm-button-container'
      className='PrimerCheckout__sceneElement PrimerCheckout__apmButtonContainer'
    >
      {store.hasSurcharge && (
        <SurchargePaymentMethods
          paymentMethods={paymentMethods}
          paymentMethodOptions={paymentMethodOptions}
          currencyCode={currencyCode}
        />
      )}
      {!store.hasSurcharge &&
        paymentMethods.map(([key, paymentMethod]) => (
          <PaymentMethodButton
            key={key}
            id={key}
            paymentMethod={paymentMethod}
          />
        ))}
      {renderCreditCardButton()}
    </div>
  );
};

export default PaymentMethodButtonContainer;
