export default class InternalError extends Error {
  public code: string;

  public data?: any;

  public static fromErrorCode(code: string, data?: any): InternalError {
    return new InternalError(code, data);
  }

  constructor(code: string, data?: any) {
    super(code);
    this.code = code;
    this.data = data;
  }
}
