import { noop } from '@primer-sdk-web/utils/noop';
import { FunctionalComponent } from 'preact';
import { useRef, useState } from 'preact/hooks';
import styled from 'styled-components';
import { PtIcon } from './assets/icons';

const Root = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const FlagContainer = styled.span`
  margin-right: 12px;
`;

const Prefix = styled.input`
  margin-right: 12px;
  border: none;
  line-height: 20px;
  width: 3em;
  font-size: 16px;
  height: 40px;
  background: transparent;
`;

const PhoneNumber = styled.input`
  border: none;
  background: #f5f5f5;
  border-radius: 4px;
  height: 40px;
  padding: 0 8px;
  line-height: 40px;
  font-size: 16px;
`;

type Props = {
  value?: string;
  prefix?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
  inputId: string;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  hasErrors?: boolean;
};

const PhoneNumberInput: FunctionalComponent<Props> = ({
  prefix,
  placeholder,
  maxLength,
  minLength,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [number, setNumber] = useState(props.value);
  prefix = prefix || '';

  const focusInput = () => {
    inputRef.current.focus();
  };

  const filterDigits = (text) => {
    text = text.replace(/[^\d]/g, '');
    setNumber(text);
    return text;
  };

  const triggerChangePhoneNumber = (e) => {
    const no = filterDigits(e.target?.value);
    props.onChange?.(no.length ? prefix + no : '');
  };

  return (
    <Root
      {...props}
      onClick={focusInput}
      onFocus={focusInput}
      onChange={noop}
      data-testid='Scene.phoneNumberInput'
      className='PrimerCheckout__primerPhoneNumberInput'
    >
      <FlagContainer>
        <PtIcon />
      </FlagContainer>

      <Prefix type='text' value={prefix} disabled />

      <PhoneNumber
        placeholder={placeholder}
        type='text'
        ref={inputRef}
        onInput={triggerChangePhoneNumber}
        onChange={triggerChangePhoneNumber}
        maxLength={maxLength ? maxLength - prefix.length : undefined}
        minLength={minLength}
        value={number}
      />
    </Root>
  );
};

export default PhoneNumberInput;
