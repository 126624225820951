export const setElementDisabled = (
  element: HTMLElement | null,
  disabled: boolean,
) => {
  if (!element) {
    return;
  }

  if (disabled) {
    element.setAttribute('disabled', 'true');
    element.setAttribute('tabindex', '-1');
    element.style.pointerEvents = 'none';
  } else {
    element.removeAttribute('disabled');
    element.setAttribute('tabindex', '0');
    element.style.pointerEvents = 'all';
  }
};
