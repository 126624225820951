import { createFocusTrap, FocusTrap } from 'focus-trap';
import { FunctionalComponent } from 'preact';
import { createPortal } from 'preact/compat';
import { useLayoutEffect, useRef } from 'preact/hooks';
import { AnimatePresence } from '../utils/AnimatePresence';
import { OverlayContentContainer } from './OverlayContentContainer';

import { noop } from '../utils/noop';
import { useOverlayContainer } from '../utils/hooks';

type ContainerProps = {
  isVisible: boolean;
  onCloseClick?: () => void;
};

export const Overlay: FunctionalComponent<ContainerProps> = ({
  isVisible,
  onCloseClick,
  children,
}) => {
  const focusTrap = useRef<FocusTrap | null>(null);

  const overlayContainerRef = useOverlayContainer();

  overlayContainerRef.current.style.pointerEvents = isVisible ? 'all' : 'none';

  useLayoutEffect(() => {
    if (isVisible) {
      focusTrap.current = createFocusTrap(overlayContainerRef.current, {
        onDeactivate: onCloseClick,
        returnFocusOnDeactivate: true,
        clickOutsideDeactivates: false,
        allowOutsideClick: false,
      });
      focusTrap.current.activate();
    } else {
      focusTrap.current?.deactivate({ onDeactivate: noop });
      focusTrap.current = null;
    }
  }, [isVisible]);

  return (
    <>
      {createPortal(
        <div>
          <AnimatePresence>
            {[
              isVisible && (
                <OverlayContentContainer
                  key={'key'} /* Fake key mandatory of AnimatePresence */
                  onCloseClick={onCloseClick}
                >
                  {children}
                </OverlayContentContainer>
              ),
            ]}
          </AnimatePresence>
        </div>,
        overlayContainerRef.current,
      )}
    </>
  );
};
