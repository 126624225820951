import { FunctionalComponent } from 'preact';
import { useState } from 'preact/hooks';
import { Copy, AlertTriangle, CheckCircle } from 'preact-feather';
import styled from 'styled-components';
import { BaseButton } from '../Button';
import { useLocaleData } from '@primer-sdk-web/utils/hooks';

const Root = styled.div`
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CopyButton = styled(BaseButton)`
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 20px;
  & > svg {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

type Props = {
  text: string;
};

enum State {
  Idle,
  CopySuccess,
  CopyFailure
};

const CopyToClipboard: FunctionalComponent<Props> = ({
  text
}) => {

  const [state, setState] = useState(State.Idle);
  const isRtlLocale = useLocaleData().isRtlLocale;

  return (
    <Root style={ { direction: isRtlLocale ? 'rtl' : 'ltr' } }>
      {text}
      <CopyButton
        type='button'
        data-testid='Scene.copyToClipboardButton'
        id='primer-checkout-copy-to-clipboard'
        className='PrimerCheckout__copyToClipboardButton'
        onClick={() => {
          navigator.clipboard.writeText(text).then(function() {
            setState(State.CopySuccess);
            setTimeout(() => setState(State.Idle), 1000);
          }, function() {
            setState(State.CopyFailure);
            setTimeout(() => setState(State.Idle), 1000);
          });
        }}
      >
        {{
           [State.Idle]: <Copy />,
           [State.CopySuccess]: <CheckCircle />,
           [State.CopyFailure]: <AlertTriangle />
        }[state]}
      </CopyButton>
    </Root>
  );
};

export default CopyToClipboard;
